//@flow
import * as React from 'react';
import { Button, Flex, Icon, Box, Text, Position } from '@locus.sh/neo';
import { keyframes } from '@emotion/core';
import testIds from 'tests/trip-map/testIds.json';
import { useTranslation } from 'react-i18next';
import TimeUtils from 'helpers/TimeUtil';
import useRefreshTimer from 'hooks/useRefreshTimer';
import NumberUtils from 'utils/NumberUtils';

const getBtnCss = () => ({
    textAlign: 'center',
});
const getPulseCss = () => {
    return {
        border: '10px solid #A3E4FF',
        backgroundColor: 'transparent',
        borderRadius: '60px',
        height: '50px',
        width: '50px',
        animation: `${pulse} 0.5s ease-in`,
        animationIterationCount: 'infinite',
        position: 'absolute',
        zIndex: 40,
        opacity: 0,
    };
};
const getDotCss = () => ({
    width: '15px',
    height: '15px',
    border: '0px solid #A3E4FF',
    borderRadius: '30px',
    backgroundColor: '#A3E4FF',
    zIndex: '50',
    position: 'absolute',
    opacity: '0.5',
    boxShadow: '0 0 10px 0px #A3E4FF',
});
const pulse = keyframes({
    from: {
        transform: 'scale(0)',
        opacity: 0.0,
    },
    to: {
        transform: 'scale(1)',
        opacity: 0.5,
    },
});

type Props = {
    inProgress: boolean,
    onClick: Function,
};

const getLastRefreshedText = (lastRefreshed: number, translator: Function) => {
    const lastRefreshedInMilliSeconds = lastRefreshed * 1000;
    if (lastRefreshed < 10) {
        return translator('justNow');
    }

    const singleUnitOptions = { largest: 1 };
    const humanizeTime = TimeUtils.humanize(
        lastRefreshedInMilliSeconds,
        singleUnitOptions
    );
    return translator('timeAgo', { time: humanizeTime });
};

const RefreshButton = function ({ inProgress, onClick }: Props) {
    const { t } = useTranslation();
    const { time } = useRefreshTimer();
    return (
        <Position position="absolute" right={2} bottom={12} zIndex={11}>
            <Flex alignItems="center">
                {NumberUtils.isNumeric(time) && (
                    <Box
                        mr={-4}
                        py={1}
                        pl={2}
                        pr={4}
                        backgroundColor="grey.100"
                        borderRadius={18}
                        boxShadow="active"
                    >
                        <Flex mr={1} alignItems="center">
                            <Text
                                color="grey.500"
                                mr={1}
                                textTransform="uppercase"
                                fontSize={1}
                                fontWeight={600}
                            >
                                {t('lastUpdated')}
                            </Text>
                            <Text
                                color="grey.700"
                                fontWeight={600}
                                fontSize={1}
                            >
                                {getLastRefreshedText(time, t)}
                            </Text>
                        </Flex>
                    </Box>
                )}
                <Button
                    onClick={onClick}
                    disabled={inProgress}
                    appearance="solid"
                    sx={{
                        width: 32,
                        height: 32,
                        borderRadius: '50%',
                        lineHeight: 1,
                        boxShadow: '0px 0px 4px rgba(9, 10, 12, 0.16)',
                        backgroundColor: 'white',
                        '&:disabled, &:hover': {
                            backgroundColor: 'white',
                        },
                    }}
                    data-test-id={testIds.refreshButton}
                >
                    {inProgress ? (
                        <Flex
                            sx={getBtnCss}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Flex sx={getPulseCss} />
                            <Flex sx={getDotCss} />
                        </Flex>
                    ) : (
                        <Icon
                            icon="refresh"
                            size="large"
                            color="grey.600"
                            pt="2px"
                        />
                    )}
                </Button>
            </Flex>
        </Position>
    );
};
export { RefreshButton };
export default RefreshButton;
