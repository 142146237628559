import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translations from './translations.json';
// the translations
// (tip move them in a JSON file and import them)
const resources = {
    'en-US': {
        translations,
    },
};

const defaultI18nOptions = {
    resources,
    fallbackLng: 'en-US',
    debug: true,
    defaultNS: 'translations',
    interpolation: {
        escapeValue: false, // react already safes from xss
    },
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init(defaultI18nOptions);

export default i18n;
