// @flow
import * as React from 'react';
import { Text, Flex } from '@locus.sh/neo';
import Price from './Price';
import type { TripInfo } from 'models/internal/TripInfo';

type Props = {|
    tripInfo: TripInfo,
    color?: string,
    showAmountType?: boolean,
    sx?: Object,
    showTotalAmount?: boolean,
    dataTestID?: string,
|};

export const canShowAmount = (
    tripInfo: TripInfo,
    showTotalAmount?: boolean
): boolean => {
    const { settings, amount } = tripInfo;
    const { showAmount } = settings;
    const shouldShowAmount = showAmount && amount.isValid();
    const totalAmount = tripInfo.getTotalAmountForLineItems();
    const data = amount.getData(showTotalAmount ? totalAmount : null);

    if (!(data && shouldShowAmount)) {
        return false;
    }

    return true;
};

export const Amount = ({
    color = 'slate',
    showAmountType,
    showTotalAmount,
    sx = {},
    tripInfo,
}: Props): null | React$Element<any> => {
    const { amount } = tripInfo;
    const totalAmount = tripInfo.getTotalAmountForLineItems();
    const data = amount.getData(showTotalAmount ? totalAmount : null);

    if (!canShowAmount(tripInfo, showTotalAmount)) {
        return null;
    }

    const { amountType, symbol, value } = data;
    return (
        <Flex alignItems="center" sx={sx}>
            {showAmountType && <Text fontSize={2}>{amountType}</Text>}
            <Price symbol={symbol} value={value} color={color} ml={1} />
        </Flex>
    );
};

export default Amount;
