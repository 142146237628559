import React from 'react';
import { Polyline as MapPolyline } from '@react-google-maps/api';

const Polyline = React.memo((props) => {
    const { path, strokeColor, strokeOpacity, strokeWeight, options } = props;
    return (
        <MapPolyline
            path={path}
            options={{ ...options, strokeColor, strokeOpacity, strokeWeight }}
        />
    );
});

Polyline.defaultProps = {
    strokeOpacity: 1,
    path: [],
    strokeWeight: 5,
    strokeColor: '#0072F5',
    options: {},
};

export default Polyline;
