//@flow
import i18n from 'i18n';

class I18nHelper {
    preferredLanguage: string = this.getBrowserLanguage();

    /**
     * The purpose of this function is to map language codes which have variations, to a uniform
     * language code which backend undestands. For example there are two variants of Hindi codes
     * - hi and hi-IN. Our backend understands hi-IN but not hi. So FE needs to map hi to hi-IN.
     */
    mapBrowserLngCodeToIetFormat(code: string): string {
        // extracting the language code from strings with country code
        const [lngCode] = code.split('-');

        switch (lngCode) {
            case 'en':
                return 'en-US';
            // case 'es':
            //     return 'es-ES';
            // case 'fr':
            //     return 'fr-FR';
            // case 'hi':
            //     return 'hi-IN';
            // case 'hr':
            //     return 'hr';
            // case 'id':
            //     return 'id-ID';
            // case 'ja':
            //     return 'ja-JP';
            // case 'ms':
            //     return 'ms-MY';
            // case 'nl':
            //     return 'nl';
            // case 'pt':
            //     return 'pt-BR';
            // case 'si':
            //     return 'si';
            // case 'sr':
            //     return 'sr-RS';
            // case 'th':
            //     return 'th-TH';
            // case 'tl':
            //     return 'tl-PH';
            // Adding support for VN translaation only, incrementally above languages will be added
            case 'vi':
                return 'vi-VN';
            default:
                return 'en-US'; // Todo: Replace en-US with code once remaining translations are ready
        }
    }

    getPreferredLanguage(): string {
        return this.preferredLanguage;
    }

    setPreferredLanguage(language: string) {
        this.preferredLanguage = language;
    }

    translate(
        namespace: Array<string>,
        key: string,
        options: { [string]: string } = {},
        language: string = this.preferredLanguage
    ): string {
        return i18n.getFixedT(language, namespace)(key, options);
    }

    getTranslate(namespace: Array<string>): any {
        return this.translate.bind(this, namespace);
    }

    getBrowserLanguage(): string {
        const browserLanguage = navigator?.language || 'en-US';
        return this.mapBrowserLngCodeToIetFormat(browserLanguage);
    }

    changeLanguage(resource: { [string]: string }) {
        const lng = this.getPreferredLanguage();
        i18n.addResourceBundle(lng, 'translations', resource);
        i18n.changeLanguage(lng, (error) => {
            if (error) {
                console.error('not able to change the language ', error);
            }
        });
    }
}

export default (new I18nHelper(): I18nHelper);
