// @flow
import * as Immutable from 'immutable';

export type Updater<TProp> = (oldValue: TProp) => TProp;

export const NOT_SET = {};

export default class ImmutableModel {
    _state: Immutable.Map<string, any>;

    constructor(state: Immutable.Map<any, any>) {
        this._state = state || Immutable.Map();
    }

    fromJS(_json: Object): this {
        throw new Error('Abstract method');
    }

    getState() {
        return this._state;
    }

    clone(value: Immutable.Map<string, any>): this {
        const constructor = this.constructor;
        return value === this._state ? this : new constructor(value);
    }

    get(property: string): any {
        return this._state.get(property);
    }

    set(property: string, value: any): this {
        return this.clone(this._state.set(property, value));
    }

    update<TProp>(property: string, updater: Updater<TProp>): this {
        return this.clone(this._state.update(property, updater));
    }

    getIn(properties: string[]): any {
        return this._state.getIn(properties);
    }

    setIn(properties: string[], value: any): this {
        return this.clone(this._state.setIn(properties, value));
    }

    deleteIn(keyPath: Array<any> | Immutable.Iterable<any, any>): this {
        return this.clone(this._state.deleteIn(keyPath));
    }

    updateIn<TProp>(
        properties: Array<string | number>,
        notSetValue: TProp | Updater<TProp>,
        updater?: Updater<TProp>
    ): this {
        return this.clone(
            this._state.updateIn(properties, notSetValue, updater)
        );
    }

    has(property: string): boolean {
        return this._state.has(property);
    }

    equals(other: Class<ImmutableModel>): boolean {
        return this._state.equals(other.getState());
    }

    addToMap<TKey, TValue>(property: string, key: TKey, value: TValue): this {
        const map: Immutable.Map<TKey, TValue> = this.get(property);
        return this.clone(this._state.set(property, map.set(key, value)));
    }

    removeFromMap<TKey, TValue>(property: string, key: TKey): this {
        const map: Immutable.Map<TKey, TValue> = this.get(property);
        return this.clone(this._state.set(property, map.remove(key)));
    }

    addToList<TProp>(property: string, value: TProp): this {
        return this.clone(
            this._state.update(property, Immutable.List(), (lst) =>
                lst.push(value)
            )
        );
    }

    concatToList<TProp>(property: string, ...value: Array<TProp>): this {
        return this.clone(
            this._state.update(property, Immutable.List(), (lst) =>
                lst.concat(...value)
            )
        );
    }

    removeFromList<TProp>(property: string, index: number): this {
        const list: Immutable.List<TProp> = this.get(property);
        return this.clone(this._state.set(property, list.remove(index)));
    }

    addToSet<TProp>(property: string, value: TProp): this {
        const collection: Immutable.Set<TProp> = this.get(property);
        return this.clone(this._state.set(property, collection.add(value)));
    }

    removeFromSet<TProp>(property: string, value: TProp): this {
        const list: Immutable.Set<TProp> = this.get(property);
        return this.clone(this._state.set(property, list.remove(value)));
    }

    mergeDeep<TKey, TValue>(
        ...iterables: Immutable.Iterable<TKey, TValue>[]
    ): this {
        iterables = iterables.map((iterable) => {
            if (iterable instanceof ImmutableModel) {
                return iterable.getState();
            }
            return iterable;
        });
        return this.clone(
            this.mergeIntoMapWith(this.getState(), this.deepMerger, iterables)
        );
    }

    deepMerger = (existing, value, key) => {
        if (
            existing &&
            existing.mergeDeep &&
            (existing instanceof ImmutableModel ||
                Immutable.Iterable.isIterable(value))
        ) {
            if (existing instanceof Immutable.List) {
                return this.mergeIntoListWith(existing, this.deepMerger, [
                    value,
                ]);
            }
            return existing.mergeDeep(value);
        } else {
            return Immutable.is(existing, value) ? existing : value;
        }
    };

    mergeIntoMapWith(map, merger, iterables) {
        var iters = [];
        for (var ii = 0; ii < iterables.length; ii++) {
            var value = iterables[ii];
            var iter = Immutable.Iterable.Keyed(value);
            if (
                !Immutable.Iterable.isIterable(value) ||
                !(value instanceof ImmutableModel)
            ) {
                iter = iter.map((v) => Immutable.fromJS(v));
            }
            iters.push(iter);
        }
        return this.mergeIntoCollectionWith(map, merger, iters);
    }

    mergeIntoCollectionWith(collection, merger, iters) {
        iters = iters.filter((x) => x.size !== 0);
        if (iters.length === 0) {
            return collection;
        }
        if (
            collection.size === 0 &&
            !collection.__ownerID &&
            iters.length === 1
        ) {
            return collection.constructor(iters[0]);
        }
        return collection.withMutations((collection) => {
            var mergeIntoMap = merger
                ? (value, key) => {
                      collection.update(key, NOT_SET, (existing) =>
                          existing === NOT_SET
                              ? value
                              : merger(existing, value, key)
                      );
                  }
                : (value, key) => {
                      collection.set(key, value);
                  };
            for (var ii = 0; ii < iters.length; ii++) {
                iters[ii].forEach(mergeIntoMap);
            }
        });
    }

    mergeIntoListWith(list, merger, iterables) {
        var iters = [];
        var maxSize = 0;
        for (var ii = 0; ii < iterables.length; ii++) {
            var value = iterables[ii];
            var iter = Immutable.Iterable.Indexed(value);
            if (iter.size > maxSize) {
                maxSize = iter.size;
            }
            if (!Immutable.Iterable.isIterable(value)) {
                iter = iter.map((v) => Immutable.fromJS(v));
            }
            iters.push(iter);
        }
        //Removed a condition to check if size has increased, so that decrease in size is also considered.
        list = list.setSize(maxSize);
        return this.mergeIntoCollectionWith(list, merger, iters);
    }

    map<M, K, V>(
        mapper: (value: V, key: K, iter: this) => M,
        context?: any
    ): Immutable.Map<K, M> {
        return this._state.map(mapper, context);
    }

    forEach<K, V>(
        sideEffect: (value: V, key: K, iter: this) => any,
        context?: any
    ): number {
        return this._state.forEach(sideEffect, context);
    }

    mapKeys<M, K, V>(
        mapper: (key: K, value: V, iter: Immutable.Iterable.Keyed<K, V>) => M,
        context?: any
    ): Immutable.Iterable.Keyed<M, V> {
        return this._state.mapKeys(mapper, context);
    }

    withMutations<K, V>(mutator: (mutable: Map<K, V>) => any): this {
        return this.clone(this._state.withMutations(mutator));
    }

    toJS(): Object {
        return this.getState().toJS();
    }

    reduce<R, K, V>(
        reducer: (reduction: R, value: V, key: K, iter: this) => R,
        initialReduction: R,
        context?: any
    ): R {
        return this.getState().reduce(reducer, initialReduction, context);
    }

    hashCode(): number {
        return this.getState().hashCode();
    }

    static defaultInit(json: Object): this {
        return this.fromJS(json || {});
    }
}
