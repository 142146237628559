import * as React from 'react';
import { Flex } from '@locus.sh/neo';
import { ImageWithFallback } from 'components';
import testIds from 'tests/trip-pane/testIds.json';
const FALLBACK_URL = '/img/rider.png';

const RiderImage = function (props) {
    return (
        <Flex
            alignItems="center"
            flexGrow={0}
            flexShrink={0}
            sx={{ overflow: 'hidden', borderRadius: '50%' }}
            width="36px"
            height="36px"
            data-test-id={testIds.riderImage}
        >
            <ImageWithFallback
                width={'100%'}
                src={props.url}
                fallbackUrl={FALLBACK_URL}
            />
        </Flex>
    );
};

RiderImage.defaultProps = {
    url: FALLBACK_URL,
};
export { RiderImage };
export default RiderImage;
