//@flow
import React, { useState, useEffect } from 'react';
import { Button, useToast } from '@locus.sh/neo';
import { useTranslation } from 'react-i18next';
import testIds from 'tests/trip-pane/testIds.json';
import { phoneCallClient } from 'trip/helpers';
import { apiToastDefaultOptions } from 'helpers/ToastHelper';
import { useTripContext } from 'trip/useTripContext';
import { type TripInfo } from 'models/internal/TripInfo';
import { isValidString } from 'helpers/StringUtil';

const getButtonStyle = () => {
    return {
        display: 'flex',
        lineHeight: 1,
        flex: 0,
        '&:disabled': {
            color: 'grey.600',
        },
    };
};

const getButtonProps = ({
    isCloudTelephonyEnabled,
    userNumber,
    t,
    onClick,
}) => {
    return isCloudTelephonyEnabled
        ? { onClick }
        : {
              title: t('callNumber', { userNumber }),
              as: 'a',
              href: `tel:${userNumber}`,
          };
};

type Props = {
    sx?: Object,
};

export function canShowRiderCallButton(tripInfo: TripInfo): boolean {
    const {
        settings: {
            moduleSettings: { enableDriverModule },
        },
        userNumber,
        isCloudTelephonyEnabled,
    } = tripInfo;
    const isReceived = tripInfo.isReceived();
    const isAssigned = tripInfo.isAssigned();
    const isTerminal = tripInfo.isTerminal();
    const canCallRider = isValidString(userNumber) || isCloudTelephonyEnabled;
    return (
        !(isReceived || isTerminal || isAssigned) &&
        enableDriverModule &&
        canCallRider
    );
}

const RiderCallButton = ({ sx = {} }: Props): React$Element<any> | null => {
    const [callStatusMessage, setCallStatusMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const { showToast, closeAllToasts } = useToast();
    const [{ tripInfo }] = useTripContext();

    const handleClick = async () => {
        setIsLoading(true);

        try {
            await phoneCallClient.fetch(tripId);
            setIsLoading(false);
            setCallStatusMessage(t('phoneCallSuccess'));
        } catch (e) {
            setIsLoading(false);
            setCallStatusMessage(t('phoneCallFailure'));
            setIsError(true);
        }
    };

    useEffect(() => {
        if (callStatusMessage) {
            closeAllToasts();
            showToast({
                content: callStatusMessage,
                palette: isError ? 'danger' : 'primary',
                ...apiToastDefaultOptions,
                onClose: () => {
                    setCallStatusMessage(null);
                    setIsError(false);
                },
            });
        }
    }, [callStatusMessage, closeAllToasts, isError, showToast]);

    const { userNumber, isCloudTelephonyEnabled, tripId } = tripInfo;
    const { t } = useTranslation();
    const disablePhoneCall = isLoading || callStatusMessage;

    if (!canShowRiderCallButton(tripInfo)) {
        return null;
    }

    return (
        <Button
            {...getButtonProps({
                isCloudTelephonyEnabled,
                userNumber,
                t,
                onClick: handleClick,
            })}
            appearance="solid"
            palette="primary"
            sx={() => ({ ...sx, ...getButtonStyle() })}
            disabled={disablePhoneCall}
            data-test-id={testIds.callButton}
            isLoading={isLoading}
            icon="call"
            size="large"
        />
    );
};

export default RiderCallButton;
