// @flow
import { track } from './FirebaseHelper';
import NewRelic from './NewRelic';

class Tracker {
    static track(name: string, data: Object) {
        // sometimes these thrid party packages throw error which if not handled will trigger pager,
        // hence wrapping those method invocation with try-catch
        try {
            track(name, data);
            NewRelic.addPageAction(name, data);
        } catch (error) {
            console.error(error);
        }
    }
}

export default Tracker;
