//@flow
import * as React from 'react';
import Marker from './Marker';
import { animateTo } from './animate';

const useMovingMarker = ({ position, onLoad }) => {
    const [markerInstance, setMarkerInstance] = React.useState(null);
    const [nextPosition, setNextPosition] = React.useState(position);

    const onMarkerLoad = React.useCallback(
        (markerInstance) => {
            setMarkerInstance(markerInstance);
            onLoad?.(markerInstance);
        },
        [onLoad]
    );
    React.useEffect(() => {
        // if position changes, animate to that
        const marker = animateTo({
            marker: markerInstance,
            currentPosition: markerInstance?.props?.position,
            newPosition: position,
            duration: 1000,
            completeCallback: () => setNextPosition(position),
        });
        return window.cancelAnimationFrame(marker?.animationHandler);
    }, [position, markerInstance]);

    return { nextPosition, onMarkerLoad };
};
const useRotatingMarker = ({ heading }) => {
    const [style, setStyle] = React.useState({ transition: '0.3s ease all' });
    React.useEffect(() => {
        const realAngle = heading;
        setStyle({
            transition: '0.6s ease all',
            WebkitTransform: `rotate(${realAngle}deg)`,
            MozTransform: `rotate(${realAngle}deg)`,
            mStransform: `rotate(${realAngle}deg)`,
            transform: `rotate(${realAngle}deg)`,
        });
    }, [heading]);
    return { style };
};
const SmoothMarker = (props) => {
    const {
        position,
        onLoad,
        heading,
        iconUrl,
        imagePositioning,
        clickable,
    } = props;
    const { nextPosition, onMarkerLoad } = useMovingMarker({
        position,
        onLoad,
    });
    const { style: rotationStyle } = useRotatingMarker({ heading });
    return (
        <Marker
            clickable={clickable}
            iconSize={48}
            imagePositioning={imagePositioning}
            position={nextPosition}
            onMarkerLoad={onMarkerLoad}
            iconUrl={iconUrl}
            rotationStyle={rotationStyle}
        />
    );
};

export { SmoothMarker };
export default SmoothMarker;
