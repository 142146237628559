// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel'; // Make sure you copied this file into your repo

import { VisitId } from './VisitId';
import { OrderDetail } from './OrderDetail';
import { AddressLatLng } from './AddressLatLng';
import { AmountTransaction } from './AmountTransaction';
import { TripStatus } from './TripStatus';
import { LiveViewSettings } from './LiveViewSettings';
import { EnrouteOrder } from './EnrouteOrder';
import { RescheduleInfo } from './RescheduleInfo';
import { CustomerChecklistData } from './CustomerChecklistFilled';
import { Banner } from './Banner';

import type { VisitIdModelType } from './VisitId';
import type { OrderDetailModelType } from './OrderDetail';
import type { AddressLatLngModelType } from './AddressLatLng';
import type { AmountTransactionModelType } from './AmountTransaction';
import type { TripStatusModelType } from './TripStatus';
import type { LiveViewSettingsModelType } from './LiveViewSettings';
import type { EnrouteOrderModelType } from './EnrouteOrder';
import type { RescheduleInfoModelType } from './RescheduleInfo';
import type { StatusModelType } from './Status';
import type { CustomerChecklistDataModelType } from './CustomerChecklistFilled';
import type { CustomerChecklist } from './CustomerChecklist';
import type { ExpiryInfoModelType } from './ExpiryInfo';
import type { BannerModelType } from './Banner';
type VehicleTypeEnum =
    | 'NONE'
    | 'TRUCK'
    | 'BUS'
    | 'BIKE'
    | 'TRICYCLE'
    | 'CAR'
    | 'PERSON';

export type TripInfoModelType = {
    visitId: VisitIdModelType,
    userName: string,
    userNumber?: string,
    userPhotoUrl?: string,
    userLocation?: AddressLatLngModelType,
    vehicleIconUrl?: string,
    source?: AddressLatLngModelType,
    destination: AddressLatLngModelType,
    customerName: ?string,
    orderDetail?: OrderDetailModelType,
    amount: AmountTransactionModelType,
    /** Unique identifier of the trip */
    tripId: string,
    /** ETA for reaching destination */
    eta: string,
    /** PIN for the order  */
    pin?: string,
    tripDisplayStrings: { [key: string]: string },
    status: TripStatusModelType,
    enrouteOrders?: Array<EnrouteOrderModelType>,
    showLocationTrail?: boolean,
    settings: LiveViewSettingsModelType,
    vehicleType?: VehicleTypeEnum,
    /** Display text for user on track link */
    userDisplayText: string,
    /**  Display text for trip on track link */
    tripDisplayText: string,
    /** Allow users to initiate phone call when rider phone number is masked */
    isCloudTelephonyEnabled: boolean,
    /** Temperature of the rider */
    riderTemperature?: number,
    checklists: CustomerChecklistDataModelType,
    /** Notes added by the end customer */
    customerNotes: Array<string>,
    rescheduleInfo: RescheduleInfoModelType,
    expiredDisplayDate?: string,
    banner?: BannerModelType,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class TripInfo extends ImmutableModel {
    static fromJS(json: TripInfoModelType): TripInfo {
        const state: Object = Object.assign({}, json);
        state.visitId = VisitId.fromJS(state.visitId);
        state.userLocation = state.userLocation
            ? AddressLatLng.fromJS(state.userLocation)
            : state.userLocation;
        state.source = state.source
            ? AddressLatLng.fromJS(state.source)
            : state.source;
        state.destination = AddressLatLng.fromJS(state.destination);
        state.orderDetail = state.orderDetail
            ? OrderDetail.fromJS(state.orderDetail)
            : state.orderDetail;
        state.amount = AmountTransaction.fromJS(state.amount);
        state.tripDisplayStrings = Immutable.Map(state.tripDisplayStrings);
        state.status = TripStatus.fromJS(state.status);
        state.enrouteOrders = state.enrouteOrders
            ? Immutable.List(state.enrouteOrders).map((item) =>
                  EnrouteOrder.fromJS(item)
              )
            : state.enrouteOrders;
        state.settings = LiveViewSettings.fromJS(state.settings);
        state.checklists = CustomerChecklistData.fromJS(state.checklists);
        state.customerNotes = Immutable.List(state.customerNotes);
        state.rescheduleInfo = RescheduleInfo.fromJS(state.rescheduleInfo);
        state.banner = state.banner
            ? Banner.fromJS(state.banner)
            : state.banner;
        return new this(Immutable.Map(state));
    }

    toJS(): TripInfoModelType {
        const js: TripInfoModelType = {
            visitId: this.visitId.toJS(),
            userName: this.userName,
            destination: this.destination.toJS(),
            customerName: this.customerName,
            amount: this.amount.toJS(),
            tripId: this.tripId,
            eta: this.eta,
            tripDisplayStrings: this.tripDisplayStrings.toObject(),
            status: this.status.toJS(),
            settings: this.settings.toJS(),
            userDisplayText: this.userDisplayText,
            tripDisplayText: this.tripDisplayText,
            isCloudTelephonyEnabled: this.isCloudTelephonyEnabled,
            checklists: this.checklists.toJS(),
            customerNotes: this.customerNotes.toArray(),
            rescheduleInfo: this.rescheduleInfo.toJS(),
        };

        if (this.userNumber != null) {
            js.userNumber = this.userNumber;
        }

        if (this.userPhotoUrl != null) {
            js.userPhotoUrl = this.userPhotoUrl;
        }

        if (this.userLocation != null) {
            js.userLocation = this.userLocation
                ? this.userLocation.toJS()
                : this.userLocation;
        }

        if (this.vehicleIconUrl != null) {
            js.vehicleIconUrl = this.vehicleIconUrl;
        }

        if (this.source != null) {
            js.source = this.source ? this.source.toJS() : this.source;
        }

        if (this.orderDetail != null) {
            js.orderDetail = this.orderDetail
                ? this.orderDetail.toJS()
                : this.orderDetail;
        }

        if (this.pin != null) {
            js.pin = this.pin;
        }

        if (this.enrouteOrders != null) {
            js.enrouteOrders = this.enrouteOrders
                ? this.enrouteOrders.toArray().map((item) => item.toJS())
                : this.enrouteOrders;
        }

        if (this.showLocationTrail != null) {
            js.showLocationTrail = this.showLocationTrail;
        }

        if (this.vehicleType != null) {
            js.vehicleType = this.vehicleType;
        }

        if (this.riderTemperature != null) {
            js.riderTemperature = this.riderTemperature;
        }

        if (this.expiredDisplayDate != null) {
            js.expiredDisplayDate = this.expiredDisplayDate;
        }

        if (this.banner != null) {
            js.banner = this.banner ? this.banner.toJS() : this.banner;
        }

        return js;
    }

    get visitId(): VisitId {
        return this._state.get('visitId');
    }

    setVisitId(visitId: VisitId): this {
        return this.clone(this._state.set('visitId', visitId));
    }

    get userName(): string {
        return this._state.get('userName');
    }

    setUserName(userName: string): this {
        return this.clone(this._state.set('userName', userName));
    }

    get userNumber(): ?string {
        return this._state.get('userNumber');
    }

    setUserNumber(userNumber: ?string): this {
        return this.clone(this._state.set('userNumber', userNumber));
    }

    get userPhotoUrl(): ?string {
        return this._state.get('userPhotoUrl');
    }

    setUserPhotoUrl(userPhotoUrl: ?string): this {
        return this.clone(this._state.set('userPhotoUrl', userPhotoUrl));
    }

    get userLocation(): ?AddressLatLng {
        return this._state.get('userLocation');
    }

    setUserLocation(userLocation: ?AddressLatLng): this {
        return this.clone(this._state.set('userLocation', userLocation));
    }

    get vehicleIconUrl(): ?string {
        return this._state.get('vehicleIconUrl');
    }

    setVehicleIconUrl(vehicleIconUrl: ?string): this {
        return this.clone(this._state.set('vehicleIconUrl', vehicleIconUrl));
    }

    get source(): ?AddressLatLng {
        return this._state.get('source');
    }

    setSource(source: ?AddressLatLng): this {
        return this.clone(this._state.set('source', source));
    }

    get destination(): AddressLatLng {
        return this._state.get('destination');
    }

    setDestination(destination: AddressLatLng): this {
        return this.clone(this._state.set('destination', destination));
    }

    get customerName(): ?string {
        return this._state.get('customerName');
    }

    setCustomerName(customerName: ?string): this {
        return this.clone(this._state.set('customerName', customerName));
    }

    get orderDetail(): ?OrderDetail {
        return this._state.get('orderDetail');
    }

    setOrderDetail(orderDetail: ?OrderDetail): this {
        return this.clone(this._state.set('orderDetail', orderDetail));
    }

    get amount(): AmountTransaction {
        return this._state.get('amount');
    }

    setAmount(amount: AmountTransaction): this {
        return this.clone(this._state.set('amount', amount));
    }

    get tripId(): string {
        return this._state.get('tripId');
    }

    setTripId(tripId: string): this {
        return this.clone(this._state.set('tripId', tripId));
    }

    get eta(): string {
        return this._state.get('eta');
    }

    setEta(eta: string): this {
        return this.clone(this._state.set('eta', eta));
    }

    get pin(): ?string {
        return this._state.get('pin');
    }

    setPin(pin: ?string): this {
        return this.clone(this._state.set('pin', pin));
    }

    get tripDisplayStrings(): Immutable.Map<string, string> {
        return this._state.get('tripDisplayStrings');
    }

    setTripDisplayStrings(
        tripDisplayStrings: Immutable.Map<string, string>
    ): this {
        return this.clone(
            this._state.set('tripDisplayStrings', tripDisplayStrings)
        );
    }

    get status(): TripStatus {
        return this._state.get('status');
    }

    setStatus(status: TripStatus): this {
        return this.clone(this._state.set('status', status));
    }

    get enrouteOrders(): ?Immutable.List<EnrouteOrder> {
        return this._state.get('enrouteOrders');
    }

    setEnrouteOrders(enrouteOrders: ?Immutable.List<EnrouteOrder>): this {
        return this.clone(this._state.set('enrouteOrders', enrouteOrders));
    }

    get showLocationTrail(): ?boolean {
        return this._state.get('showLocationTrail');
    }

    setShowLocationTrail(showLocationTrail: ?boolean): this {
        return this.clone(
            this._state.set('showLocationTrail', showLocationTrail)
        );
    }

    get settings(): LiveViewSettings {
        return this._state.get('settings');
    }

    setSettings(settings: LiveViewSettings): this {
        return this.clone(this._state.set('settings', settings));
    }

    get vehicleType(): ?VehicleTypeEnum {
        return this._state.get('vehicleType');
    }

    setVehicleType(vehicleType: ?VehicleTypeEnum): this {
        return this.clone(this._state.set('vehicleType', vehicleType));
    }

    get userDisplayText(): string {
        return this._state.get('userDisplayText');
    }

    setUserDisplayText(userDisplayText: string): this {
        return this.clone(this._state.set('userDisplayText', userDisplayText));
    }

    get tripDisplayText(): string {
        return this._state.get('tripDisplayText');
    }

    setTripDisplayText(tripDisplayText: string): this {
        return this.clone(this._state.set('tripDisplayText', tripDisplayText));
    }

    get isCloudTelephonyEnabled(): boolean {
        return this._state.get('isCloudTelephonyEnabled');
    }

    setIsCloudTelephonyEnabled(isCloudTelephonyEnabled: boolean): this {
        return this.clone(
            this._state.set('isCloudTelephonyEnabled', isCloudTelephonyEnabled)
        );
    }

    get riderTemperature(): ?number {
        return this._state.get('riderTemperature');
    }

    setRiderTemperature(riderTemperature: ?number): this {
        return this.clone(
            this._state.set('riderTemperature', riderTemperature)
        );
    }

    get checklists(): CustomerChecklistData {
        return this._state.get('checklists');
    }

    setChecklists(checklists: CustomerChecklistData): this {
        return this.clone(this._state.set('checklists', checklists));
    }

    get customerNotes(): Immutable.List<string> {
        return this._state.get('customerNotes');
    }

    setCustomerNotes(customerNotes: Immutable.List<string>): this {
        return this.clone(this._state.set('customerNotes', customerNotes));
    }

    get rescheduleInfo(): RescheduleInfo {
        return this._state.get('rescheduleInfo');
    }

    setRescheduleInfo(rescheduleInfo: RescheduleInfo): this {
        return this.clone(this._state.set('rescheduleInfo', rescheduleInfo));
    }

    get expiredDisplayDate(): ?string {
        return this._state.get('expiredDisplayDate');
    }

    setExpiredDisplayDate(expiredDisplayDate: ?string): this {
        return this.clone(
            this._state.set('expiredDisplayDate', expiredDisplayDate)
        );
    }

    get banner(): ?Banner {
        return this._state.get('banner');
    }

    setBanner(banner: ?Banner): this {
        return this.clone(this._state.set('banner', banner));
    }
}
