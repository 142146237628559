//@flow
import * as Immutable from 'immutable';

import {
    ChecklistItem,
    CustomerChecklist,
} from 'models/flow/CustomerChecklist.js';
import type {
    ChecklistItemModelType,
    CustomerChecklistModelType,
} from 'models/flow/CustomerChecklist.js';

class InternalChecklistItem extends ChecklistItem {
    static fromJS(json: ChecklistItemModelType): InternalChecklistItem {
        const state: Object = Object.assign({}, json);
        state.possibleValues = Immutable.List(state.possibleValues);
        return new this(Immutable.Map(state));
    }
}

class InternalCustomerChecklist extends CustomerChecklist {
    static fromJS(json: CustomerChecklistModelType): InternalCustomerChecklist {
        const state: Object = Object.assign({}, json);
        state.items = Immutable.List(state.items).map((item) =>
            InternalChecklistItem.fromJS(item)
        );
        return new this(Immutable.Map(state));
    }

    get items(): Immutable.List<ChecklistItem> {
        return this._state.get('items');
    }
}

export {
    InternalChecklistItem as ChecklistItem,
    InternalCustomerChecklist as CustomerChecklist,
};
