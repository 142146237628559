//@flow
import * as Immutable from 'immutable';

import { AmountTransaction } from 'models/flow/AmountTransaction.js';
import type { AmountTransactionModelType } from 'models/flow/AmountTransaction.js';

import { Amount } from './Amount';
import I18nHelper from 'helpers/I18nHelper';
const nsTranslate = I18nHelper.getTranslate();

export const ExchangeTypeEnum = {
    Give: 'GIVE',
    Collect: 'COLLECT',
    None: 'NONE',
};

const ExchangeTypeTranslationMap = {
    GIVE: 'exchangeTypeGive',
    COLLECT: 'exchangeTypeCollect',
    NONE: 'exchangeTypeNone',
};

class InternalAmountTransaction extends AmountTransaction {
    static fromJS(json: AmountTransactionModelType): InternalAmountTransaction {
        const state: Object = Object.assign({}, json);
        state.amount = Amount.fromJS(state.amount);
        return new this(Immutable.Map(state));
    }

    get amount(): Amount {
        return this._state.get('amount');
    }
    getData(totalAmount) {
        const { amount: amountData, exchangeType } = this;
        if (!this.isValid()) {
            return null;
        }
        const { symbol, amount: amountValue } = amountData;
        let value = amountValue;
        let amountType = nsTranslate(ExchangeTypeTranslationMap[exchangeType]);
        const newValue = totalAmount - value;
        if (totalAmount && exchangeType === 'NONE' && newValue > 0) {
            value = newValue;
            amountType = nsTranslate('toPay');
        }
        return { symbol, amountType, value };
    }
    isValid(): boolean {
        return Boolean(this.amount?.amount);
    }
}

export { InternalAmountTransaction as AmountTransaction };
