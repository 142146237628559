// @flow
import React, { lazy, Suspense } from 'react';

type Opts = {
    fallback: React$Node,
};
export const lazyLoad = (
    importFunc: () => any,
    opts: Opts = { fallback: null }
): any => {
    const LazyComponent = lazy(importFunc);

    return (props) => (
        <Suspense fallback={opts.fallback}>
            <LazyComponent {...props} />
        </Suspense>
    );
};
