import React, { forwardRef } from 'react';
import { Box, mediaQueries } from '@locus.sh/neo';
import OrderStatusAndActions from 'sections/tripPane/OrderStatusAndActions';
import { ScrollIndicator } from 'components';

const getTopSectionStyles = function (isSticky, hidden) {
    return {
        ...(isSticky && {
            position: 'fixed',
            top: '0px',
            width: '100%',
            zIndex: 15,
            boxShadow: '0px 1px 6px rgba(9, 10, 12, 0.16)',
        }),
        visibility: hidden ? 'hidden' : 'visible',
        flexShrink: 0,
        borderRadius: '8px 8px 0 0',
        overflow: 'hidden',
        [mediaQueries.md]: {
            mt: 2,
            borderRadius: '0',
        },
    };
};

const TripPaneTopSection = forwardRef(({ isSticky, hidden, styles }, ref) => (
    <Box sx={getTopSectionStyles(isSticky, hidden)} ref={ref}>
        <ScrollIndicator />
        <OrderStatusAndActions styles={styles} isSticky={isSticky} />
    </Box>
));

export default TripPaneTopSection;
