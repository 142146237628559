// @flow
import EnvConfig from 'env-config';

const getConfig = (): any => {
    if (!EnvConfig.devo) {
        return EnvConfig;
    }

    const hostname = window.location.host;
    // Basically here we are changing the EndPoint to correct devo based on subdomain
    // example: for devo-2.via-sh.locus-dev.com, replacing devo with devo-2 in api endpoint
    const [subdomain] = hostname.split('.');
    const devo: string = /^devo(-\d+|-coverage)?$/.test(subdomain)
        ? subdomain
        : EnvConfig.devo;

    // NOTE: in devo.json apiEndPoint is template with {devo} as placeholder
    const apiEndPoint = EnvConfig.apiEndPoint.replace('{devo}', devo);

    return {
        ...EnvConfig,
        devo,
        apiEndPoint,
    };
};

export default (getConfig(): typeof EnvConfig);
