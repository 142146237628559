//@flow
import * as Immutable from 'immutable';

import { LatLng } from 'models/flow/LatLng.js';
import type { LatLngModelType } from 'models/flow/LatLng.js';

class InternalLatLng extends LatLng {
    static fromJS(json: LatLngModelType): InternalLatLng {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }

    getKey(index) {
        return `${this.lat}-${this.lng}-${index}`;
    }
}

export { InternalLatLng as LatLng };
