//@flow
import * as Immutable from 'immutable';

import { OrderDetail } from 'models/flow/OrderDetail.js';
import type { OrderDetailModelType } from 'models/flow/OrderDetail.js';

import { LineItem } from './LineItem';

class InternalOrderDetail extends OrderDetail {
    static fromJS(json: OrderDetailModelType): InternalOrderDetail {
        const state: Object = Object.assign({}, json);
        state.lineItems = Immutable.List(state.lineItems).map((item) =>
            LineItem.fromJS(item)
        );
        return new this(Immutable.Map(state));
    }

    get lineItems(): Immutable.List<LineItem> {
        return this._state.get('lineItems');
    }
}

export { InternalOrderDetail as OrderDetail };
