//@flow
import * as React from 'react';
import TripContainer from './TripContainer';
import TripStateProvider from 'trip/TripStateProvider';
import { RescheduleProvider } from 'sections/state/reschedule';
import { ToastProvider } from '@locus.sh/neo';

function TripRoute(props): React.Node {
    return (
        <TripStateProvider>
            <RescheduleProvider>
                <ToastProvider>
                    <TripContainer tripId={props.tripId} />
                </ToastProvider>
            </RescheduleProvider>
        </TripStateProvider>
    );
}

export default TripRoute;
