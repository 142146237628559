// @flow
import React from 'react';
import { Flex, Box } from '@locus.sh/neo';

type SlotProps = {
    children: any,
    disabled: boolean,
    selected: boolean,
    onClick: () => any,
};

export default function Slot({
    children,
    disabled,
    selected,
    onClick,
}: SlotProps): React$Element<any> {
    const getTextColor = () => {
        if (disabled) {
            return 'grey.500';
        }
        if (selected) {
            return 'blue.600';
        }
        return 'grey.900';
    };

    const getBackgroundColor = () => {
        if (disabled) {
            return 'grey.100';
        }
        if (selected) {
            return 'blue.100';
        }
        return 'initial';
    };

    const getBorderColor = () => {
        if (disabled) {
            return 'grey.200';
        }
        if (selected) {
            return 'blue.500';
        }
        return 'grey.300';
    };

    return (
        <Flex flexBasis="50%" paddingRight={2}>
            <Box
                width="100%"
                padding={3}
                marginBottom={4}
                borderWidth="thin"
                borderColor={getBorderColor()}
                borderStyle="solid"
                borderRadius="0.25rem"
                backgroundColor={getBackgroundColor()}
                color={getTextColor()}
                onClick={() => {
                    if (disabled) {
                        return;
                    }
                    onClick();
                }}
                sx={{
                    cursor: 'pointer',
                    userSelect: 'none',
                    textAlign: 'center',
                    fontSize: 2,
                }}
            >
                {children}
            </Box>
        </Flex>
    );
}
