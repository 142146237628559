//@flow
export const toSentenceCaseFromMixedCase = (str: string): string => {
    const delim = '. ';
    const arr = str.split(delim); // split multiple sentences
    return arr
        .map((s) => {
            const formatted =
                s.charAt(0).toUpperCase() + s.substr(1).toLowerCase();

            return formatted;
        })
        .join(delim);
};

export const isValidString = (value: any): boolean => {
    return value && String(value).length > 0;
};
