// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel'; // Make sure you copied this file into your repo

import { LatLng } from './LatLng';
import type { LatLngModelType } from './LatLng';
export type EnrouteOrderModelType = {
    latLng: LatLngModelType,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class EnrouteOrder extends ImmutableModel {
    static fromJS(json: EnrouteOrderModelType): EnrouteOrder {
        const state: Object = Object.assign({}, json);
        state.latLng = LatLng.fromJS(state.latLng);
        return new this(Immutable.Map(state));
    }

    toJS(): EnrouteOrderModelType {
        return {
            latLng: this.latLng.toJS(),
        };
    }

    get latLng(): LatLng {
        return this._state.get('latLng');
    }

    setLatLng(latLng: LatLng): this {
        return this.clone(this._state.set('latLng', latLng));
    }
}
