// @flow
import { TripStatusEnum } from 'models/internal/TripStatus';

const ExpiryInfoStatusEnum = {
    COMPLETED: TripStatusEnum.Completed,
    CANCELLED: TripStatusEnum.Cancelled,
    RESCHEDULED: 'RESCHEDULED',
};

export { ExpiryInfoStatusEnum };
