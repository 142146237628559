// @flow
import * as React from 'react';
import { Text, Value, Box, Divider, Flex } from '@locus.sh/neo';
import { useTranslation } from 'react-i18next';
import { useTripContext } from 'trip/useTripContext';
import testIds from 'tests/trip-pane/testIds.json';
import Amount from 'components/Amount';
import { isEmpty } from 'helpers/DataHelper';

import type { TripInfo } from 'models/internal/TripInfo';

const canShowOrderDetails = (tripInfo: TripInfo) => {
    const lineItems = tripInfo.getLineItems();
    const {
        settings: {
            moduleSettings: { enableOrderModule },
        },
    } = tripInfo;

    if (enableOrderModule && lineItems.size > 0) {
        return true;
    }
    return false;
};

const OrderDetails = () => {
    const [{ tripInfo }] = useTripContext();
    const { amount } = tripInfo;
    const lineItems = tripInfo.getLineItems();
    const { showAmount } = tripInfo.settings;
    const itemCount = lineItems?.size;
    const { t } = useTranslation();
    if (itemCount === 0) {
        return null;
    }
    return (
        <Box p={0} data-test-id={testIds.orderDetailsModule}>
            <Box p={4}>
                <Text
                    fontWeight={600}
                    color="grey.900"
                    fontSize={2}
                    data-test-id={testIds.orderDetails}
                >
                    {t('itemDetails')}
                </Text>
            </Box>
            <Box mb={4}>
                <Divider color="grey.300" />
            </Box>
            {lineItems.map((lineItem) => {
                const { name, price, id } = lineItem;
                const { symbol, amount } = price;
                return (
                    <Flex key={id} py={2} px={4}>
                        <Text
                            color="grey.600"
                            data-test-id={testIds.lineItemName}
                            fontSize={2}
                        >
                            {name || id}
                        </Text>
                        {showAmount && !isEmpty(amount) && (
                            <Text
                                ml="auto"
                                fontSize={2}
                                color="grey.600"
                                data-test-id={testIds.lineItemAmount}
                            >
                                {symbol}{' '}
                                <Value>{parseFloat(amount).toFixed(2)}</Value>
                            </Text>
                        )}
                    </Flex>
                );
            })}
            {!isEmpty(amount?.amount?.amount) && (
                <Box px={4}>
                    <Box>
                        <Divider color="grey.300" mb={2} />
                    </Box>
                    <Flex pt={3} pb={4} alignItems="center">
                        <Text mr={1} fontSize={2} fontWeight={600}>
                            {t('total')}
                        </Text>
                        <Text
                            data-test-id={testIds.itemCount}
                            fontSize={2}
                            color="grey.600"
                            fontWeight={400}
                        >
                            ({t('itemCount', { count: itemCount })})
                        </Text>
                        <Amount
                            tripInfo={tripInfo}
                            sx={{ marginLeft: 'auto' }}
                            showTotalAmount
                            showAmountType
                            data-test-id={testIds.totalAmount}
                        />
                    </Flex>
                </Box>
            )}
        </Box>
    );
};

export { canShowOrderDetails };
export default OrderDetails;
