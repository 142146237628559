// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel'; // Make sure you copied this file into your repo

export const Position = {
    TOP: 'TOP',
    MIDDLE: 'MID',
};

type PositionType = $Keys<typeof Position>;

export type BannerModelType = {
    bannerUrl: string,
    redirectUrl?: string,
    position: PositionType,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class Banner extends ImmutableModel {
    static fromJS(json: BannerModelType): Banner {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }

    toJS(): BannerModelType {
        const js: BannerModelType = {
            bannerUrl: this.bannerUrl,
            position: this.position,
        };

        if (this.redirectUrl != null) {
            js.redirectUrl = this.redirectUrl;
        }

        return js;
    }

    get bannerUrl(): string {
        return this._state.get('bannerUrl');
    }

    setBannerUrl(bannerUrl: string): this {
        return this.clone(this._state.set('bannerUrl', bannerUrl));
    }

    get redirectUrl(): ?string {
        return this._state.get('redirectUrl');
    }

    setRedirectUrl(redirectUrl: ?string): this {
        return this.clone(this._state.set('redirectUrl', redirectUrl));
    }

    get position(): PositionType {
        return this._state.get('position');
    }

    setPosition(position: PositionType): this {
        return this.clone(this._state.set('position', position));
    }
}
