//@flow
import * as Immutable from 'immutable';
import { Direction } from 'models/flow/Direction.js';
import type { DirectionModelType } from 'models/flow/Direction.js';

class InternalDirection extends Direction {
    static fromJS(json: DirectionModelType): InternalDirection {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }
}

export { InternalDirection as Direction };
