// @flow
import React from 'react';
import { useTripContext } from 'trip/useTripContext';
import { RiderImage } from 'sections/tripPane/OrderStatusAndActions/RiderImage';
import { Box, Text, Flex, Icon } from '@locus.sh/neo';
import { useTranslation } from 'react-i18next';
import { type TripInfo } from 'models/internal/TripInfo';
import testIds from 'tests/trip-pane/testIds.json';

const getTemperatureUnit = (unit) => {
    switch (unit) {
        case 'FAHRENHEIT':
            return '°F';
        case 'CELSIUS':
            return '°C';
        default:
            return '';
    }
};

const getRiderNameHTML = (tripInfo, translator) => {
    const { userName } = tripInfo;
    const riderName = userName ?? translator('driver');

    return <>{translator('userDisplayText', { riderName })}</>;
};

export function canShowRiderDetails(tripInfo: TripInfo): boolean {
    const isReceivedORAssigned = tripInfo.isReceived() || tripInfo.isAssigned();
    const {
        settings: {
            moduleSettings: { enableDriverModule },
        },
    } = tripInfo;
    return enableDriverModule && !isReceivedORAssigned;
}

type Props = {
    sx?: Object,
};

export default function RiderDetails({
    sx = {},
}: Props): React$Element<any> | null {
    const { t } = useTranslation();
    const [{ tripInfo }] = useTripContext();
    const {
        settings: {
            moduleSettings: { temperatureUnit },
        },
    } = tripInfo;

    if (!canShowRiderDetails(tripInfo)) {
        return null;
    }
    const isTerminal = tripInfo.isTerminal();

    if (isTerminal) {
        return (
            <Box data-test-id={testIds.riderModule}>
                <Text
                    fontSize={1}
                    color="grey.600"
                    data-test-id={testIds.riderDisplayText}
                >
                    {getRiderNameHTML(tripInfo, t)}
                </Text>
            </Box>
        );
    }

    return (
        <Flex sx={sx} data-test-id={testIds.riderModule}>
            <Box mr={3}>
                <RiderImage url={tripInfo.userPhotoUrl} />
            </Box>
            <Flex justifyContent="center" flexDirection="column">
                <Text
                    fontSize={1}
                    color="grey.600"
                    data-test-id={testIds.riderDisplayText}
                >
                    {getRiderNameHTML(tripInfo, t)}
                </Text>
                {tripInfo.riderTemperature && (
                    <Flex alignItems="center" mt="2px">
                        <Icon
                            icon="motiontrack_thermometer_filled"
                            color="blue.600"
                            size="small"
                            mr="2px"
                        />
                        <Flex>
                            <Text
                                fontSize={1}
                                mr="2px"
                                color="blue.600"
                                data-test-id={testIds.riderTemperature}
                            >
                                {`${
                                    tripInfo.riderTemperature
                                } ${getTemperatureUnit(temperatureUnit)}`}
                            </Text>
                            <Text
                                fontSize={1}
                                color="grey.600"
                                fontWeight={600}
                            >
                                @today
                            </Text>
                        </Flex>
                    </Flex>
                )}
            </Flex>
        </Flex>
    );
}
