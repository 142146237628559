// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel'; // Make sure you copied this file into your repo
import type { KeyValue } from './Common';
import type { STATUS } from './Status';

export const formats = {
    SINGLE_CHOICE: 'SINGLE_CHOICE',
    BOOLEAN: 'BOOLEAN',
    RATING: 'RATING',
    TEXT_FIELD: 'TEXT_FIELD',
    URL: 'URL',
};

export type FORMAT = $Keys<typeof formats>;

type AllowedValue = {
    key: string,
    value: string,
};

export type ChecklistItemModelType = {
    key: string,
    item: string,
    format?: FORMAT,
    type?: FORMAT,
    possibleValues: Array<string>,
};

export type CustomerChecklistModelType = {
    status: STATUS,
    items: Array<ChecklistItemModelType>,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class ChecklistItem extends ImmutableModel {
    static fromJS(json: ChecklistItemModelType): ChecklistItem {
        const state: Object = Object.assign({}, json);
        state.possibleValues = Immutable.List(state.possibleValues);
        return new this(Immutable.Map(state));
    }

    toJS(): ChecklistItemModelType {
        const js: ChecklistItemModelType = {
            key: this.key,
            item: this.item,
            possibleValues: this.possibleValues.toArray(),
        };

        if (this.format != null) {
            js.format = this.format;
        }

        if (this.type != null) {
            js.type = this.type;
        }

        return js;
    }

    get key(): string {
        return this._state.get('key');
    }

    setKey(key: string): this {
        return this.clone(this._state.set('key', key));
    }

    get item(): string {
        return this._state.get('item');
    }

    setItem(item: string): this {
        return this.clone(this._state.set('item', item));
    }

    get format(): ?FORMAT {
        return this._state.get('format');
    }

    setFormat(format: ?FORMAT): this {
        return this.clone(this._state.set('format', format));
    }

    get type(): ?FORMAT {
        return this._state.get('type');
    }

    setType(type: ?FORMAT): this {
        return this.clone(this._state.set('type', type));
    }

    get possibleValues(): Immutable.List<string> {
        return this._state.get('possibleValues');
    }

    setPossibleValues(possibleValues: Immutable.List<string>): this {
        return this.clone(this._state.set('possibleValues', possibleValues));
    }
}

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class CustomerChecklist extends ImmutableModel {
    static fromJS(json: CustomerChecklistModelType): CustomerChecklist {
        const state: Object = Object.assign({}, json);
        state.items = Immutable.List(state.items).map((item) =>
            ChecklistItem.fromJS(item)
        );
        return new this(Immutable.Map(state));
    }

    toJS(): CustomerChecklistModelType {
        return {
            status: this.status,
            items: this.items.toArray().map((item) => item.toJS()),
        };
    }

    get status(): STATUS {
        return this._state.get('status');
    }

    setStatus(status: STATUS): this {
        return this.clone(this._state.set('status', status));
    }

    get items(): Immutable.List<ChecklistItem> {
        return this._state.get('items');
    }

    setItems(items: Immutable.List<ChecklistItem>): this {
        return this.clone(this._state.set('items', items));
    }
}
