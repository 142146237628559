//@flow
import React from 'react';
import { GlobalStyle } from '@locus.sh/neo';
import TripRoute from './TripRoute';
import { NotFound } from 'components';
import { Router } from '@reach/router';
import { Global } from '@emotion/core';
import { hot } from 'react-hot-loader/root';

const styles = {
    body: {
        fontFamily: `'Noto Sans', sans-serif`,
    },
    '#router-root': {
        height: '100%',
        width: '100%',
    },
};

function App() {
    return (
        <>
            <GlobalStyle />
            <Global styles={styles} />
            <Router id="router-root">
                <TripRoute path="/:tripId" />
                <NotFound default />
            </Router>
        </>
    );
}

export default hot(App);
