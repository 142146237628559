//@flow
import React from 'react';
import Polyline from 'components/maps/Polyline';
import { useDirectionsContext } from 'sections/state/directions';

const TripDirections = (props) => {
    const { directions } = useDirectionsContext();

    return directions ? (
        <Polyline
            path={google.maps.geometry.encoding.decodePath(directions)}
            map={props.map}
        />
    ) : null;
};

export { TripDirections };

export default TripDirections;
