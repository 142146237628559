// @flow
import React from 'react';
import { GoogleMap, Marker } from 'components';
import { useTripContext } from 'trip/useTripContext';
import { EnrouteOrderMarker } from './EnrouteOrderMarker';
import { TripDirections } from './TripDirections';
import { useTripBounds } from './hooks';
import UserMarker from './UserMarker';
import DestinationMarker from './DestinationMarker';
import { fitBoundsAndResetZoom } from 'components/maps/FitBounds';
import { withGoogleMapLoader } from 'components/maps/LoadGoogleMap';

const TripDynamicMap: $FlowFixMe = React.forwardRef((props, ref) => {
    const { center } = props;
    const [state] = useTripContext();
    const { tripInfo, directions } = state;
    const { showLocationTrail, settings, enrouteOrders } = tripInfo;
    const { showEnrouteOrders } = settings;
    const { destinationLatLng, sourceLatLng, userLatLng } =
        tripInfo.getMapData();
    const isInitial = tripInfo.isInitial();
    const isTerminal = tripInfo.isTerminal();

    React.useImperativeHandle(ref, () => ({
        refresh() {
            fitBounds();
        },
    }));

    const { bounds, numberOfPoints } = useTripBounds({
        destinationLatLng,
        sourceLatLng: isInitial ? sourceLatLng : undefined,
        userLatLng: !(isInitial || isTerminal) ? userLatLng : undefined,
        directions,
        showLocationTrail,
        showEnrouteOrders,
        enrouteOrders: !isInitial ? enrouteOrders : undefined,
    });
    const [map, setMap] = React.useState();
    const onLoadCallback = (newMapInstance) => setMap(newMapInstance);
    const fitBounds = () => {
        fitBoundsAndResetZoom({
            map,
            bounds,
            numberOfPoints,
        });
    };

    return (
        <GoogleMap
            center={center}
            zoom={15}
            bounds={bounds}
            onLoad={onLoadCallback}
            numberOfPoints={numberOfPoints}
        >
            <DestinationMarker />
            {sourceLatLng && isInitial && (
                <>
                    <Marker
                        imagePositioning="BOTTOM_CENTERED"
                        position={sourceLatLng}
                        iconType="secondary"
                    />
                </>
            )}
            {!isInitial && !isTerminal && (
                <>
                    <EnrouteOrderMarker />
                    <UserMarker />
                    <TripDirections map={map} />
                </>
            )}
        </GoogleMap>
    );
});

TripDynamicMap.defaultProps = {
    center: { lat: 12.933852, lng: 77.620284 },
};

export default withGoogleMapLoader(TripDynamicMap);
