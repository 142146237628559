import React, { useState } from 'react';
import { Image } from '@locus.sh/neo';

const ImageWithFallback = (props) => {
    const [src, setSrc] = useState(props.src || props.fallbackUrl);
    return (
        <Image onError={() => setSrc(props.fallbackUrl)} {...props} src={src} />
    );
};

export { ImageWithFallback };
export default ImageWithFallback;
