// @flow
import React from 'react';
import Otp from 'sections/reschedule/OTP';
import type { TripInfo } from 'models/internal/TripInfo';
import ChooseSlot from 'sections/reschedule/ChooseSlot';
import { useRescheduleContext } from 'sections/state/reschedule';
import { Button, useToast } from '@locus.sh/neo';
import { apiToastDefaultOptions } from 'helpers/ToastHelper';
import { useTranslation } from 'react-i18next';
import { useTripContext } from 'trip/useTripContext';
import testIds from 'tests/trip-pane/testIds.json';
import Tracker from 'helpers/Tracker';

export const canReschedule = (tripInfo: TripInfo): boolean => {
    const {
        settings: {
            customerRescheduleSettings: { allowCustomerReschedule },
        },
    } = tripInfo;

    const isValidState =
        tripInfo.isAssigned() || tripInfo.isStarted() || tripInfo.isAccepted();

    return allowCustomerReschedule && isValidState;
};

const areReschedulesExhausted = (tripInfo: TripInfo): boolean => {
    const {
        settings: {
            customerRescheduleSettings: { reschedulesExhausted },
        },
    } = tripInfo;

    return reschedulesExhausted;
};

type Props = {
    sx?: Object,
};

export function RescheduleButton({ sx }: Props): React$Element<any> | null {
    const [{ tripInfo }] = useTripContext();
    const { startRescheduleFlow, fetchingFreshTripData } =
        useRescheduleContext();
    const { showToast, closeAllToasts } = useToast();
    const { t } = useTranslation();

    const { tripId } = tripInfo;

    const handleRescheduleButtonClick = (): any => {
        if (areReschedulesExhausted(tripInfo)) {
            Tracker.track('rescheduleExhausted', {
                tripId,
            });
            closeAllToasts();
            return showToast({
                title: t('maximumRescheduleDoneTitle'),
                content: t('maximumRescheduleDoneMessage'),
                palette: 'danger',
                ...apiToastDefaultOptions,
            });
        }

        startRescheduleFlow();
    };

    if (canReschedule(tripInfo)) {
        return (
            <Button
                sx={sx}
                palette="primary"
                appearance="outline"
                onClick={handleRescheduleButtonClick}
                icon="reschedule"
                isLoading={fetchingFreshTripData}
                // partial disabled behaviour, as button is supposed to be clickable to show error
                opacity={areReschedulesExhausted(tripInfo) ? '0.5' : null}
                size="large"
                data-test-id={testIds.rescheduleButton}
            >
                {t('reschedule')}
            </Button>
        );
    }

    return null;
}

type RescheduleProps = {
    tripInfo: TripInfo,
};
function Reschedule({ tripInfo }: RescheduleProps): React$Element<any> | null {
    const { slots } = useRescheduleContext();
    const { tripId } = tripInfo;

    if (slots) {
        return <ChooseSlot tripId={tripId} />;
    }

    return <Otp tripId={tripId} />;
}

export default Reschedule;
