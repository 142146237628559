// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel'; // Make sure you copied this file into your repo

import { Amount } from './Amount';
import type { AmountModelType } from './Amount';
export type ExchangeTypeEnumType = 'COLLECT' | 'GIVE' | 'NONE';
export type AmountTransactionModelType = {
    /**  Amount that needs to be transacted */
    amount: AmountModelType,
    /** Type of transaction */
    exchangeType: ExchangeTypeEnumType,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class AmountTransaction extends ImmutableModel {
    static fromJS(json: AmountTransactionModelType): AmountTransaction {
        const state: Object = Object.assign({}, json);
        state.amount = Amount.fromJS(state.amount);
        return new this(Immutable.Map(state));
    }

    toJS(): AmountTransactionModelType {
        return {
            amount: this.amount.toJS(),
            exchangeType: this.exchangeType,
        };
    }

    get amount(): Amount {
        return this._state.get('amount');
    }

    setAmount(amount: Amount): this {
        return this.clone(this._state.set('amount', amount));
    }

    get exchangeType(): ExchangeTypeEnumType {
        return this._state.get('exchangeType');
    }

    setExchangeType(exchangeType: ExchangeTypeEnumType): this {
        return this.clone(this._state.set('exchangeType', exchangeType));
    }
}
