// @flow
import React from 'react';
import { Flex, Position, mediaQueries } from '@locus.sh/neo';
import { ClientLogo } from './ClientLogo';
import { OrderIdCard } from './OrderIdCard';
import testIds from 'tests/trip-header/testIds.json';
import { TripInfo } from 'models/flow/TripInfo.js';

const HeaderStyle = {
    transition: '0.3s ease all',
    zIndex: '12',
};

const PositionStyle = {
    [mediaQueries.md]: {
        position: 'relative',
    },
};

type Props = {
    tripInfo: TripInfo,
};

export const TRIP_HEADER_HEIGHT = 64;

export const canShowTripHeader = (tripInfo: TripInfo): boolean => {
    const {
        settings: {
            moduleSettings: { enableHeaderModule },
        },
    } = tripInfo;
    return enableHeaderModule;
};

const TripHeader = function (props: Props) {
    const { tripInfo } = props;
    const { logo, displayName, websiteUrl } = tripInfo.settings;
    const { taskId } = tripInfo.visitId;

    return (
        <Position
            display="flex"
            flexDirection="column"
            sx={PositionStyle}
            width="100%"
        >
            <Flex
                flex="0 0 auto"
                p={0}
                width="100%"
                height={`${TRIP_HEADER_HEIGHT}px`}
                sx={HeaderStyle}
                backgroundColor="white"
                borderRadius="2px"
                minWidth={1}
                data-test-id={testIds.tripHeader}
            >
                <ClientLogo
                    websiteUrl={websiteUrl}
                    alt={displayName}
                    logo={logo}
                />
                <OrderIdCard orderId={taskId} />
            </Flex>
        </Position>
    );
};

export { TripHeader };

export default TripHeader;
