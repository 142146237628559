//@flow
import * as React from 'react';
import { OverlayView } from '@react-google-maps/api';
export class CustomOverlayView extends React.PureComponent {
    state = {
        currentPosition: this.props.position,
    };
    setPosition(position) {
        this.setState({ currentPosition: position });
    }
    render() {
        const { currentPosition } = this.state;
        return <OverlayView {...this.props} position={currentPosition} />;
    }
}
export default CustomOverlayView;
