//@flow
import {
    TripReducerState,
    UIStatusEnum,
} from 'models/internal/TripReducerState';

export const initialState = TripReducerState.fromJS({
    tripStatus: UIStatusEnum.Loading,
});

export const reducer = (state, action) => {
    switch (action.type) {
        case 'TRIP_FETCH_START': {
            return state.setTripStatus(UIStatusEnum.Loading);
        }
        case 'TRIP_FETCH_SUCCESS': {
            const { data } = action;
            return state
                .setTripInfo(data)
                .setTripStatus(UIStatusEnum.InitialSuccess);
        }
        case 'TRIP_FETCH_FAILURE': {
            return state
                .setTripStatus(UIStatusEnum.Error)
                .setError(action.error);
        }
        case 'TRIP_POLL_START': {
            return state.setTripStatus(UIStatusEnum.Polling);
        }
        case 'TRIP_POLL_FAILURE': {
            return state
                .setTripStatus(UIStatusEnum.PollError)
                .setError(action.error);
        }
        case 'TRIP_POLL_SUCCESS': {
            const { data } = action;

            return state.setTripInfo(data).setTripStatus(UIStatusEnum.Idle);
        }
        case 'TRIP_REFRESH_START': {
            return state.setTripStatus(UIStatusEnum.Polling);
        }
        case 'TRIP_REFRESH_FAILURE': {
            return state.setTripStatus(UIStatusEnum.PollError);
        }
        case 'TRIP_REFRESH_SUCCESS': {
            const { data } = action;

            return state.setTripInfo(data).setTripStatus(UIStatusEnum.Idle);
        }
        case 'TRIP_DIRECTIONS_FETCH_SUCCESS': {
            const { data } = action;
            return state.setDirections(data);
        }
        case 'TRIP_DIRECTIONS_FETCH_FAILURE': {
            return state.setError(action.error);
        }

        default:
            return state;
    }
};
