import * as React from 'react';
import { Text, Value } from '@locus.sh/neo';

export const Price = (props) => {
    const { symbol, value, ...others } = props;
    const valueWithDecimal = Number.parseFloat(value).toFixed(2);

    return (
        <Text fontSize={2} {...others}>
            {symbol} <Value>{valueWithDecimal}</Value>
        </Text>
    );
};

export default Price;
