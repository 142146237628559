import * as React from 'react';
import { GoogleMap as GoogleMapReact } from '@react-google-maps/api';
import MapZoomControl from './MapZoomControl';
import FitBounds from './FitBounds';
import DEFAULT_STYLES from './googleMapStyles.json';

import styled from '@emotion/styled';
import { css } from 'emotion';
const StyledGoogleMapReact = styled(GoogleMapReact)({
    background: 'red',
});
const GoogleMap = function (props) {
    const { zoom, center, options, bounds, numberOfPoints, ...rest } = props;
    return (
        <StyledGoogleMapReact
            zoom={zoom}
            center={center}
            options={options}
            mapContainerClassName={css({
                height: '100%',
                '> div > div': { background: '#efefef' },
            })}
            {...rest}
        >
            <FitBounds bounds={bounds} numberOfPoints={numberOfPoints} />
            <MapZoomControl />
            {props.children}
        </StyledGoogleMapReact>
    );
};

GoogleMap.defaultProps = {
    center: {
        lat: -3.745,
        lng: -38.523,
    },
    zoom: 7,
    // Styles reference: https://developers.google.com/maps/documentation/javascript/style-reference
    options: { disableDefaultUI: true, styles: DEFAULT_STYLES },
};
export { GoogleMap };
export default GoogleMap;
