//@flow
import * as React from 'react';
import { Text } from '@locus.sh/neo';

export const TIME_SECONDS = 1000;

export const TimeWithUnit = ({ time, unit, ...rest }) => {
    return (
        <>
            <Text fontSize={2} lineHeight={3} {...rest}>
                {time}
            </Text>{' '}
            <Text fontSize={1}>{unit}</Text>
        </>
    );
};
