//@flow
import * as React from 'react';
import { useGoogleMap } from '@react-google-maps/api';

const resetZoom = function ({ map, minZoom = 16, numberOfPoints = 0 }) {
    google.maps.event.addListenerOnce(map, 'idle', () => {
        if (numberOfPoints < 2 && map.getZoom() > minZoom) {
            map.setZoom(minZoom);
        }
    });
};
export const fitBoundsAndResetZoom = function ({
    map,
    bounds,
    numberOfPoints,
}) {
    bounds && map && map.fitBounds(bounds);
    resetZoom({ map, numberOfPoints });
};

const FitBounds = ({ bounds, minZoom, numberOfPoints }) => {
    const map = useGoogleMap();
    React.useEffect(() => {
        fitBoundsAndResetZoom({
            map,
            bounds,
            numberOfPoints,
        });
    }, [map, bounds, minZoom, numberOfPoints]);
    return null;
};
export { FitBounds };
export default FitBounds;
