// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel'; // Make sure you copied this file into your repo

export const statuses = {
    COMPLETED: 'COMPLETED',
    CANCELLED: 'CANCELLED',
};

export type STATUS = $Keys<typeof statuses>;

export type StatusModelType = {
    status: STATUS,
    triggeredOn: string, // date ISO
    displayText: string,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class Status extends ImmutableModel {
    static fromJS(json: StatusModelType): Status {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }

    toJS(): StatusModelType {
        return {
            status: this.status,
            triggeredOn: this.triggeredOn,
            displayText: this.displayText,
        };
    }

    get status(): STATUS {
        return this._state.get('status');
    }

    setStatus(status: STATUS): this {
        return this.clone(this._state.set('status', status));
    }

    get triggeredOn(): string {
        return this._state.get('triggeredOn');
    }

    setTriggeredOn(triggeredOn: string): this {
        return this.clone(this._state.set('triggeredOn', triggeredOn));
    }

    get displayText(): string {
        return this._state.get('displayText');
    }

    setDisplayText(displayText: string): this {
        return this.clone(this._state.set('displayText', displayText));
    }
}
