// @flow
import React, { createContext, useState, useContext } from 'react';

type Props = {
    children: React$Element<any>,
};

const RescheduleContext = createContext();

const RescheduleProvider = ({ children }: Props): React$Element<any> => {
    const [rescheduleFlow, setRescheduleFlow] = useState(false);
    const [otp, setOtp] = useState('');
    const [slots, setSlots] = useState(null);
    const [rescheduleSuccess, setRescheduleSuccess] = useState(false);
    const [fetchingFreshTripData, setFetchingFreshTripData] = useState(false);

    const startRescheduleFlow = () => setRescheduleFlow(true);
    const stopRescheduleFlow = () => {
        setRescheduleFlow(false);
        setSlots(null);
        setOtp('');
    };

    const startedFetchingFreshTripData = () => {
        setRescheduleSuccess(false);
        setFetchingFreshTripData(true);
    };

    const stoppedFetchingFreshTripData = () => {
        setFetchingFreshTripData(false);
    };

    return (
        <RescheduleContext.Provider
            value={{
                rescheduleFlow,
                startRescheduleFlow,
                stopRescheduleFlow,
                otp,
                setOtp,
                slots,
                setSlots,
                rescheduleSuccess,
                setRescheduleSuccess,
                fetchingFreshTripData,
                startedFetchingFreshTripData,
                stoppedFetchingFreshTripData,
            }}
        >
            {children}
        </RescheduleContext.Provider>
    );
};

const useRescheduleContext = (): any => {
    const context = useContext(RescheduleContext);
    return context;
};

export { useRescheduleContext, RescheduleProvider };
