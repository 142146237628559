// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel'; // Make sure you copied this file into your repo

import { Amount } from './Amount';

import type { AmountModelType } from './Amount';

export type LineItemModelType = {
    /**
     * Name of the item
     * */
    name: string,

    /**
     * Quantity of the item
     * */
    quantity: number,

    /**
     * An identifier for the line item
     * */
    id: string,

    /**
     * Price of the item
     * */
    price: AmountModelType,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class LineItem extends ImmutableModel {
    static fromJS(json: LineItemModelType): LineItem {
        const state: Object = Object.assign({}, json);
        state.price = Amount.fromJS(state.price);
        return new this(Immutable.Map(state));
    }

    toJS(): LineItemModelType {
        return {
            name: this.name,
            quantity: this.quantity,
            id: this.id,
            price: this.price.toJS(),
        };
    }

    get name(): string {
        return this._state.get('name');
    }

    setName(name: string): this {
        return this.clone(this._state.set('name', name));
    }

    get quantity(): number {
        return this._state.get('quantity');
    }

    setQuantity(quantity: number): this {
        return this.clone(this._state.set('quantity', quantity));
    }

    get id(): string {
        return this._state.get('id');
    }

    setId(id: string): this {
        return this.clone(this._state.set('id', id));
    }

    get price(): Amount {
        return this._state.get('price');
    }

    setPrice(price: Amount): this {
        return this.clone(this._state.set('price', price));
    }
}
