// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel'; // Make sure you copied this file into your repo

export type LatLngModelType = {
    lat: string,
    lng: string,
    /**
     * Accuracy of lat,lng in meters.
     * If the lat,lng denotes a certain region instead of the exact address,
     * this field can be used to denote the accuracy of the same.
     * For example, if the lat,lng denotes a region of 2km x 2km, put this value as 2000.
     * A value of 0 or a null value denotes that lat,lng is exact.
     **/
    accuracy: number,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class LatLng extends ImmutableModel {
    static fromJS(json: LatLngModelType): LatLng {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }

    toJS(): LatLngModelType {
        return {
            lat: this.lat,
            lng: this.lng,
            accuracy: this.accuracy,
        };
    }

    get lat(): string {
        return this._state.get('lat');
    }

    setLat(lat: string): this {
        return this.clone(this._state.set('lat', lat));
    }

    get lng(): string {
        return this._state.get('lng');
    }

    setLng(lng: string): this {
        return this.clone(this._state.set('lng', lng));
    }

    get accuracy(): number {
        return this._state.get('accuracy');
    }

    setAccuracy(accuracy: number): this {
        return this.clone(this._state.set('accuracy', accuracy));
    }
}
