// @flow
import * as React from 'react';
import { Flex, mediaQueries } from '@locus.sh/neo';
import { canShowPaymentInfo } from 'components/PaymentInfo';
import {
    canShowTripHeader,
    TRIP_HEADER_HEIGHT,
} from 'sections/header/TripHeader';
import { canShowUrlCard, URL_CARD_HEIGHT } from 'sections/header/URLCard';
import { canShowBanner } from 'components/Banner';
import { useBannerContext } from 'sections/state/banner';

const bottomOffset = 184;
const getPaneStyle = function ({
    isShowingPaymentDetails,
    isHeaderShown,
    hasUrlChecklist,
    isBannerShown,
    bannerHeight,
}) {
    let height = 10;
    if (isHeaderShown) height += TRIP_HEADER_HEIGHT;
    if (hasUrlChecklist) height += URL_CARD_HEIGHT;
    if (isBannerShown) height += bannerHeight;

    return {
        position: 'relative',
        background: '#e5e5e5',
        transform: `translate3d(0, calc(100% - ${bottomOffset}px), 0)`,
        transition: '0.3s ease all',
        zIndex: '14',
        height: `calc(100% - ${height}px)`,
        flexShrink: 0,
        [mediaQueries.md]: {
            overflow: 'overlay',
            height: 'initial',
            transform: 'none',
            paddingTop: isShowingPaymentDetails ? '40px' : 0,
            zIndex: 11,
            flex: 1,
            borderRadius: '0',
            boxShadow: 'none',
        },
    };
};

const Pane = (props) => {
    const { tripInfo } = props;
    const { children } = props;
    const hasUrlChecklist = canShowUrlCard(tripInfo);
    const scrollablePaneRef = React.useRef();
    const isShowingPaymentDetails = canShowPaymentInfo(tripInfo);
    const isBannerShownOnTop = canShowBanner(tripInfo, 'belowLogo');
    const isHeaderShown = canShowTripHeader(tripInfo);
    const { bannerHeight } = useBannerContext();

    return (
        <Flex
            flexDirection="column"
            width="100%"
            boxShadow="active"
            sx={(theme) =>
                getPaneStyle({
                    theme,
                    isHeaderShown,
                    hasUrlChecklist,
                    isShowingPaymentDetails,
                    isBannerShown: isBannerShownOnTop,
                    bannerHeight,
                })
            }
            ref={scrollablePaneRef}
        >
            {children}
        </Flex>
    );
};

export { Pane };
export default Pane;
