// @flow
import * as React from 'react';
import { useEffect } from 'react';
import { useTripContext } from 'trip/useTripContext';
import { fetchDirection } from 'trip/helpers';
import { Direction } from 'models/internal/Direction';

const DirectionsContext = React.createContext({});

const DirectionsProvider = (props) => {
    const [{ tripInfo, directions }, dispatch] = useTripContext();

    const { showLocationTrail } = tripInfo;
    const { destinationLatLng: destination, userLatLng: origin } =
        tripInfo.getMapData();
    const originLat = origin?.lat;
    const originLng = origin?.lng;
    const destinationLat = destination?.lat;
    const destinationLng = destination?.lng;
    const isTerminal = tripInfo.isTerminal();

    useEffect(() => {
        if (originLat && originLng && showLocationTrail && !isTerminal) {
            fetchDirection({
                origin: { lat: originLat, lng: originLng },
                destination: { lat: destinationLat, lng: destinationLng },
            })(dispatch);
        } else if (isTerminal) {
            // Clearing the polyline when trip completes
            dispatch({
                type: 'TRIP_DIRECTIONS_FETCH_SUCCESS',
                data: Direction.fromJS(),
            });
        }
    }, [
        originLat,
        originLng,
        destinationLat,
        destinationLng,
        dispatch,
        isTerminal,
        showLocationTrail,
    ]);

    return (
        <DirectionsContext.Provider value={{ directions: directions.polyline }}>
            {props.children}
        </DirectionsContext.Provider>
    );
};

const useDirectionsContext = () => {
    const context = React.useContext(DirectionsContext);
    return context;
};

const withDirectionsProvider = (Component) => (props) =>
    (
        <DirectionsProvider>
            <Component {...props} />
        </DirectionsProvider>
    );

export { useDirectionsContext, DirectionsProvider, withDirectionsProvider };
