//@flow

export class MapHelper {
    static getIcon = function (iconUrl) {
        return {
            url: iconUrl,
            anchor: new window.google.maps.Point(24, 24),
            scaledSize: new window.google.maps.Size(48, 48),
        };
    };
    static makeBounds = function (extendedLatLng) {
        const bounds = new window.google.maps.LatLngBounds();
        const latLng = new window.google.maps.LatLng(extendedLatLng);
        return bounds.extend(latLng);
    };
    static computeHeading = function ({ startLatLng, endLatLng }) {
        if (!window.google || !startLatLng || !endLatLng) {
            return;
        }
        return window.google.maps.geometry.spherical.computeHeading(
            new window.google.maps.LatLng(startLatLng.lat, startLatLng.lng),
            new window.google.maps.LatLng(endLatLng.lat, endLatLng.lng)
        );
    };
}
export default MapHelper;
