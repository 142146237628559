//@flow
import dayjs from 'dayjs';
import preciseDiff from 'dayjs-precise-range';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import humanizeDuration from 'humanize-duration';
dayjs.extend(preciseDiff);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
    relativeTime: {
        s: '1 min',
        m: '1 min',
        mm: '%d min',
        h: '1 hr',
        hh: '%d hr',
        d: '1 d',
        dd: '%d d',
        M: '1 m',
        MM: '%d m',
        y: '1 yr',
        yy: '%d yr',
    },
});

export const DateTimeFormat = {
    HUMAN_DATE_FORMAT: 'D MMM',
    HUMAN_DATE_FORMAT_WITH_ORDINAL: 'Do MMM',
    HUMAN_DATE_TIME_FORMAT: 'D MMM, h:mma',
    HUMAN_DATE_TIME_FORMAT_WITH_ORDINAL: 'Do MMM, h:mma',
};

const shortEnglishHumanizer = humanizeDuration.humanizer({
    language: 'shortEn',
    languages: {
        shortEn: {
            d: () => 'day',
            h: () => 'hour',
            m: () => 'min',
            s: () => 'sec',
        },
    },
});

type HumanizerOptions = {
    largest: number, // Number representing the maximum number of units to display for the duration
};

export class TimeUtil {
    static isBefore = function (
        start: string,
        end: string,
        precision?: ?string
    ) {
        return start && end
            ? TimeUtil.dayjs(start).isBefore(TimeUtil.dayjs(end), precision)
            : null;
    };
    static now = () => {
        return dayjs();
    };
    static preciseDiff = dayjs.preciseDiff;
    // @DEPRECATED usage of dayjs
    static dayjs = (
        date: string,
        /**
         * this is used to support non-standard
         * date format in safari
         * reference: https://github.com/iamkun/dayjs/issues/73#issuecomment-464443622
         */
        customFormat?: ?string = 'YYYY-MM-DDTHH:mm:ss.000ZZ',
        ...args
    ) => {
        if (typeof date === 'string') {
            return dayjs(date, customFormat, ...args);
        }
        return date;
    };
    static format: ({ date?: string, format: string }) => string = ({
        date = new Date().toISOString(),
        format,
    }) => {
        return dayjs(date).format(format);
    };

    static getNextDate = (date: ?string) => {
        return dayjs(date).add(1, 'day');
    };

    static isSameDay = (date1: string, date2: string) => {
        return dayjs(date1).isSame(date2, 'day');
    };

    static toNow = (date: string) => {
        return dayjs(date).toNow(true);
    };

    static humanize = (milliseconds: number, options?: HumanizerOptions) =>
        shortEnglishHumanizer(milliseconds, options);
}

export default TimeUtil;
