import React from 'react';
import { StateProvider } from './useTripContext';
import { initialState, reducer } from './Store';

const TripStateProvider = (props) => {
    return (
        <StateProvider
            initialState={props.initialState}
            reducer={props.reducer}
        >
            {props.children}
        </StateProvider>
    );
};

TripStateProvider.defaultProps = {
    initialState,
    reducer,
};

export default TripStateProvider;
export { TripStateProvider };
