// @flow
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import I18nHelper from 'helpers/I18nHelper';

export const initializeFirebase = ({ clientId }) => {
    const firebaseConfig = {
        apiKey: 'AIzaSyAwRAVjxyyr-mJji9l1mdxxx9YMt9W6-Og',
        projectId: 'via-sh-firebase',
        appId: '1:8120008266:web:4bde3ee42415078dd9f0b3',
        measurementId: 'G-RG71754FNE',
    };

    try {
        const app = initializeApp(firebaseConfig);

        const analytics = getAnalytics(app);

        setUserProperties(analytics, {
            clientId,
            viashLanguage: I18nHelper.getPreferredLanguage(),
        });
    } catch (error) {
        console.error(error);
    }
};

export const track = (eventName: string, data) => {
    try {
        const analytics = getAnalytics();
        logEvent(analytics, eventName, data);
    } catch (error) {
        console.error(`Unable to track event ${eventName}`, error);
    }
};
