//@flow
import React from 'react';
import { Flex } from '@locus.sh/neo';
import TripMap from 'sections/tripMap/TripMap';
import TripPane from 'sections/tripPane/TripPane';
import {
    fetchFirstTrip,
    pollFetchTrip,
    InternetNotAvailableError,
} from 'trip/helpers';
import { useTripContext } from 'trip/useTripContext';
import { UIStatusEnum } from 'models/internal/TripReducerState';
import { ErrorScreen, NotFound, NoInternet } from 'components';
import { useTranslation } from 'react-i18next';
import { initializeFirebase } from 'helpers/FirebaseHelper';
import { useRescheduleContext } from 'sections/state/reschedule';
import Reschedule, { canReschedule } from 'sections/tripPane/Reschedule';
import { isMobile } from 'helpers/MediaHelper';
import NewRelicDataCollector from 'components/NewRelicDataCollector';
import type { TripInfo } from 'models/flow/TripInfo';
import ExpiredPage from 'components/expiredPage';
import { BannerProvider } from 'sections/state/banner';

function TripContainer(props) {
    const [state, dispatch] = useTripContext();
    return (
        <TripPollContainer {...props} dispatch={dispatch} tripState={state} />
    );
}
const REFRESH_SECONDS = 15; // poll every 15 seconds

const APIErrorScreen = ({ error }) => {
    const { t } = useTranslation();
    if (error instanceof InternetNotAvailableError) {
        return <NoInternet />;
    }
    if (error.statusCode === 404) {
        return <NotFound />;
    }
    return (
        <ErrorScreen
            errorCodeText={error.statusCode}
            title={error.message}
            subtitle={t('apologise404')}
        />
    );
};
class TripPollContainer extends React.PureComponent {
    componentDidMount() {
        const { tripId, dispatch } = this.props;

        fetchFirstTrip(tripId, true, {
            onSuccess: this.tripSuccess,
        })(dispatch);
    }

    componentDidUpdate(prevProps) {
        const {
            tripState: { tripStatus, tripInfo },
            rescheduleSuccess,
            tripId,
        } = this.props;
        const {
            tripState: { tripStatus: prevTripStatus },
            rescheduleSuccess: prevRescheduleSuccess,
        } = prevProps;
        if (
            prevTripStatus === UIStatusEnum.Loading &&
            tripStatus === UIStatusEnum.InitialSuccess
        ) {
            initializeFirebase({ clientId: tripInfo.visitId.clientId });
        }

        if (rescheduleSuccess && prevRescheduleSuccess !== rescheduleSuccess) {
            this.fetchFreshTripData(tripId);
        }
    }

    componentWillUnmount() {
        this.timer && clearTimeout(this.timer);
        this.interval && clearTimeout(this.interval);
    }

    tripSuccess = () => {
        const { tripId, dispatch } = this.props;
        this.startPolling(tripId)(dispatch);
    };

    startPolling =
        (tripId, duration = REFRESH_SECONDS * 1000) =>
        (dispatch) => {
            const startPollingWithInterval = () => {
                this.interval = setTimeout(() => {
                    this.startPolling(tripId)(dispatch);
                }, duration);
            };

            this.timer = setTimeout(() => {
                pollFetchTrip(tripId, {
                    onSuccess: (tripInfo) => {
                        // stop polling once the trip has reached terminal state
                        if (tripInfo.isTerminal()) {
                            clearTimeout(this.timer);
                            return;
                        }
                        startPollingWithInterval();
                        return;
                    },
                    onFailure: (error) => {
                        if (error instanceof InternetNotAvailableError) {
                            startPollingWithInterval();
                        }
                    },
                })(dispatch);
            }, duration);
        };

    fetchFreshTripData = (tripId) => {
        const {
            startedFetchingFreshTripData,
            stoppedFetchingFreshTripData,
            dispatch,
        } = this.props;

        startedFetchingFreshTripData();
        pollFetchTrip(tripId, {
            onSuccess: stoppedFetchingFreshTripData,
            onFailure: stoppedFetchingFreshTripData,
        })(dispatch);
    };

    render() {
        const { tripState, slots, rescheduleFlow } = this.props;

        if (tripState.tripStatus === UIStatusEnum.Loading) {
            return (
                <div className="loaderWrap">
                    <div className="loaderInner">
                        <div className="loaderCircle" />
                    </div>
                </div>
            );
        }
        if (tripState.tripStatus === UIStatusEnum.Error) {
            return <APIErrorScreen error={tripState.error} />;
        }

        const { tripInfo }: { tripInfo: TripInfo } = tripState;
        const { settings, expiredDisplayDate, status } = tripInfo;

        if (expiredDisplayDate) {
            return (
                <ExpiredPage status={status.status} time={expiredDisplayDate} />
            );
        }

        const {
            moduleSettings: {
                enableCustomerModule,
                enableDriverModule,
                enableHeaderModule,
                enableOrderModule,
                enableStatusModule,
            },
        } = settings;

        const canShowTripPane = !(isMobile() && rescheduleFlow) || slots;

        const shouldShowPane =
            (enableCustomerModule ||
                enableDriverModule ||
                enableHeaderModule ||
                enableOrderModule ||
                enableStatusModule) &&
            canShowTripPane;
        return (
            <BannerProvider>
                <Flex height="100%">
                    <NewRelicDataCollector />
                    {shouldShowPane && <TripPane />}
                    {canShowTripPane && (
                        <TripMap shouldShowPane={shouldShowPane} />
                    )}
                    {canReschedule(tripInfo) && rescheduleFlow && (
                        <Reschedule tripInfo={tripInfo} />
                    )}
                </Flex>
            </BannerProvider>
        );
    }
}

function TripContainerWrapper(props: any): React$Element<any> {
    const {
        rescheduleFlow,
        slots,
        startedFetchingFreshTripData,
        stoppedFetchingFreshTripData,
        rescheduleSuccess,
    } = useRescheduleContext();
    return (
        <TripContainer
            {...props}
            rescheduleFlow={rescheduleFlow}
            slots={slots}
            startedFetchingFreshTripData={startedFetchingFreshTripData}
            rescheduleSuccess={rescheduleSuccess}
            stoppedFetchingFreshTripData={stoppedFetchingFreshTripData}
        />
    );
}

export default TripContainerWrapper;
