import React from 'react';
import { useLoadScript } from '@react-google-maps/api';
import EnvConfig from 'config';

const LIBRARIES = ['geometry'];
const LoadGoogleMap = function ({ googleMapsApiKey, children }) {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey,
        libraries: LIBRARIES,
        // ...otherOptions
    });
    if (!isLoaded) {
        return '';
    }
    return children;
};

LoadGoogleMap.defaultProps = {
    googleMapsApiKey: EnvConfig.googleMapsApiKey,
};

const withGoogleMapLoader = (Component) =>
    function (props) {
        return (
            <LoadGoogleMap>
                <Component {...props} />
            </LoadGoogleMap>
        );
    };

export { LoadGoogleMap, withGoogleMapLoader };
export default LoadGoogleMap;
