// @flow
import React from 'react';
import { useTripContext } from 'trip/useTripContext';
import NewRelic from 'helpers/NewRelic';

function NewRelicDataCollector(): null {
    const [{ tripInfo }] = useTripContext();
    const {
        status: { status },
        visitId: { clientId, taskId },
    } = tripInfo;

    React.useEffect(() => {
        NewRelic.track('status', status);
    }, [status]);

    React.useEffect(() => {
        NewRelic.track('clientId', clientId);
    }, [clientId]);

    React.useEffect(() => {
        NewRelic.track('taskId', taskId);
    }, [taskId]);

    React.useEffect(() => {
        NewRelic.track('language', navigator?.language);
    }, []);

    return null;
}

export default NewRelicDataCollector;
