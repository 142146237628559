// @flow
import React from 'react';
import { useTripContext } from 'trip/useTripContext';
import { UIStatusEnum } from 'models/internal/TripReducerState';
import useTimer from 'hooks/useTimer';

type ReturnProps = {
    time: number | null,
};

const useRefreshTimer = (): ReturnProps => {
    const [{ tripStatus, tripInfo }] = useTripContext();
    const { time, reset, stop } = useTimer({ interval: 10 });
    const isTripInTerminalState = tripInfo.isTerminal();

    React.useEffect(() => {
        if (isTripInTerminalState) {
            return stop();
        }
        if (
            tripStatus === UIStatusEnum.InitialSuccess ||
            tripStatus === UIStatusEnum.Idle
        ) {
            reset();
        }
    }, [tripStatus, reset, isTripInTerminalState, stop]);

    if (isTripInTerminalState) {
        return { time: null };
    }

    return { time };
};

export default useRefreshTimer;
