//@flow
import * as React from 'react';
import { SmoothMarker } from 'components';
import { useTripContext } from 'trip/useTripContext';
import { useHeading } from './hooks';
import { useDirectionsContext } from 'sections/state/directions';

const useNextPointLatLng = () => {
    const { directions } = useDirectionsContext();
    if (!directions) {
        return {};
    }

    const decodedPath = google.maps.geometry.encoding.decodePath(directions);
    return {
        lat: decodedPath[1]?.lat?.(),
        lng: decodedPath[1]?.lng?.(),
    };
};

const UserMarker = () => {
    const [state] = useTripContext();
    const { tripInfo } = state;
    const { userLatLng, userIcon } = tripInfo.getMapData();

    const nextPointLatLng = useNextPointLatLng();
    const userHeading = useHeading(userLatLng, nextPointLatLng);

    if (!userLatLng) {
        return null;
    }

    return (
        <SmoothMarker
            heading={userHeading}
            imagePositioning="CENTERED"
            position={userLatLng}
            iconUrl={userIcon}
        />
    );
};
export { UserMarker };

export default UserMarker;
