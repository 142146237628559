//@flow
import * as Immutable from 'immutable';

import { TripStatus } from 'models/flow/TripStatus.js';
import type { TripStatusModelType } from 'models/flow/TripStatus.js';
export const TripStatusEnum = {
    NotStarted: 'NOT_STARTED', //depracted
    Assigned: 'ASSIGNED',
    Accepted: 'ACCEPTED',
    Received: 'RECEIVED',
    Started: 'STARTED',
    Cancelled: 'CANCELLED',
    Completed: 'COMPLETED',
    Error: 'ERROR',
};

class InternalTripStatus extends TripStatus {
    static fromJS(json: TripStatusModelType): InternalTripStatus {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }
}

export { InternalTripStatus as TripStatus };
