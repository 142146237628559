// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel'; // Make sure you copied this file into your repo

export type VisitIdModelType = {
    clientId: string,
    taskId: string,
    visitId: string,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class VisitId extends ImmutableModel {
    static fromJS(json: VisitIdModelType): VisitId {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }

    toJS(): VisitIdModelType {
        return {
            clientId: this.clientId,
            taskId: this.taskId,
            visitId: this.visitId,
        };
    }

    get clientId(): string {
        return this._state.get('clientId');
    }

    setClientId(clientId: string): this {
        return this.clone(this._state.set('clientId', clientId));
    }

    get taskId(): string {
        return this._state.get('taskId');
    }

    setTaskId(taskId: string): this {
        return this.clone(this._state.set('taskId', taskId));
    }

    get visitId(): string {
        return this._state.get('visitId');
    }

    setVisitId(visitId: string): this {
        return this.clone(this._state.set('visitId', visitId));
    }
}
