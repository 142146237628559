//@flow
import * as Immutable from 'immutable';

import { VisitId } from 'models/flow/VisitId.js';
import type { VisitIdModelType } from 'models/flow/VisitId.js';

class InternalVisitId extends VisitId {
    static fromJS(json: VisitIdModelType): InternalVisitId {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }
}

export { InternalVisitId as VisitId };
