// @flow
import React from 'react';
import { Box, ProgressBar } from '@locus.sh/neo';
import type { TripInfoProp } from 'components/status/TripInfoProp.type';
import testIds from 'tests/trip-pane/testIds.json';

const styles = {
    paddingLeft: `0 !important`,
    paddingRight: `0 !important`,
};

function TripProgressBar({
    tripInfo,
}: TripInfoProp): null | React$Element<HTMLElement> {
    const {
        settings: { showEta },
    } = tripInfo;
    const isETAValid = tripInfo.isETAValid();
    const shouldShowEta = isETAValid && showEta && tripInfo.isStarted();

    if (!shouldShowEta) {
        return null;
    }

    return (
        <Box mt={1}>
            <ProgressBar
                value={50}
                hideValue
                data-test-id={testIds.progressBar}
                sx={styles}
            />
        </Box>
    );
}

export default TripProgressBar;
