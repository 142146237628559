//@flow

class NewRelic {
    static notifyError(error: Error) {
        window.newrelic?.noticeError?.(error);
    }

    static track(key: string, data: string) {
        window.newrelic?.setCustomAttribute?.(key, data);
    }

    static addPageAction(key: string, data?: Object) {
        window.newrelic?.addPageAction?.(key, data);
    }
}

export default NewRelic;
