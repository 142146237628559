//@flow
import { useState, useEffect, useCallback } from 'react';
import { useInterval } from 'react-use';

type Props = {
    autoStart?: boolean,
    interval?: number, // in seconds
};

type Return = {
    time: number, // in seconds
    reset: () => void,
    start: () => void,
    stop: () => void,
};

const TIME_SKIPPER = 1; // in seconds

const useTimer = ({
    autoStart = true,
    interval = TIME_SKIPPER,
}: Props): Return => {
    const [time, setTime] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const incrementTime = (time) => time + interval;

    const start = useCallback(() => {
        setIsRunning(true);
    }, []);
    const stop = useCallback(() => {
        setIsRunning(false);
    }, []);

    const resetInterval = useCallback(() => {
        // in order to reset the interval, we need to start it again
        stop();
        setTimeout(start, 0);
    }, [stop, start]);

    const resetTime = useCallback(() => setTime(0), [setTime]);

    const reset = useCallback(() => {
        resetTime();
        resetInterval();
    }, [resetTime, resetInterval]);

    useEffect(() => {
        if (autoStart) {
            start();
        }
    }, [autoStart, start]);

    useInterval(
        () => {
            setTime(incrementTime);
        },
        isRunning ? interval * 1000 : null
    );

    return { time, reset, start, stop };
};

export default useTimer;
