// @flow
import React, { useState, useEffect } from 'react';
import {
    Heading,
    Button,
    Flex,
    Box,
    Divider,
    Dialog,
    DialogContent,
    Text,
    useToast,
} from '@locus.sh/neo';
import { isMobile } from 'helpers/MediaHelper';
import { BottomSheet } from 'components';
import useFetch from 'hooks/useFetch';
import { getAPIURL } from 'trip/helpers';
import { useTripContext } from 'trip/useTripContext';
import { useRescheduleContext } from 'sections/state/reschedule';
import { apiToastDefaultOptions } from 'helpers/ToastHelper';
import { useTranslation } from 'react-i18next';
import TimeUtil from 'helpers/TimeUtil';
import DateList from 'sections/reschedule/DateList';
import SlotList, { formatSlot } from 'sections/reschedule/SlotList';
import Tracker from 'helpers/Tracker';

type Props = {
    tripId: string,
};

type PayloadType = {
    otp: string,
    slot?: {
        start: string,
        end: string,
    },
    rescheduleDate?: string,
};

const getPayload = ({
    isSameDateSelected,
    selectedDate,
    selectedSlot,
    otp,
}) => {
    const payload: PayloadType = { otp };

    if (isSameDateSelected && selectedSlot) {
        payload.slot = {
            start: selectedSlot.start,
            end: selectedSlot.end,
        };
    } else {
        payload.rescheduleDate = TimeUtil.format({
            date: selectedDate,
            format: 'YYYY-MM-DD',
        });
    }

    return payload;
};

function ChooseSlot({ tripId }: Props): React$Element<any> {
    const { t } = useTranslation();
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [selectedDate, setSelectedDate] = useState();

    const [{ tripInfo }] = useTripContext();
    const {
        settings: {
            customerRescheduleSettings: {
                allowFutureDatesReschedule,
                rescheduleNumberOfDays,
            },
        },
    } = tripInfo;

    const { otp, stopRescheduleFlow, slots, setRescheduleSuccess } =
        useRescheduleContext();
    const { showToast, closeAllToasts } = useToast();

    const isSameDateSelected = TimeUtil.isSameDay(tripInfo.eta, selectedDate);

    const payload = getPayload({
        isSameDateSelected,
        selectedDate,
        selectedSlot,
        otp,
    });
    const url = isSameDateSelected
        ? getAPIURL(`trip/${tripId}/slot`)
        : getAPIURL(`trip/${tripId}/reschedule`);

    const method = isSameDateSelected ? 'PUT' : 'POST';

    const { response, isLoading, triggerRequest, errorMessage } = useFetch({
        url,
        method,
        body: payload,
    });

    useEffect(() => {
        if (errorMessage) {
            Tracker.track('rescheduleFailed', {
                tripId,
                error: errorMessage,
            });
            showToast({
                content: errorMessage,
                palette: 'danger',
                ...apiToastDefaultOptions,
            });
        }
        () => {
            closeAllToasts();
        };
    }, [closeAllToasts, errorMessage, showToast, tripId]);

    useEffect(() => {
        if (response) {
            Tracker.track('rescheduleSuccess', {
                tripId,
            });
            showToast({
                title: t('rescheduleSuccessTitle'),
                content:
                    isSameDateSelected && selectedSlot
                        ? t('rescheduleSuccessMessage', {
                              date: TimeUtil.format({ format: 'DD MMMM' }),
                              startTime: formatSlot(selectedSlot.start),
                              endTime: formatSlot(selectedSlot.end),
                          })
                        : t('futureRescheduleSuccessMessage', {
                              date: TimeUtil.format({
                                  date: selectedDate,
                                  format: 'DD MMMM',
                              }),
                          }),
                palette: 'success',
                ...apiToastDefaultOptions,
            });
            setRescheduleSuccess(true);
            stopRescheduleFlow(true);
        }
    }, [
        isSameDateSelected,
        response,
        selectedDate,
        selectedSlot,
        setRescheduleSuccess,
        showToast,
        stopRescheduleFlow,
        t,
        tripId,
    ]);

    let canBeSubmitted;
    if (allowFutureDatesReschedule) {
        if (isSameDateSelected) {
            canBeSubmitted = Boolean(selectedSlot && selectedDate);
        } else {
            canBeSubmitted = Boolean(selectedDate);
        }
    } else {
        canBeSubmitted = Boolean(selectedSlot);
    }
    return (
        <>
            <Box m={4}>
                <Flex alignItems="center" marginBottom={2}>
                    <Flex flexGrow={1}>
                        <Heading>{t('slotChoiceHeading')}</Heading>
                    </Flex>
                    <Button
                        appearance="icon"
                        icon="cross"
                        size="large"
                        onClick={stopRescheduleFlow}
                    />
                </Flex>
                <Box mx={-4} pb={4}>
                    <Divider color="grey.200" />
                </Box>
                {allowFutureDatesReschedule ? (
                    <DateList
                        startDate={TimeUtil.format({
                            date: tripInfo.eta || new Date(),
                            format: 'YYYY-MM-DD',
                        })}
                        numberOfDays={
                            rescheduleNumberOfDays
                                ? rescheduleNumberOfDays + 1 // as same date is also included
                                : 7
                        }
                        onDateSelect={setSelectedDate}
                        selectedDate={selectedDate}
                    />
                ) : (
                    <Text
                        color="grey.600"
                        fontSize={2}
                        fontWeight={600}
                        marginBottom={4}
                    >
                        {TimeUtil.format({ format: 'DD MMMM, ddd' })}
                    </Text>
                )}
                {(isSameDateSelected || !allowFutureDatesReschedule) && (
                    <SlotList
                        slots={slots}
                        onSlotSelect={setSelectedSlot}
                        selectedSlot={selectedSlot}
                    />
                )}
            </Box>
            <Button
                size="large"
                width="100%"
                appearance="solid"
                palette="primary"
                disabled={!canBeSubmitted}
                onClick={triggerRequest}
                isLoading={isLoading}
            >
                {t('submit')}
            </Button>
        </>
    );
}

function ChooseSlotWrapper(props: Props): React$Element<any> {
    const { stopRescheduleFlow } = useRescheduleContext();

    if (isMobile()) {
        return (
            <BottomSheet isOpen>
                <ChooseSlot {...props} />
            </BottomSheet>
        );
    }
    return (
        <Dialog isOpen onDismiss={stopRescheduleFlow}>
            <DialogContent sx={{ padding: 0 }}>
                <ChooseSlot {...props} />
            </DialogContent>
        </Dialog>
    );
}

export default ChooseSlotWrapper;
