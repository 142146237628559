//@flow
import * as Immutable from 'immutable';

import { Amount } from 'models/flow/Amount.js';
import type { AmountModelType } from 'models/flow/Amount.js';

class InternalAmount extends Amount {
    static fromJS(json: AmountModelType): InternalAmount {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }
}

export { InternalAmount as Amount };
