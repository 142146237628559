// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel'; // Make sure you copied this file into your repo

import { TripInfo } from './TripInfo';
import { Direction } from './Direction';

import type { TripInfoModelType } from './TripInfo';
import type { DirectionModelType } from './Direction';

type UIStatusEnumType =
    | 'LOADING'
    | 'POLLING'
    | 'ERROR'
    | 'POLL_ERROR'
    | 'IDLE'
    | 'INITIAL_SUCCESS';

export type TripReducerStateModelType = {
    error: Object,
    tripStatus: UIStatusEnumType,
    tripInfo?: TripInfoModelType,
    directions: DirectionModelType,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class TripReducerState extends ImmutableModel {
    static fromJS(json: TripReducerStateModelType): TripReducerState {
        const state: Object = Object.assign({}, json);
        state.tripInfo = state.tripInfo
            ? TripInfo.fromJS(state.tripInfo)
            : state.tripInfo;
        state.directions = Direction.fromJS(state.directions);
        return new this(Immutable.Map(state));
    }

    toJS(): TripReducerStateModelType {
        const js: TripReducerStateModelType = {
            error: this.error,
            tripStatus: this.tripStatus,
            directions: this.directions.toJS(),
        };

        if (this.tripInfo != null) {
            js.tripInfo = this.tripInfo ? this.tripInfo.toJS() : this.tripInfo;
        }

        return js;
    }

    get error(): Object {
        return this._state.get('error');
    }

    setError(error: Object): this {
        return this.clone(this._state.set('error', error));
    }

    get tripStatus(): UIStatusEnumType {
        return this._state.get('tripStatus');
    }

    setTripStatus(tripStatus: UIStatusEnumType): this {
        return this.clone(this._state.set('tripStatus', tripStatus));
    }

    get tripInfo(): ?TripInfo {
        return this._state.get('tripInfo');
    }

    setTripInfo(tripInfo: ?TripInfo): this {
        return this.clone(this._state.set('tripInfo', tripInfo));
    }

    get directions(): Direction {
        return this._state.get('directions');
    }

    setDirections(directions: Direction): this {
        return this.clone(this._state.set('directions', directions));
    }
}
