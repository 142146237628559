//@flow
import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogHeader,
    Divider,
    Flex,
    Heading,
    Icon,
    Position,
    Subheading,
    Text,
} from '@locus.sh/neo';
import { BottomSheet, TextArea } from 'components';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'helpers/MediaHelper';
import {
    addNotesClient,
    ApiError,
    InternetNotAvailableError,
} from 'trip/helpers';

import { useTripContext } from 'trip/useTripContext';
import { useOrderNoteContext } from 'sections/state/orderNote';
import testIds from 'tests/trip-pane/testIds.json';
import Tracker from 'helpers/Tracker';

type NoteCardPropType = {
    note: string,
    isEditNoteAllowed?: boolean,
    onEdit?: () => void,
};

const NoteCard = ({
    note,
    isEditNoteAllowed,
    onEdit,
}: NoteCardPropType): React$Element<any> => (
    <Flex
        pt={3}
        pb={2}
        alignItems="flex-start"
        justifyContent="space-between"
        flex="0 1"
        flexShrink={0}
    >
        <Text
            fontSize={2}
            color="grey.600"
            minWidth="0"
            whiteSpace="pre-line"
            lineHeight={1}
            sx={{
                overflowWrap: 'break-word',
            }}
            data-test-id={testIds.deliveryInstruction}
        >
            {note.trim()}
        </Text>
        {isEditNoteAllowed && (
            <Icon
                color="blue.500"
                icon="edit"
                size="large"
                onClick={onEdit}
                ml={2}
                sx={{
                    cursor: 'pointer',
                }}
                data-test-id={testIds.editNoteButton}
            />
        )}
    </Flex>
);

type Props = {
    styles?: Object,
};

const OrderNote = ({
    styles: stylesFromParent = {},
}: Props): React$Element<any> => {
    const { t } = useTranslation();
    const [{ tripInfo }] = useTripContext();
    const {
        settings: { customerNotesSettings },
    } = tripInfo;
    const { tripId, customerNotes } = tripInfo;
    const { maxNotesLimit, notesDisplayText } = customerNotesSettings;

    const [tempNote, setTempNote] = useState('');
    const [savedNotes, setSavedNotes] = useState(customerNotes);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const isTerminal = tripInfo.isTerminal();
    const areNotesEmpty = savedNotes.size === 0;
    const isAddNoteAllowed = !isTerminal && savedNotes.size === 0;
    const isEditNoteAllowed = !isTerminal && savedNotes.size < maxNotesLimit;
    const latestNote = savedNotes.last() || '';

    const {
        showAddNoteButton,
        hideAddNoteButton,
        isNoteEditing,
        closeNoteEditor,
        openNoteEditor,
    } = useOrderNoteContext();

    useEffect(() => {
        if (isAddNoteAllowed || isEditNoteAllowed) {
            showAddNoteButton();
        } else {
            hideAddNoteButton();
        }
        return () => hideAddNoteButton();
    }, [
        isAddNoteAllowed,
        showAddNoteButton,
        hideAddNoteButton,
        isEditNoteAllowed,
    ]);

    const handleNoteChange = (value) => {
        setTempNote(value);
        if (error) {
            setError(null);
        }
    };

    const clearNote = () => {
        setTempNote('');
    };

    const onAddNotesSuccess = () => {
        Tracker.track('noteEdited', {
            tripId,
        });
        setSavedNotes(savedNotes.push(tempNote));
        setTempNote('');
        closeNoteEditor();
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            await addNotesClient.fetch(tripId, tempNote);
            onAddNotesSuccess();
        } catch (e) {
            // Success, but empty response
            if (
                !(
                    e instanceof ApiError ||
                    e instanceof InternetNotAvailableError
                )
            ) {
                onAddNotesSuccess();
            } else {
                // Api error
                setError(t('notesError'));
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (isNoteEditing) {
            setTempNote(latestNote);
        }
    }, [isNoteEditing, latestNote, setTempNote]);

    const getContent = (isMobileDevice: boolean) => {
        const numberOfEditRemaining = maxNotesLimit - savedNotes.size;
        return (
            <Box data-test-id={testIds.deliveryInstructionModal}>
                <DialogHeader showDismissAction={false}>
                    <Heading
                        data-test-id={testIds.deliveryInstructionModalHeading}
                    >
                        {notesDisplayText || t('deliveryInstruction')}
                    </Heading>
                    <Subheading
                        fontSize={1}
                        data-test-id={
                            testIds.deliveryInstructionModalSubHeading
                        }
                    >
                        {t('deliveryInstructionSubHeader', {
                            count: Math.max(numberOfEditRemaining, 0),
                        })}
                    </Subheading>
                    <Position position="absolute" right={5}>
                        <Button
                            appearance="icon"
                            icon="cross"
                            size="large"
                            onClick={closeNoteEditor}
                        />
                    </Position>
                </DialogHeader>
                <Divider color="grey.200" />
                <DialogContent>
                    <form onSubmit={handleFormSubmit} noValidate>
                        <Box mr={4}>
                            <TextArea
                                label={t('addNote')}
                                value={tempNote}
                                onChange={handleNoteChange}
                                onClear={clearNote}
                                charLimit={250}
                                placeholder={t('addNotePlaceholder')}
                                error={error}
                                rows={isMobileDevice ? 1 : 5}
                                backgroundColor="white"
                                leftIcon={
                                    <Icon
                                        icon="orchestrator_id"
                                        color="grey.600"
                                    />
                                }
                                maxRows={4}
                                autoFocus
                                autoGrow
                                dataTestId={testIds.deliveryInstructionInput}
                            />
                        </Box>
                        <Flex my={3} justifyContent="flex-end">
                            <Button
                                appearance="ghost"
                                role="button"
                                color="grey.600"
                                onClick={closeNoteEditor}
                                data-test-id={testIds.cancelNoteButton}
                            >
                                {t('cancel')}
                            </Button>
                            <Button
                                appearance="ghost"
                                color="blue.500"
                                disabled={!tempNote || error}
                                isLoading={isLoading}
                                data-test-id={testIds.submitNoteButton}
                                sx={{
                                    '&:disabled': {
                                        color: 'blue.300',
                                    },
                                }}
                            >
                                {t('notifyRider')}
                            </Button>
                        </Flex>
                    </form>
                </DialogContent>
            </Box>
        );
    };

    const isMobileDevice = isMobile();

    return (
        <>
            {!areNotesEmpty && (
                <Box
                    backgroundColor="white"
                    sx={stylesFromParent}
                    flexDirection="column"
                    px={4}
                    py={3}
                >
                    <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                        pb={savedNotes.size ? 2 : 0}
                    >
                        <Text
                            color="grey.900"
                            fontWeight={600}
                            fontSize={2}
                            data-test-id={testIds.deliveryInstructionHeader}
                        >
                            {notesDisplayText || t('deliveryInstructionHeader')}
                        </Text>
                    </Flex>
                    {latestNote && (
                        <>
                            <Box mx={-4}>
                                <Divider color="grey.200" />
                            </Box>
                            <NoteCard
                                note={latestNote}
                                isEditNoteAllowed={isEditNoteAllowed}
                                onEdit={openNoteEditor}
                            />
                        </>
                    )}
                </Box>
            )}
            {isMobileDevice ? (
                <BottomSheet isOpen={isNoteEditing} onDismiss={closeNoteEditor}>
                    {isNoteEditing && getContent(isMobileDevice)}
                </BottomSheet>
            ) : (
                <Dialog isOpen={isNoteEditing} onDismiss={closeNoteEditor}>
                    {isNoteEditing && getContent(isMobileDevice)}
                </Dialog>
            )}
        </>
    );
};

export default OrderNote;
