//@flow
import * as React from 'react';
import { Flex, Text } from '@locus.sh/neo';
import { TimeWithUnit } from './Time';
import TimeUtil from 'helpers/TimeUtil';
import { useTranslation } from 'react-i18next';

const CSS_STYLE = {
    textTransform: 'uppercase',
    fontWeight: 600,
    p: {
        marginLeft: 1,
        '&:first-of-type': {
            marginLeft: 0,
        },
    },
};

type Props = {
    time: string,
};

export const RemainingTime = ({ time, ...others }: Props) => {
    const { t } = useTranslation();
    const timeInstance = TimeUtil.dayjs(time);
    const now = TimeUtil.dayjs();
    const timeDiff = TimeUtil.preciseDiff(timeInstance, now, true);
    const { days, hours, minutes, firstDateWasLater } = timeDiff;
    if (!firstDateWasLater) {
        return null;
    }

    return (
        <Flex alignItems="baseline" {...others} sx={CSS_STYLE} color="blue.600">
            <Text fontSize={2}>{t('etaHeading')} :</Text>
            {days > 0 ? (
                <>
                    <TimeWithUnit time={days} unit="D" />
                    {hours > 0 && <TimeWithUnit time={hours} unit="HR" />}
                </>
            ) : (
                <>
                    {hours > 0 && <TimeWithUnit time={hours} unit="HR" />}
                    {minutes >= 0 && <TimeWithUnit time={minutes} unit="MIN" />}
                </>
            )}
        </Flex>
    );
};

export default RemainingTime;
