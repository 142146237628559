//@flow
import React from 'react';
import { useOrderNoteContext } from 'sections/state/orderNote';
import testIds from 'tests/trip-pane/testIds.json';
import { Button } from '@locus.sh/neo';

type Props = {
    sx?: Object,
};
function OrderNoteButton({ sx }: Props): React$Element<any> | null {
    const { canShowAddNoteButton, openNoteEditor } = useOrderNoteContext();

    if (!canShowAddNoteButton) {
        return null;
    }

    return (
        <Button
            sx={sx}
            icon="internal_write"
            appearance="outline"
            palette="primary"
            onClick={openNoteEditor}
            data-test-id={testIds.addNoteButton}
            size="large"
        >
            Note
        </Button>
    );
}

export default OrderNoteButton;
