// @flow
import React, { createContext, useState, useContext, useCallback } from 'react';

type Props = {
    children: React$Element<any>,
};

const BannerContext = createContext();

const BannerProvider = ({ children }: Props): React$Element<any> => {
    const [bannerHeight, setBannerHeight] = useState(0);

    const updateBannerHeight = useCallback((height) => {
        setBannerHeight(height);
    }, []);

    return (
        <BannerContext.Provider
            value={{
                bannerHeight,
                updateBannerHeight,
            }}
        >
            {children}
        </BannerContext.Provider>
    );
};

const useBannerContext = (): any => {
    const context = useContext(BannerContext);
    return context;
};

export { useBannerContext, BannerProvider };
