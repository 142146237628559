// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel'; // Make sure you copied this file into your repo

export type DirectionModelType = {
    polyline: string,
    ddTuple: {
        distance: number,
        duration: number,
    },
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class Direction extends ImmutableModel {
    static fromJS(json: DirectionModelType): Direction {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }

    toJS(): DirectionModelType {
        return {
            polyline: this.polyline,
            ddTuple: this.ddTuple,
        };
    }

    get polyline(): string {
        return this._state.get('polyline');
    }

    setPolyline(polyline: string): this {
        return this.clone(this._state.set('polyline', polyline));
    }

    get ddTuple(): {
        distance: number,
        duration: number,
    } {
        return this._state.get('ddTuple');
    }

    setDdTuple(ddTuple: { distance: number, duration: number }): this {
        return this.clone(this._state.set('ddTuple', ddTuple));
    }
}
