// @flow
import { useState, useCallback } from 'react';
import { fetchClient, ApiError, InternetNotAvailableError } from 'trip/helpers';
import { useDeepCompareEffect } from 'react-use';
import { useTranslation } from 'react-i18next';

type Props = {
    url: string,
    method?: 'POST' | 'GET' | 'PUT',
    body?: any,
    autoRun?: boolean,
};

export const STATUS_CODE = {
    '500': 500,
    '401': 401,
    '429': 429,
};

type ErrorObject = {
    statusCode: $Values<typeof STATUS_CODE>,
};

const useFetch = <ResponseValue>({
    url,
    method = 'GET',
    body,
    autoRun = false,
}: Props): ({
    errorMessage: string | null,
    isLoading: boolean,
    response: ResponseValue | null,
    errorObject: ErrorObject | null,
    triggerRequest: () => void,
}) => {
    const [response, setResponse] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [errorObject, setErrorObject] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [request, setRequest] = useState(autoRun);
    const { t } = useTranslation();

    const setDefaults = () => {
        setRequest(false);
        setResponse(null);
        setErrorMessage(null);
        setErrorObject(null);
    };

    useDeepCompareEffect(() => {
        if (!request && !autoRun) {
            return;
        }
        setDefaults(); //empty all information in start of request
        setIsLoading(true);

        fetchClient
            .fetch({ url, method, body })
            .then((response: ResponseValue) => setResponse(response))
            .catch((error) => {
                if (
                    error instanceof ApiError ||
                    error instanceof InternetNotAvailableError
                ) {
                    setErrorMessage(
                        error?.message ? error.message : t('genericError')
                    );
                    setErrorObject({
                        statusCode: error?.statusCode,
                    });
                } else {
                    setResponse(true);
                }
            })
            .finally(() => setIsLoading(false));
    }, [body, method, request, url]);

    const triggerRequest = useCallback(() => {
        setRequest(true);
    }, []);

    return { isLoading, errorMessage, response, triggerRequest, errorObject };
};

export default useFetch;
