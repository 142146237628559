// @flow
import React from 'react';
import { useTripContext } from 'trip/useTripContext';
import { StaticGoogleMap, Marker, Direction } from 'react-static-google-map';
import EnvConfig from 'config';
import NewRelic from 'helpers/NewRelic';
import { useDirectionsContext } from 'sections/state/directions';

type Props = {
    size: { width: number, height: number },
};

const ICON_TYPE_URL_MAP = {
    primary: `${window.location.origin}/img/markers-static/OrderDrop40.png`,
    success: `${window.location.origin}/img/markers-static/OrderDrop40.png`,
    danger: `${window.location.origin}/img/markers-static/danger40.png`,
    secondary: `${window.location.origin}/img/markers-static/OrderPickup40.png`,
    warning: `${window.location.origin}/img/markers-static/warning40.png`,
    warningMultiple: `${window.location.origin}/img/markers-static/warningMultiple40.png`,
};

// NOTE: react-static-google-map needs child components to be the one's it's exposing directly,
// and any wrapper on top of child component will create issues with library
const TripStaticMap = ({ size }: Props) => {
    const [{ tripInfo }] = useTripContext();
    const {
        settings: { showEnrouteOrders },
        enrouteOrders,
    } = tripInfo;
    const isInitial = tripInfo.isInitial();
    const isTerminal = tripInfo.isTerminal();
    const { userIcon, destinationLatLng, sourceLatLng, userLatLng } =
        tripInfo.getMapData();
    const { directions } = useDirectionsContext();

    if (!size.width || !size.height) {
        return null;
    }

    const trackEvent = (url: string) => {
        NewRelic.addPageAction('static-map-request', { mapURL: url });
    };

    return (
        <StaticGoogleMap
            size={`${Math.round(size.width)}x${Math.round(size.height)}`}
            apiKey={EnvConfig.googleMapsApiKey}
            cache={!directions}
            onGenerate={trackEvent}
            // we don't want cache in case of polylines,
            // as this library is currently not updating path cache based on custom polylines strategy
        >
            {isInitial && sourceLatLng && (
                <Marker
                    location={`${sourceLatLng.lat},${sourceLatLng.lng}`}
                    iconURL={ICON_TYPE_URL_MAP.secondary}
                />
            )}
            <Marker
                location={`${destinationLatLng.lat},${destinationLatLng.lng}`}
                iconURL={ICON_TYPE_URL_MAP[tripInfo.getMarkerIconType()]}
            />
            {!isInitial && !isTerminal && userLatLng && (
                <Marker
                    location={`${userLatLng.lat},${userLatLng.lng}`}
                    iconURL={userIcon}
                />
            )}
            {!isInitial &&
                !isTerminal &&
                showEnrouteOrders &&
                enrouteOrders &&
                enrouteOrders.size > 0 &&
                enrouteOrders.map((order, index) => (
                    <Marker
                        key={order.latLng.getKey(index)}
                        location={order.latLng.toJS()}
                        iconURL={
                            ICON_TYPE_URL_MAP[
                                enrouteOrders.size === 1
                                    ? 'warning'
                                    : 'warningMultiple'
                            ]
                        }
                    />
                ))}
            {!isInitial &&
                !isTerminal &&
                userLatLng &&
                destinationLatLng &&
                directions && (
                    <Direction
                        color="0x0072F5FF"
                        weight={5}
                        origin={`${userLatLng.lat},${userLatLng.lng}`}
                        destination={`${destinationLatLng.lat},${destinationLatLng.lng}`}
                        requestStrategy={() => Promise.resolve(directions)}
                    />
                )}
        </StaticGoogleMap>
    );
};

export default TripStaticMap;
