// @flow
import React from 'react';
import { Box, Flex, mediaQueries } from '@locus.sh/neo';
import { RescheduleButton, canReschedule } from '../Reschedule';
import RiderCallButton, { canShowRiderCallButton } from '../RiderCallButton';
import RiderDetails, {
    canShowRiderDetails,
} from 'sections/tripPane/OrderStatusAndActions/RiderDetails';
import Status, {
    canShowStatus,
    StatusChip,
    getETATime,
} from 'sections/tripPane/OrderStatusAndActions/Status';
import OrderNoteButton from 'sections/tripPane/OrderStatusAndActions/OrderNoteButton';
import { useTripContext } from 'trip/useTripContext';
import { useOrderNoteContext } from 'sections/state/orderNote';
import { notoSansDisplay } from 'styles/font.js';
import Pin from 'components/status/Pin';
import testIds from 'tests/trip-pane/testIds.json';
import { useTranslation } from 'react-i18next';
type Props = {
    styles?: Object,
    isSticky?: boolean,
};

function OrderStatusAndActions({
    styles: stylesFromParent = {},
    isSticky,
}: Props): React$Element<any> | null {
    const [{ tripInfo }] = useTripContext();
    const { t } = useTranslation();
    const actionButtonStyles = {
        marginRight: 2,
        flex: isSticky ? 0 : 1,
        [mediaQueries.md]: {
            flex: 'initial',
        },
        minWidth: 'fit-content',
        // hiding the text when this is sticky
        ...(isSticky && {
            '& i': {
                mr: 0,
                ml: 0,
            },
            '& i + p': {
                display: 'none',
            },
        }),
    };

    const style = {
        ['> :last-child']: {
            marginBottom: '0',
        },
        ...stylesFromParent,
        ...notoSansDisplay,
        ...(isSticky && { mb: 0 }),
    };

    const actionButtonWrapper = {
        ['> button:last-child']: {
            marginRight: 0,
        },
    };

    const { canShowAddNoteButton } = useOrderNoteContext();

    const canShowActionButtons =
        canShowRiderCallButton(tripInfo) ||
        canShowAddNoteButton ||
        canReschedule(tripInfo);

    const canShowOrderStatusAndActions =
        canShowStatus(tripInfo) ||
        canShowRiderDetails(tripInfo) ||
        canShowActionButtons;

    if (!canShowOrderStatusAndActions) {
        return null;
    }

    const etaMessage: string | null = t('tripDisplayText', {
        time: getETATime(tripInfo),
    });

    const shouldShowEta = tripInfo.isETAValid() && tripInfo.settings.showEta;

    /**
     * !etaMessage - Condition for <StatusChip /> to render
     * canShowRiderDetails(tripInfo) - Condition for <RiderDetails /> to render
     * (tripInfo.isStarted() && tripInfo.pin && shouldShowEta) - Conditions for <Pin /> to render
     *
     * If any of the components is not rendered, the margin from the action buttons will be removed
     */
    const isRiderDetailsStatusAndPinRendered =
        !etaMessage ||
        canShowRiderDetails(tripInfo) ||
        (tripInfo.isStarted() && tripInfo.pin && shouldShowEta);

    return (
        <Box
            sx={style}
            backgroundColor="white"
            p={3}
            data-test-id={testIds.statusAndRiderModule}
        >
            {etaMessage && <Status />}
            <Flex alignItems="center">
                <Flex flex={1} mr={2}>
                    <RiderDetails />
                </Flex>
                <Flex flexDirection="column">
                    {!etaMessage && <StatusChip />}
                    <Pin tripInfo={tripInfo} />
                </Flex>
            </Flex>
            {canShowActionButtons && (
                <Flex
                    sx={actionButtonWrapper}
                    mt={isRiderDetailsStatusAndPinRendered ? 4 : 0}
                    overflowX="auto"
                >
                    <RiderCallButton sx={actionButtonStyles} />
                    <OrderNoteButton sx={actionButtonStyles} />
                    <RescheduleButton sx={actionButtonStyles} />
                </Flex>
            )}
        </Box>
    );
}

export default OrderStatusAndActions;
