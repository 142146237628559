// @flow
import { TRIP_HEADER_HEIGHT } from 'sections/header/TripHeader';
import { URL_CARD_HEIGHT } from 'sections/header/URLCard';
import { TOP_BANNER_HEIGHT } from 'components/Banner';

const paymentInfoHeight = 44;

type Props = {
    isHeaderShown: boolean,
    shouldShowPane: boolean,
    isUrlChecklistShown: boolean,
    canShowPaymentInfo: boolean,
    isBannerShownOnTop: boolean,
    topBannerHeight?: number,
};

type ReturnProps = {
    height: string,
    top: string,
};

const useBackgroundMapPosition = ({
    isHeaderShown = true, // true if header module is shown, else false
    shouldShowPane = true, // true if left pane is shown (if any of the module is enabled), else false,
    isUrlChecklistShown,
    canShowPaymentInfo,
    isBannerShownOnTop,
    topBannerHeight = TOP_BANNER_HEIGHT,
}: Props): ReturnProps => {
    let topOffset = 180;

    if (isHeaderShown) topOffset += TRIP_HEADER_HEIGHT;
    if (isUrlChecklistShown) topOffset += URL_CARD_HEIGHT;
    if (isBannerShownOnTop) topOffset += topBannerHeight;

    return {
        height: shouldShowPane
            ? `calc(100% - ${
                  topOffset + (canShowPaymentInfo ? paymentInfoHeight : 0) + 10
              }px)`
            : '100%',
        top: `${topOffset - 180}px`,
    };
};

export default useBackgroundMapPosition;
