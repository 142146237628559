// @flow
import React from 'react';
import TimeUtil from 'helpers/TimeUtil';
import { useTripContext } from 'trip/useTripContext';
import { Box, Text, Flex, Heading } from '@locus.sh/neo';
import type { TripInfo } from 'models/internal/TripInfo';
import { useTranslation } from 'react-i18next';
import { TripStatusEnum } from 'models/internal/TripStatus';
import TripProgressBar from 'components/status/TripProgressBar';
import { notoSansDisplay } from 'styles/font.js';
import { canShowPaymentInfo } from 'components/PaymentInfo';
import testIds from 'tests/trip-pane/testIds.json';

export const getETATime = (tripInfo: TripInfo) => {
    const isStarted = tripInfo.isStarted();

    if (isStarted) {
        const relativeTime = TimeUtil.toNow(tripInfo.eta);
        const absoluteTime = TimeUtil.format({
            date: tripInfo.eta,
            format: 'h:mm a',
        });
        return `${relativeTime} (${absoluteTime})`;
    }

    return TimeUtil.format({
        date: tripInfo.status.triggeredOn,
        format: 'h:mm a, D MMM',
    });
};

export function canShowStatus(tripInfo: TripInfo): boolean {
    const {
        settings: {
            moduleSettings: { enableStatusModule },
        },
    } = tripInfo;

    return enableStatusModule;
}

export default function Status(): React$Element<any> | null {
    const [{ tripInfo }] = useTripContext();
    const { t } = useTranslation();

    if (!canShowStatus(tripInfo)) {
        return null;
    }

    const {
        settings: { showEta },
    } = tripInfo;

    const etaMessage: string | null = t('tripDisplayText', {
        time: showEta ? getETATime(tripInfo) : '',
    });

    return (
        <Box mb={2} data-test-id={testIds.statusModule}>
            <Flex
                alignItems="flex-start"
                justifyContent="space-between"
                mt={canShowPaymentInfo(tripInfo) ? 2 : 0}
            >
                <Heading
                    size="medium"
                    color="grey.600"
                    fontFamily={notoSansDisplay.fontFamily}
                    data-test-id={testIds.etaMessage}
                >
                    {etaMessage}
                </Heading>
                <StatusChip />
            </Flex>
            <TripProgressBar tripInfo={tripInfo} />
        </Box>
    );
}

export function StatusChip() {
    const [{ tripInfo }] = useTripContext();
    if (!canShowStatus(tripInfo)) {
        return null;
    }

    const getStatusChipBackground = () => {
        switch (tripInfo.status.status) {
            case TripStatusEnum.Assigned:
                return 'grey.100';
            case TripStatusEnum.Received:
                return 'grey.100';
            case TripStatusEnum.Accepted:
                return 'yellow.100';
            case TripStatusEnum.Started:
                return 'blue.100';
            case TripStatusEnum.Completed:
                return 'green.100';
            case TripStatusEnum.Cancelled:
                return 'red.100';
            default:
                return '';
        }
    };

    return (
        <Box
            py="4px"
            px="6px"
            backgroundColor={getStatusChipBackground()}
            sx={{ whiteSpace: 'nowrap' }}
        >
            <Text
                fontSize={1}
                color="grey.900"
                fontWeight={600}
                data-test-id={testIds.statusText}
            >
                {tripInfo.status.displayText}
            </Text>
        </Box>
    );
}
