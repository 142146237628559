//@flow
import React, { type Node } from 'react';
import { Box, Button, Flex, Text, defaultTheme } from '@locus.sh/neo';
import TimeUtil from 'helpers/TimeUtil';
import { useTranslation } from 'react-i18next';

type Props = {
    onDateSelect: (d: string) => void,
    startDate: string,
    numberOfDays: number,
    selectedDate: ?string,
};

const getDateList = (startDate, numberOfDays) => {
    const dates = [];
    let prevDate = startDate;
    for (let i = 0; i < numberOfDays; i++) {
        const nextDate = TimeUtil.getNextDate(prevDate);
        dates.push(TimeUtil.format({ date: prevDate, format: 'YYYY-MM-DD' }));
        prevDate = nextDate;
    }
    return dates;
};

const DateList = ({
    onDateSelect,
    startDate,
    numberOfDays,
    selectedDate,
}: Props): Node => {
    const dates = getDateList(startDate, numberOfDays);
    const { t } = useTranslation();

    const handleDateSelect = (date: string) => () => {
        onDateSelect(date);
    };

    return (
        <Box mb={4}>
            <Text fontSize={1} fontWeight={600} mb={4}>
                {t('selectDate')}
            </Text>
            <Flex alignItems="center" overflow="auto" width="100%">
                {dates.map((date, idx) => {
                    const nameOfday = TimeUtil.format({
                        date,
                        format: 'ddd',
                    });
                    const dayOfMonth = TimeUtil.format({
                        date,
                        format: 'DD',
                    });
                    const isSelected = date === selectedDate;
                    return (
                        <Button
                            key={idx}
                            appearance="outline"
                            mr={1}
                            width="4rem"
                            height="4rem"
                            onClick={handleDateSelect(date)}
                            backgroundColor={isSelected ? 'blue.100' : 'white'}
                            sx={{
                                borderColor: isSelected
                                    ? `${defaultTheme.colors.blue['600']} !important`
                                    : defaultTheme.colors.grey['600'],
                                flexShrink: 0,
                            }}
                        >
                            <Text
                                fontSize={1}
                                fontWeight="normal"
                                color={isSelected ? 'blue.600' : 'grey.600'}
                            >
                                {nameOfday}
                            </Text>
                            <Text
                                fontSize={4}
                                color={isSelected ? 'blue.600' : 'grey.600'}
                            >
                                {dayOfMonth}
                            </Text>
                        </Button>
                    );
                })}
            </Flex>
        </Box>
    );
};

export default DateList;
