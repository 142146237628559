// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel'; // Make sure you copied this file into your repo
import type { KeyValue } from './Common';
import type { STATUS } from './Status';
import type { FORMAT } from './CustomerChecklist';

export type ChecklistItemModelType = {
    id: string,
    name: string,
    format: FORMAT,
    selectedValue: string,
};

export type ChecklistItemSubmittedModelType = {
    id: string,
    name: string,
    type: FORMAT,
    selectedValue: string,
};

export type CustomerChecklistModelType = {
    status: STATUS,
    items: Array<ChecklistItemModelType>,
};

export type CustomerChecklistSubmittedModelType = {
    status: STATUS,
    items: Array<ChecklistItemSubmittedModelType>,
};

export type CustomerChecklistDataModelType = {
    completed: CustomerChecklistSubmittedModelType,
    cancelled: CustomerChecklistSubmittedModelType,

    // FIXME: find a way to use dynamic key of status instead of adding them manually as done above
    // [STATUS]: CustomerChecklistModelType,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class ChecklistItem extends ImmutableModel {
    static fromJS(json: ChecklistItemModelType): ChecklistItem {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }

    toJS(): ChecklistItemModelType {
        return {
            id: this.id,
            name: this.name,
            format: this.format,
            selectedValue: this.selectedValue,
        };
    }

    get id(): string {
        return this._state.get('id');
    }

    setId(id: string): this {
        return this.clone(this._state.set('id', id));
    }

    get name(): string {
        return this._state.get('name');
    }

    setName(name: string): this {
        return this.clone(this._state.set('name', name));
    }

    get format(): FORMAT {
        return this._state.get('format');
    }

    setFormat(format: FORMAT): this {
        return this.clone(this._state.set('format', format));
    }

    get selectedValue(): string {
        return this._state.get('selectedValue');
    }

    setSelectedValue(selectedValue: string): this {
        return this.clone(this._state.set('selectedValue', selectedValue));
    }
}

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class CustomerChecklist extends ImmutableModel {
    static fromJS(json: CustomerChecklistModelType): CustomerChecklist {
        const state: Object = Object.assign({}, json);
        state.items = Immutable.List(state.items).map((item) =>
            ChecklistItem.fromJS(item)
        );
        return new this(Immutable.Map(state));
    }

    toJS(): CustomerChecklistModelType {
        return {
            status: this.status,
            items: this.items.toArray().map((item) => item.toJS()),
        };
    }

    get status(): STATUS {
        return this._state.get('status');
    }

    setStatus(status: STATUS): this {
        return this.clone(this._state.set('status', status));
    }

    get items(): Immutable.List<ChecklistItem> {
        return this._state.get('items');
    }

    setItems(items: Immutable.List<ChecklistItem>): this {
        return this.clone(this._state.set('items', items));
    }
}

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class CustomerChecklistData extends ImmutableModel {
    static fromJS(json: CustomerChecklistDataModelType): CustomerChecklistData {
        const state: Object = Object.assign({}, json);
        state.completed = CustomerChecklistSubmitted.fromJS(state.completed);
        state.cancelled = CustomerChecklistSubmitted.fromJS(state.cancelled);
        return new this(Immutable.Map(state));
    }

    toJS(): CustomerChecklistDataModelType {
        return {
            completed: this.completed.toJS(),
            cancelled: this.cancelled.toJS(),
        };
    }

    get completed(): CustomerChecklistSubmitted {
        return this._state.get('completed');
    }

    setCompleted(completed: CustomerChecklistSubmitted): this {
        return this.clone(this._state.set('completed', completed));
    }

    get cancelled(): CustomerChecklistSubmitted {
        return this._state.get('cancelled');
    }

    setCancelled(cancelled: CustomerChecklistSubmitted): this {
        return this.clone(this._state.set('cancelled', cancelled));
    }
}

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class ChecklistItemSubmitted extends ImmutableModel {
    static fromJS(
        json: ChecklistItemSubmittedModelType
    ): ChecklistItemSubmitted {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }

    toJS(): ChecklistItemSubmittedModelType {
        return {
            id: this.id,
            name: this.name,
            type: this.type,
            selectedValue: this.selectedValue,
        };
    }

    get id(): string {
        return this._state.get('id');
    }

    setId(id: string): this {
        return this.clone(this._state.set('id', id));
    }

    get name(): string {
        return this._state.get('name');
    }

    setName(name: string): this {
        return this.clone(this._state.set('name', name));
    }

    get type(): FORMAT {
        return this._state.get('type');
    }

    setType(type: FORMAT): this {
        return this.clone(this._state.set('type', type));
    }

    get selectedValue(): string {
        return this._state.get('selectedValue');
    }

    setSelectedValue(selectedValue: string): this {
        return this.clone(this._state.set('selectedValue', selectedValue));
    }
}

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class CustomerChecklistSubmitted extends ImmutableModel {
    static fromJS(
        json: CustomerChecklistSubmittedModelType
    ): CustomerChecklistSubmitted {
        const state: Object = Object.assign({}, json);
        state.items = Immutable.List(state.items).map((item) =>
            ChecklistItemSubmitted.fromJS(item)
        );
        return new this(Immutable.Map(state));
    }

    toJS(): CustomerChecklistSubmittedModelType {
        return {
            status: this.status,
            items: this.items.toArray().map((item) => item.toJS()),
        };
    }

    get status(): STATUS {
        return this._state.get('status');
    }

    setStatus(status: STATUS): this {
        return this.clone(this._state.set('status', status));
    }

    get items(): Immutable.List<ChecklistItemSubmitted> {
        return this._state.get('items');
    }

    setItems(items: Immutable.List<ChecklistItemSubmitted>): this {
        return this.clone(this._state.set('items', items));
    }
}
