// @flow
import React from 'react';
import { Flex, Text, mediaQueries } from '@locus.sh/neo';
import type { TripInfoProp } from 'components/status/TripInfoProp.type';
import { canShowAmount } from 'components/Amount';
import type { ExchangeTypeEnumType } from 'models/flow/AmountTransaction';
import { useTranslation } from 'react-i18next';
import type { TripInfo } from 'models/internal/TripInfo';
import testIds from 'tests/trip-pane/testIds.json';

const getPaymentMessageTextAndColor = (
    exchangeType: ExchangeTypeEnumType,
    translator: any
) => {
    if (exchangeType === 'COLLECT') {
        return {
            text: translator('paymentCollectMessage'),
            backgroundColor: 'blue.100',
            color: 'blue.600',
        };
    }
    if (exchangeType === 'GIVE') {
        return {
            text: translator('paymentGiveMessage'),
            backgroundColor: 'yellow.100',
            color: 'yellow.600',
        };
    }

    return {
        text: translator('paymentComplete'),
        backgroundColor: 'green.100',
        color: 'green.600',
    };
};

const styles = {
    position: 'absolute',
    top: '-40px',
    zIndex: -1,
    left: 0,
    right: 0,
    [mediaQueries.md]: {
        top: '12px',
        zIndex: 1,
    },
};

export const canShowPaymentInfo = (tripInfo: TripInfo): boolean => {
    return canShowAmount(tripInfo) && tripInfo.isStarted();
};

function PaymentInfo({ tripInfo }: TripInfoProp): null | React$Element<any> {
    const { t } = useTranslation();
    if (!canShowPaymentInfo(tripInfo)) {
        return null;
    }

    const { amount } = tripInfo;
    const { exchangeType } = amount;
    const { symbol, value } = amount.getData();
    const { backgroundColor, color, text } = getPaymentMessageTextAndColor(
        exchangeType,
        t
    );

    const isPaymentNotCompleted =
        exchangeType === 'COLLECT' || exchangeType === 'GIVE';

    return (
        <Flex
            sx={styles}
            backgroundColor={backgroundColor}
            px={4}
            py={3}
            alignItems="center"
            data-test-id={testIds.paymentInfo}
        >
            <Text
                fontSize={2}
                sx={{ display: 'flex' }}
                fontWeight={isPaymentNotCompleted ? 400 : 700}
                color={color}
            >
                {text}
                {isPaymentNotCompleted && (
                    <Text ml={1} fontWeight={700}>
                        {symbol} {value}
                    </Text>
                )}
            </Text>
        </Flex>
    );
}

export default PaymentInfo;
