import * as React from 'react';
import { Flex, Text, Subheading } from '@locus.sh/neo';
import { useTranslation } from 'react-i18next';
import testIds from 'tests/trip-header/testIds.json';

const OrderIdCard = (props) => {
    const { orderId } = props;
    const { t } = useTranslation();
    return (
        <Flex
            px={3}
            py={3}
            height="100%"
            minWidth="0px"
            ml="auto"
            alignItems="center"
        >
            <Flex flexDirection="column" minWidth="0px">
                <Subheading
                    ml="auto"
                    textAlign="right"
                    color="grey.800"
                    fontSize={1}
                    sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        lineHeight: 1,
                    }}
                    width="100%"
                >
                    {t('orderId')}
                </Subheading>
                <Text
                    ml="auto"
                    truncated
                    lineHeight={2}
                    fontSize={3}
                    width="100%"
                    data-test-id={testIds.orderId}
                >
                    {orderId}
                </Text>
            </Flex>
        </Flex>
    );
};

export { OrderIdCard };

export default OrderIdCard;
