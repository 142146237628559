//@flow
import * as React from 'react';
import { Position, mediaQueries } from '@locus.sh/neo';
import { useTranslation } from 'react-i18next';
import { useTripContext } from 'trip/useTripContext';
import { toSentenceCaseFromMixedCase } from 'helpers/StringUtil';

const getCSS = (theme) => ({
    boxShadow: theme.shadows.raised,
    display: 'none',
    [mediaQueries.md]: {
        display: 'flex',
    },
});

const EnrouteOrderInfo = () => {
    const [state] = useTripContext();
    const { tripInfo } = state;
    const { enrouteOrders, settings } = tripInfo;
    const { showEnrouteOrders } = settings;
    const { t } = useTranslation();
    if (!showEnrouteOrders || !enrouteOrders.size) {
        return null;
    }
    return (
        <Position
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width={'140px'}
            minHeight={'60px'}
            p={3}
            right={3}
            top={3}
            zIndex={14}
            fontSize={3}
            position="absolute"
            backgroundColor="white"
            sx={getCSS}
        >
            {toSentenceCaseFromMixedCase(t('deliveringNearby'))}
        </Position>
    );
};
export { EnrouteOrderInfo };
export default EnrouteOrderInfo;
