// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel'; // Make sure you copied this file into your repo
import type { RescheduleInfoModelType } from './RescheduleInfo';
import { RescheduleInfo } from './RescheduleInfo';
import type { CustomerChecklistModelType } from './CustomerChecklist';
import { CustomerChecklist } from './CustomerChecklist';

type ModuleSettingsType = {
    /**
     * Boolean flag to control whether to show customer module or not
     */
    enableCustomerModule: boolean,
    /**
     * Boolean flag to control whether to show driver module or not
     */
    enableDriverModule: boolean,
    /**
     * Boolean flag to control whether to show header module or not
     */
    enableHeaderModule: boolean,
    /**
     * Boolean flag to control whether to show order details module or not
     */
    enableOrderModule: boolean,
    /**
     * Boolean flag to control whether to show status module or not
     */
    enableStatusModule: boolean,
    /**
     * String that repesents rider temperatue unit
     */
    temperatureUnit: string,
};

export type CustomerNotesSettingsType = {
    maxNotesLimit: number,
    notesDisplayText: string,
    showCustomerNotes: boolean,
};

export type LiveViewSettingsModelType = {
    /**
     * Url for client logo
     */
    logo: string,
    /**
     * Url for client logo to be displayed with light background
     */
    lightBackgroundLogo?: string,
    /**
     * Url for image to use as favicon
     */
    favicon: string,
    /**
     * Display name of the client
     */
    displayName: string,
    /**
     * Title to show at top of page
     */
    title: string,
    /**
     * Should display name be shown or not?
     */
    shouldDisplayName: boolean,
    /**
     * Url for website of the client.
     * This will be linked from the logo on live view
     */
    websiteUrl?: string,
    /**
     * Call Support Display text on the live view map
     */
    callSupportDisplayText?: string,
    /**
     * Call Support number of the client
     */
    callSupportNumber?: string,
    /**
     * Boolean flag to control whether to show eta or not
     */
    showEta?: boolean,
    /**
     * Boolean flag to control whether to show amount or not
     */
    showAmount?: boolean,
    /**
     * Boolean flag to control whether to show en-route orders or not
     */
    showEnrouteOrders?: boolean,
    /**
     * Boolean flag to control whether to show watermark or not
     */
    showPoweredBy: boolean,
    /**
     * Object that contains all the module flags
     */
    moduleSettings: ModuleSettingsType,
    customerChecklists: Array<CustomerChecklistModelType>,
    /**
     * Object that contains Customer Notes module config
     */
    customerNotesSettings: CustomerNotesSettingsType,
    customerRescheduleSettings: RescheduleInfoModelType,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class LiveViewSettings extends ImmutableModel {
    static fromJS(json: LiveViewSettingsModelType): LiveViewSettings {
        const state: Object = Object.assign({}, json);
        state.customerChecklists = Immutable.List(state.customerChecklists).map(
            (item) => CustomerChecklist.fromJS(item)
        );
        state.customerRescheduleSettings = RescheduleInfo.fromJS(
            state.customerRescheduleSettings
        );
        return new this(Immutable.Map(state));
    }

    toJS(): LiveViewSettingsModelType {
        const js: LiveViewSettingsModelType = {
            logo: this.logo,
            favicon: this.favicon,
            displayName: this.displayName,
            title: this.title,
            shouldDisplayName: this.shouldDisplayName,
            showPoweredBy: this.showPoweredBy,
            moduleSettings: this.moduleSettings,
            customerChecklists: this.customerChecklists
                .toArray()
                .map((item) => item.toJS()),
            customerNotesSettings: this.customerNotesSettings,
            customerRescheduleSettings: this.customerRescheduleSettings.toJS(),
        };

        if (this.lightBackgroundLogo != null) {
            js.lightBackgroundLogo = this.lightBackgroundLogo;
        }

        if (this.websiteUrl != null) {
            js.websiteUrl = this.websiteUrl;
        }

        if (this.callSupportDisplayText != null) {
            js.callSupportDisplayText = this.callSupportDisplayText;
        }

        if (this.callSupportNumber != null) {
            js.callSupportNumber = this.callSupportNumber;
        }

        if (this.showEta != null) {
            js.showEta = this.showEta;
        }

        if (this.showAmount != null) {
            js.showAmount = this.showAmount;
        }

        if (this.showEnrouteOrders != null) {
            js.showEnrouteOrders = this.showEnrouteOrders;
        }

        return js;
    }

    get logo(): string {
        return this._state.get('logo');
    }

    setLogo(logo: string): this {
        return this.clone(this._state.set('logo', logo));
    }

    get lightBackgroundLogo(): ?string {
        return this._state.get('lightBackgroundLogo');
    }

    setLightBackgroundLogo(lightBackgroundLogo: ?string): this {
        return this.clone(
            this._state.set('lightBackgroundLogo', lightBackgroundLogo)
        );
    }

    get favicon(): string {
        return this._state.get('favicon');
    }

    setFavicon(favicon: string): this {
        return this.clone(this._state.set('favicon', favicon));
    }

    get displayName(): string {
        return this._state.get('displayName');
    }

    setDisplayName(displayName: string): this {
        return this.clone(this._state.set('displayName', displayName));
    }

    get title(): string {
        return this._state.get('title');
    }

    setTitle(title: string): this {
        return this.clone(this._state.set('title', title));
    }

    get shouldDisplayName(): boolean {
        return this._state.get('shouldDisplayName');
    }

    setShouldDisplayName(shouldDisplayName: boolean): this {
        return this.clone(
            this._state.set('shouldDisplayName', shouldDisplayName)
        );
    }

    get websiteUrl(): ?string {
        return this._state.get('websiteUrl');
    }

    setWebsiteUrl(websiteUrl: ?string): this {
        return this.clone(this._state.set('websiteUrl', websiteUrl));
    }

    get callSupportDisplayText(): ?string {
        return this._state.get('callSupportDisplayText');
    }

    setCallSupportDisplayText(callSupportDisplayText: ?string): this {
        return this.clone(
            this._state.set('callSupportDisplayText', callSupportDisplayText)
        );
    }

    get callSupportNumber(): ?string {
        return this._state.get('callSupportNumber');
    }

    setCallSupportNumber(callSupportNumber: ?string): this {
        return this.clone(
            this._state.set('callSupportNumber', callSupportNumber)
        );
    }

    get showEta(): ?boolean {
        return this._state.get('showEta');
    }

    setShowEta(showEta: ?boolean): this {
        return this.clone(this._state.set('showEta', showEta));
    }

    get showAmount(): ?boolean {
        return this._state.get('showAmount');
    }

    setShowAmount(showAmount: ?boolean): this {
        return this.clone(this._state.set('showAmount', showAmount));
    }

    get showEnrouteOrders(): ?boolean {
        return this._state.get('showEnrouteOrders');
    }

    setShowEnrouteOrders(showEnrouteOrders: ?boolean): this {
        return this.clone(
            this._state.set('showEnrouteOrders', showEnrouteOrders)
        );
    }

    get showPoweredBy(): boolean {
        return this._state.get('showPoweredBy');
    }

    setShowPoweredBy(showPoweredBy: boolean): this {
        return this.clone(this._state.set('showPoweredBy', showPoweredBy));
    }

    get moduleSettings(): ModuleSettingsType {
        return this._state.get('moduleSettings');
    }

    setModuleSettings(moduleSettings: ModuleSettingsType): this {
        return this.clone(this._state.set('moduleSettings', moduleSettings));
    }

    get customerChecklists(): Immutable.List<CustomerChecklist> {
        return this._state.get('customerChecklists');
    }

    setCustomerChecklists(
        customerChecklists: Immutable.List<CustomerChecklist>
    ): this {
        return this.clone(
            this._state.set('customerChecklists', customerChecklists)
        );
    }

    get customerNotesSettings(): CustomerNotesSettingsType {
        return this._state.get('customerNotesSettings');
    }

    setCustomerNotesSettings(
        customerNotesSettings: CustomerNotesSettingsType
    ): this {
        return this.clone(
            this._state.set('customerNotesSettings', customerNotesSettings)
        );
    }

    get customerRescheduleSettings(): RescheduleInfo {
        return this._state.get('customerRescheduleSettings');
    }

    setCustomerRescheduleSettings(
        customerRescheduleSettings: RescheduleInfo
    ): this {
        return this.clone(
            this._state.set(
                'customerRescheduleSettings',
                customerRescheduleSettings
            )
        );
    }
}
