// @flow
import React, { useState, useEffect, useCallback } from 'react';
import {
    Text,
    Button,
    Box,
    Flex,
    Dialog,
    DialogContent,
    Loader,
    useToast,
} from '@locus.sh/neo';
import OtpInput from 'components/otp/OTPInput';
import ResendOTP from 'components/otp/ResendOTP';
import { isMobile } from 'helpers/MediaHelper';
import useFetch, { STATUS_CODE } from 'hooks/useFetch';
import { getAPIURL } from 'trip/helpers';
import { useTripContext } from 'trip/useTripContext';
import { useRescheduleContext } from 'sections/state/reschedule';
import type { SlotsSuccessResponse } from './types';
import { apiToastDefaultOptions } from 'helpers/ToastHelper';
import { useTranslation } from 'react-i18next';

type Props = {
    tripId: string,
};

const OTP_LENGTH = 4;

function Otp({ tripId }: Props): React$Element<any> {
    const { t } = useTranslation();
    const { otp, setOtp, setSlots } = useRescheduleContext();
    const { showToast, closeAllToasts } = useToast();
    const [lastSendDate, setLastSendDate] = useState(null);
    const {
        response: otpSentSuccessfully,
        isLoading: isSendingOTP,
        triggerRequest,
        errorMessage: otpNotSentSuccessfullyMessage,
        errorObject,
    } = useFetch<string>({
        url: getAPIURL(`trip/${tripId}/generate-otp`),
        method: 'POST',
    });

    const {
        response: slotsResponse,
        isLoading: isSlotsLoading,
        triggerRequest: slotsRequestTrigger,
        errorMessage: slotsRequestErrorMessage,
        errorObject: slotsRequestErrorObject,
    } = useFetch<SlotsSuccessResponse>({
        url: getAPIURL(`trip/${tripId}/slot`),
        method: 'POST',
        body: {
            otp,
        },
    });

    useEffect(() => {
        triggerRequest();
    }, [triggerRequest]);

    useEffect(() => {
        if (otpSentSuccessfully) {
            setLastSendDate(new Date());
        }
    }, [otpSentSuccessfully]);

    const onOTPChange = (newOtp) => setOtp(newOtp);
    const canSubmitOTP = otp.length === OTP_LENGTH;

    const getOTPInitialRequestErrorMessage = useCallback(() => {
        if (errorObject && errorObject.statusCode === STATUS_CODE[500]) {
            return t('unableToSendOTP');
        }

        return otpNotSentSuccessfullyMessage;
    }, [errorObject, otpNotSentSuccessfullyMessage, t]);

    const showSlotsPopupError = (errorObject) => {
        return (
            errorObject &&
            errorObject.statusCode &&
            errorObject.statusCode === STATUS_CODE[429]
        );
    };

    useEffect(() => {
        if (otpNotSentSuccessfullyMessage && showSlotsPopupError(errorObject)) {
            closeAllToasts();
            showToast({
                content: otpNotSentSuccessfullyMessage,
                palette: 'danger',
                ...apiToastDefaultOptions,
            });
        }
        return () => {
            closeAllToasts();
        };
    }, [
        closeAllToasts,
        showToast,
        slotsRequestErrorMessage,
        getOTPInitialRequestErrorMessage,
        slotsRequestErrorObject,
        errorObject,
        otpNotSentSuccessfullyMessage,
    ]);

    useEffect(() => {
        if (slotsResponse) {
            setSlots([...slotsResponse.slots]);
        }
    }, [slotsResponse, setSlots]);

    const canSendCode = (): boolean => {
        return errorObject?.statusCode !== STATUS_CODE[401];
    };

    const isMobileView = isMobile();

    return (
        <Box
            m={6}
            style={{
                textAlign: isMobileView ? 'initial' : 'center',
            }}
        >
            <Text fontWeight={600} marginBottom={2}>
                {t('verificationCodeLabel')}
            </Text>
            {isSendingOTP && (
                <Box mt={2}>
                    <Loader color="blue.500" />
                </Box>
            )}
            {otpSentSuccessfully && (
                <Text
                    fontWeight={400}
                    fontSize={1}
                    color="grey.600"
                    marginBottom={1}
                >
                    {t('otpSentSuccessMessage')}
                </Text>
            )}
            {(otpNotSentSuccessfullyMessage || slotsRequestErrorMessage) &&
                !showSlotsPopupError(errorObject) && (
                    <Text color="red.500" fontSize={1} fontWeight={400}>
                        {getOTPInitialRequestErrorMessage() ||
                            slotsRequestErrorMessage}
                    </Text>
                )}
            <Flex
                marginBottom="1.25rem"
                justifyContent={isMobileView ? 'flex-start' : 'center'}
            >
                <OtpInput
                    value={otp}
                    onChange={onOTPChange}
                    length={OTP_LENGTH}
                />
            </Flex>
            <Flex justifyContent={isMobileView ? 'flex-start' : 'center'}>
                <ResendOTP
                    lastSend={lastSendDate}
                    onResendClick={triggerRequest}
                    canSendCode={canSendCode()}
                />
            </Flex>
            <Button
                palette="primary"
                appearance="solid"
                size="large"
                disabled={!canSubmitOTP}
                width="100%"
                marginTop="1.25rem"
                marginBottom="0.75rem"
                onClick={slotsRequestTrigger}
                isLoading={isSlotsLoading}
            >
                {t('verify')}
            </Button>
            <Text fontSize={1} color="grey.600">
                {t('notReceivedOTPMessage')}
            </Text>
        </Box>
    );
}

function OTPWrapper(props: Props): React$Element<any> {
    const [state] = useTripContext();
    const {
        tripInfo: { tripId },
    } = state;

    const extraProps = {
        tripId,
    };

    const { stopRescheduleFlow } = useRescheduleContext();

    if (isMobile()) {
        return <Otp {...props} {...extraProps} />;
    }

    return (
        <Dialog isOpen onDismiss={stopRescheduleFlow}>
            <DialogContent>
                <Otp {...props} {...extraProps} />
            </DialogContent>
        </Dialog>
    );
}

export default OTPWrapper;
