//@flow
import React, { useCallback, useEffect, useRef } from 'react';
import { Marker } from 'components';
import { useTripContext } from 'trip/useTripContext';

const DestinationMarker = () => {
    const [state] = useTripContext();
    const { tripInfo } = state;
    const iconType = tripInfo.getMarkerIconType();
    const { destinationLatLng } = tripInfo.getMapData();

    const markerInstance = useRef(null);

    const onMarkerLoad = useCallback((marker) => {
        markerInstance.current = marker;
    }, []);

    useEffect(() => {
        if (destinationLatLng && markerInstance.current) {
            markerInstance.current.setPosition(destinationLatLng);
        }
    }, [destinationLatLng, markerInstance]);

    if (!destinationLatLng) {
        return null;
    }
    return (
        <Marker
            clickable
            position={destinationLatLng}
            imagePositioning="BOTTOM_CENTERED"
            iconType={iconType}
            onMarkerLoad={onMarkerLoad}
        />
    );
};
export { DestinationMarker };

export default DestinationMarker;
