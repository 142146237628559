// @flow
import React, { useEffect, useState, useCallback } from 'react';
import { Button, Text } from '@locus.sh/neo';
import { useTranslation } from 'react-i18next';
import { TIME_SECONDS } from '../Time';

type Props = {
    lastSend: ?Date,
    onResendClick: () => any,
    canSendCode: boolean,
};

const TIMER_SECONDS = 60;

function ResendOTP({
    lastSend,
    onResendClick,
    canSendCode,
}: Props): React$Element<any> {
    const [currentTimer, setCurrentTimer] = useState(0);
    const { t } = useTranslation();

    const updateTimerFunc = useCallback(
        (lastSendDate) => {
            const updateTimer = () => {
                const difference =
                    lastSendDate.getTime() - new Date().getTime();
                const differenceInSeconds = difference / TIME_SECONDS;
                const shouldTimerStillRun =
                    differenceInSeconds < 0 &&
                    differenceInSeconds > -TIMER_SECONDS;

                if (shouldTimerStillRun) {
                    setCurrentTimer(
                        TIMER_SECONDS - formatTimer(differenceInSeconds)
                    );
                } else {
                    setCurrentTimer(0);
                    window.clearInterval(interval);
                }
            };
            updateTimer();
            const interval = window.setInterval(() => {
                updateTimer();
            }, 1000);

            return interval;
        },
        [setCurrentTimer]
    );

    const formatTimer = (timer: number) => {
        const truncatedNumber = Math.trunc(timer);
        const positiveNumber = Math.abs(truncatedNumber);
        return positiveNumber;
    };

    const formatTimerDisplay = (timer: number) => {
        return timer.toString().padStart(2, '0');
    };

    useEffect(() => {
        if (lastSend) {
            const interval = updateTimerFunc(lastSend);

            return () => {
                window.clearInterval(interval);
            };
        }
    }, [lastSend, updateTimerFunc]);

    if (currentTimer > 0) {
        return (
            <Text fontSize={1}>
                {t('resendCodeIn', {
                    time: `00:${formatTimerDisplay(currentTimer)}`,
                })}
            </Text>
        );
    }

    return (
        <Button
            palette="primary"
            marginLeft={-3}
            onClick={onResendClick}
            disabled={!canSendCode}
        >
            {t('resendCode')}
        </Button>
    );
}

export default ResendOTP;
