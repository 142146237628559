//@flow
import * as Immutable from 'immutable';

import { LiveViewSettings } from 'models/flow/LiveViewSettings.js';
import type { LiveViewSettingsModelType } from 'models/flow/LiveViewSettings.js';
import { CustomerChecklist } from './CustomerChecklist';

import { RescheduleInfo } from './RescheduleInfo';

class InternalLiveViewSettings extends LiveViewSettings {
    static fromJS(json: LiveViewSettingsModelType): InternalLiveViewSettings {
        const state: Object = Object.assign({}, json);
        state.customerChecklists = Immutable.List(state.customerChecklists).map(
            (item) => CustomerChecklist.fromJS(item)
        );
        state.customerRescheduleSettings = RescheduleInfo.fromJS(
            state.customerRescheduleSettings
        );
        return new this(Immutable.Map(state));
    }

    get customerChecklists(): Immutable.List<CustomerChecklist> {
        return this._state.get('customerChecklists');
    }

    get customerRescheduleSettings(): RescheduleInfo {
        return this._state.get('customerRescheduleSettings');
    }
}

export { InternalLiveViewSettings as LiveViewSettings };
