import * as React from 'react';
import ErrorScreen from './ErrorScreen';
import { useTranslation } from 'react-i18next';
import { Text } from '@locus.sh/neo';
const NoInternet = () => {
    const { t } = useTranslation();
    return (
        <ErrorScreen title={t('internetNotAvailable')}>
            <Text mb={2}>{t('pleaseTryAgain')}</Text>
        </ErrorScreen>
    );
};
export { NoInternet };
export default NoInternet;
