//@flow
import * as Immutable from 'immutable';

import {
    ChecklistItem,
    CustomerChecklist,
    CustomerChecklistData,
    ChecklistItemSubmitted,
    CustomerChecklistSubmitted,
} from 'models/flow/CustomerChecklistFilled.js';

import type {
    ChecklistItemModelType,
    CustomerChecklistModelType,
    CustomerChecklistDataModelType,
    ChecklistItemSubmittedModelType,
    CustomerChecklistSubmittedModelType,
} from 'models/flow/CustomerChecklistFilled.js';

class InternalChecklistItem extends ChecklistItem {
    static fromJS(json: ChecklistItemModelType): InternalChecklistItem {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }
}

class InternalCustomerChecklist extends CustomerChecklist {
    static fromJS(json: CustomerChecklistModelType): InternalCustomerChecklist {
        const state: Object = Object.assign({}, json);
        state.items = Immutable.List(state.items).map((item) =>
            InternalChecklistItem.fromJS(item)
        );
        return new this(Immutable.Map(state));
    }

    get items(): Immutable.List<ChecklistItem> {
        return this._state.get('items');
    }
}

class InternalCustomerChecklistData extends CustomerChecklistData {
    static fromJS(
        json: CustomerChecklistDataModelType
    ): InternalCustomerChecklistData {
        const state: Object = Object.assign({}, json);
        state.completed = InternalCustomerChecklistSubmitted.fromJS(
            state.completed
        );
        state.cancelled = InternalCustomerChecklistSubmitted.fromJS(
            state.cancelled
        );
        return new this(Immutable.Map(state));
    }

    get completed(): InternalCustomerChecklistSubmitted {
        return this._state.get('completed');
    }

    get cancelled(): InternalCustomerChecklistSubmitted {
        return this._state.get('cancelled');
    }
}

class InternalChecklistItemSubmitted extends ChecklistItemSubmitted {
    static fromJS(
        json: ChecklistItemSubmittedModelType
    ): InternalChecklistItemSubmitted {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }
}

class InternalCustomerChecklistSubmitted extends CustomerChecklistSubmitted {
    static fromJS(
        json: CustomerChecklistSubmittedModelType
    ): InternalCustomerChecklistSubmitted {
        const state: Object = Object.assign({}, json);
        state.items = Immutable.List(state.items).map((item) =>
            InternalChecklistItemSubmitted.fromJS(item)
        );
        return new this(Immutable.Map(state));
    }

    get items(): Immutable.List<ChecklistItemSubmitted> {
        return this._state.get('items');
    }
}

export {
    InternalChecklistItem as ChecklistItem,
    InternalCustomerChecklist as CustomerChecklist,
    InternalCustomerChecklistData as CustomerChecklistData,
    InternalChecklistItemSubmitted as ChecklistItemSubmitted,
    InternalCustomerChecklistSubmitted as CustomerChecklistSubmitted,
};
