//@flow
import * as Immutable from 'immutable';

import { TripInfo } from 'models/flow/TripInfo.js';
import type { TripInfoModelType } from 'models/flow/TripInfo.js';

import { VisitId } from './VisitId';
import { OrderDetail } from './OrderDetail';
import { AddressLatLng } from './AddressLatLng';
import { AmountTransaction } from './AmountTransaction';
import { TripStatus, TripStatusEnum } from './TripStatus';
import { LiveViewSettings } from './LiveViewSettings';
import { EnrouteOrder } from './EnrouteOrder';
import { RescheduleInfo } from './RescheduleInfo';
import { defaultMemoize } from 'reselect';
import { TimeUtil } from 'helpers/TimeUtil';
import { CustomerChecklist } from './CustomerChecklist';
import { Status } from './Status';
import { CustomerChecklistData } from './CustomerChecklistFilled';
import { ExpiryInfo } from './ExpiryInfo';
import { Banner } from './Banner';

const VEHICLE_ICON_MAP = {
    CAR: 'img/vehicles/car.png',
    TRUCK: 'img/vehicles/truck.png',
    BUS: 'img/vehicles/van.png',
    BIKE: 'img/vehicles/bike.png',
    TRICYCLE: 'img/vehicles/bike.png',
    PERSON: 'img/vehicles/bike.png',
    NONE: 'img/vehicles/bike_new.svg',
};
const mapDataBuilder = defaultMemoize((tripInfo) => {
    return {
        destinationAddress: tripInfo.destination?.address,
        destinationLatLng: tripInfo.getDestinationLatLng(),
        sourceLatLng: tripInfo.getSourceLatLng(),
        userLatLng: tripInfo.getUserLocationLatLng(),
        userIcon: tripInfo.getUserVehicleIcon(),
    };
});
class InternalTripInfo extends TripInfo {
    static fromJS(json: TripInfoModelType): InternalTripInfo {
        const state: Object = Object.assign({}, json);
        state.visitId = VisitId.fromJS(state.visitId);
        state.userLocation = state.userLocation
            ? AddressLatLng.fromJS(state.userLocation)
            : state.userLocation;
        state.source = state.source
            ? AddressLatLng.fromJS(state.source)
            : state.source;
        state.destination = AddressLatLng.fromJS(state.destination);
        state.orderDetail = state.orderDetail
            ? OrderDetail.fromJS(state.orderDetail)
            : state.orderDetail;
        state.amount = AmountTransaction.fromJS(state.amount);
        state.tripDisplayStrings = Immutable.Map(state.tripDisplayStrings);
        state.status = TripStatus.fromJS(state.status);
        state.enrouteOrders = state.enrouteOrders
            ? Immutable.List(state.enrouteOrders).map((item) =>
                  EnrouteOrder.fromJS(item)
              )
            : state.enrouteOrders;
        state.settings = LiveViewSettings.fromJS(state.settings);
        state.checklists = CustomerChecklistData.fromJS(state.checklists);
        state.customerNotes = Immutable.List(state.customerNotes);
        state.rescheduleInfo = RescheduleInfo.fromJS(state.rescheduleInfo);
        state.banner = state.banner
            ? Banner.fromJS(state.banner)
            : state.banner;
        return new this(Immutable.Map(state));
    }

    getMapData() {
        return mapDataBuilder(this);
    }

    getDestinationLatLng() {
        return this.destination?.latLng?.toJS();
    }
    getSourceLatLng() {
        return this.source?.latLng?.toJS();
    }
    getUserLocationLatLng() {
        if (this.isCompleted()) {
            return null;
        }
        return this.userLocation?.latLng?.toJS();
    }
    getUserVehicleIcon() {
        return (
            this.vehicleIconUrl ||
            (this.vehicleType && VEHICLE_ICON_MAP[this.vehicleType]) ||
            VEHICLE_ICON_MAP.NONE
        );
    }
    getLineItems() {
        return (
            this.orderDetail &&
            this.orderDetail.lineItems.map((item) => {
                const { name, id, quantity } = item;
                const itemName = name ?? id;
                const updatedName =
                    quantity >= 1 ? `${itemName} x ${quantity}` : name;
                return item.setName(updatedName);
            })
        );
    }
    getTotalAmountForLineItems() {
        let sum = 0;
        this.orderDetail &&
            this.orderDetail.lineItems.forEach((item) => {
                const { price } = item;
                sum = sum + price.amount;
            });
        return sum;
    }
    getMarkerIconType() {
        switch (this.status.status) {
            case TripStatusEnum.Completed:
                return 'success';
            case TripStatusEnum.Cancelled:
                return 'danger';
            default:
                return 'primary';
        }
    }

    /**
     * ETA is valid if it's before 'now'.
     * Also, check for `this.eta` before parsing,
     * as `dayjs` returns 'Invalid date' for `null` values.
     */
    isETAValid() {
        return (
            this.eta &&
            !TimeUtil.dayjs(this.eta).isBefore(TimeUtil.dayjs(), 'seconds')
        );
    }
    isInitial() {
        return this.isReceived() || this.isAssigned();
    }
    isTerminal() {
        return this.isCompleted() || this.isCancelled();
    }
    isCompleted() {
        return this.status.status === TripStatusEnum.Completed;
    }
    isStarted() {
        return this.status.status === TripStatusEnum.Started;
    }
    isCancelled() {
        return this.status.status === TripStatusEnum.Cancelled;
    }
    isReceived() {
        return this.status.status === TripStatusEnum.Received;
    }
    isAssigned() {
        return this.status.status === TripStatusEnum.Assigned;
    }
    isAccepted() {
        return this.status.status === TripStatusEnum.Accepted;
    }

    get visitId(): VisitId {
        return this._state.get('visitId');
    }

    get userLocation(): ?AddressLatLng {
        return this._state.get('userLocation');
    }

    get source(): ?AddressLatLng {
        return this._state.get('source');
    }

    get destination(): AddressLatLng {
        return this._state.get('destination');
    }

    get orderDetail(): ?OrderDetail {
        return this._state.get('orderDetail');
    }

    get amount(): AmountTransaction {
        return this._state.get('amount');
    }

    get status(): TripStatus {
        return this._state.get('status');
    }

    get enrouteOrders(): ?Immutable.List<EnrouteOrder> {
        return this._state.get('enrouteOrders');
    }

    get settings(): LiveViewSettings {
        return this._state.get('settings');
    }

    get rescheduleInfo(): RescheduleInfo {
        return this._state.get('rescheduleInfo');
    }

    get checklists(): CustomerChecklistData {
        return this._state.get('checklists');
    }

    get expiryInfo(): ?ExpiryInfo {
        return this._state.get('expiryInfo');
    }

    get banner(): ?Banner {
        return this._state.get('banner');
    }
}

export { InternalTripInfo as TripInfo };
