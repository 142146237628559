// @flow
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Input, Flex } from '@locus.sh/neo';
import { isValidString } from 'helpers/StringUtil';
import { isMobile } from 'helpers/MediaHelper';

type Props = {
    value: string,
    onChange: (value: string) => any,
    length?: number,
    isNumericOnly?: boolean,
};

const otpInputStyles = {
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    boxShadow: 'none',
    borderRadius: 'none',
    width: '3.25rem',
    height: '3.25rem',
    textAlign: 'center',
};

function OTPInput({
    value,
    onChange,
    length = 6,
    isNumericOnly = true,
}: Props): React$Element<any> {
    const [internalValue, setInternalValue]: [Array<string>, any] = useState(
        new Array(length).fill('')
    );

    const isBackSpaceTriggeredOnEmptyInput = useRef(false);
    const inputElementRefs = React.useMemo(
        () => Array.from({ length }, () => React.createRef()),
        [length]
    );

    // handles single input as well as multiple value input cases (specially copy-paste ones)
    const updateInternalValue = useCallback(
        (newValue, startIndex = 0) => {
            setInternalValue((oldValue) => {
                let currentIndex = startIndex; // startIndex - from where customer has entered input
                for (const value of newValue) {
                    oldValue[currentIndex] = value;
                    currentIndex += 1;
                    if (currentIndex >= length) {
                        break;
                    }
                }
                return [...oldValue];
            });
        },
        [length]
    );

    const sanitizeInput = (input: string) => {
        if (!isNumericOnly) {
            return input;
        }

        const isNumber = (value) => !isNaN(value);
        return input.split('').filter(isNumber).join('');
    };

    const handleChange = (element, index) => {
        if (isBackSpaceTriggeredOnEmptyInput.current) {
            isBackSpaceTriggeredOnEmptyInput.current = false;
            return;
        }
        const elementValue = element.value.trim();
        const sanitizedInput = sanitizeInput(elementValue);

        if (!isValidString(sanitizedInput)) {
            // remove input from specific index
            setInternalValue((value) => {
                value[index] = sanitizedInput;
                return [...value];
            });
            return;
        }

        updateInternalValue(sanitizedInput, index);
        focusElement(index + sanitizedInput.length);
    };

    const focusElement = (index) => {
        const element = inputElementRefs[index]?.current;
        if (element) {
            element.focus();
        }
    };

    const handleKeyDown = (event, index) => {
        if (!isValidString(event.target.value) && event.keyCode === 8) {
            isBackSpaceTriggeredOnEmptyInput.current = true;
            focusElement(index - 1);
        }
    };

    useEffect(() => {
        const trimmedValue = internalValue.join('').trim();
        if (trimmedValue) {
            onChange(trimmedValue);
        }
    }, [internalValue, onChange]);

    useEffect(() => {
        updateInternalValue(value);
    }, [length, updateInternalValue, value]);

    return (
        <Flex>
            {internalValue.map((data, index) => (
                <Input
                    value={data}
                    onChange={(e) => handleChange(e.target, index)}
                    id={`otp_input_${index}`}
                    key={index}
                    onFocus={(e) => e.target.select()}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    ref={inputElementRefs[index]}
                    style={{
                        marginRight:
                            index === internalValue.length - 1 ? '0' : '1rem',
                        ...otpInputStyles,
                    }}
                    type={isMobile() && isNumericOnly ? 'number' : 'text'}
                />
            ))}
        </Flex>
    );
}

export default OTPInput;
