//@flow
import React from 'react';
import bugsnag, { Bugsnag } from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import NewRelic from 'helpers/NewRelic';

const BUGSNAG_API_KEY = 'e90f60b50f5029ecaff74d08e796f98e';

class BugsnagHelper {
    bugsnagClient: Bugsnag.Client;
    constructor() {
        this.initialize();
    }

    initialize() {
        if (this.isBugsnagDefined() && !this.isInitialized) {
            this.bugsnagClient = bugsnag({
                apiKey: BUGSNAG_API_KEY,
                appVersion: __VERSION__, // eslint-disable-line no-undef
                notifyReleaseStages: ['production'], // notify only production issues
                releaseStage: process.env.NODE_ENV,
            });
            this.bugsnagClient.use(bugsnagReact, React);
            this.ErrorBoundary = this.bugsnagClient.getPlugin('react');
            this.isInitialized = true;
        }
    }

    getClient() {
        return this.bugsnagClient;
    }

    isBugsnagDefined() {
        return typeof bugsnag !== 'undefined';
    }

    setUser(user) {
        if (this.isInitialized) {
            this.bugsnagClient.user = user;
        }
    }

    notify(error, { metaData, errorName, ...othersOptions } = {}) {
        const newError = new Error(error);
        if (errorName) {
            newError.name = errorName;
        }
        NewRelic.notifyError(error);
        if (this.isInitialized) {
            this.bugsnagClient.notify(newError, {
                metaData,
                ...(othersOptions || {}),
            });
        }
    }

    getErrorBoundary() {
        return this.ErrorBoundary;
    }
}

export default new BugsnagHelper();
