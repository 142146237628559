//@flow
import * as React from 'react';
import { Position, mediaQueries } from '@locus.sh/neo';
import { useTripContext } from 'trip/useTripContext';
import { CustomerSupport } from './CustomerSupport';
import { RefreshButton } from './RefreshButton';
import { refreshTrip } from 'trip/helpers';
import { EnrouteOrderInfo } from './EnrouteOrderInfo';
import testIds from 'tests/trip-map/testIds.json';
import { canShowPaymentInfo } from 'components/PaymentInfo';
import { canShowBanner } from 'components/Banner';
import { canShowUrlCard } from 'sections/header/URLCard';
import { useResizeDetector } from 'react-resize-detector';
import TripStaticMap from './TripStaticMap';
import ETAPopup from 'sections/tripMap/ETAPopup';
import TripDynamicMap from './TripDynamicMap';
import useBackgroundMapPosition from 'hooks/useBackgroundMapPosition';
import { useBannerContext } from 'sections/state/banner';
import { withDirectionsProvider } from 'sections/state/directions';

const TripMap = function ({ center, shouldShowPane }) {
    const [state, dispatch] = useTripContext();
    const { tripInfo } = state;
    const { tripId } = tripInfo;
    const isRefreshInProgress = state.isRefreshInProgress();

    return (
        <TripMapRenderer
            zoom={15}
            center={center}
            shouldShowPane={shouldShowPane}
        >
            {({ dynamicMapRef }) => {
                return (
                    <>
                        <CustomerSupport />
                        <EnrouteOrderInfo />
                        <RefreshButton
                            inProgress={isRefreshInProgress}
                            onClick={() => {
                                refreshTrip(tripId)(dispatch);
                                dynamicMapRef?.current?.refresh();
                            }}
                        />
                        <ETAPopup />
                    </>
                );
            }}
        </TripMapRenderer>
    );
};

const tripMapStyle = {
    [mediaQueries.md]: {
        height: '100%',
        position: 'relative',
        top: 0,
    },
};

const TripMapRenderer = (props) => {
    const { children, shouldShowPane } = props;
    const [state] = useTripContext();
    const { tripInfo } = state;
    const { settings } = tripInfo;
    const {
        moduleSettings: { enableHeaderModule },
    } = settings;
    const { width, height, ref } = useResizeDetector({
        refreshMode: 'debounce',
        refreshRate: 250,
    });

    const dynamicMapRef = React.useRef();
    const isUrlChecklistShown = canShowUrlCard(tripInfo);
    const isBannerShownOnTop = canShowBanner(tripInfo, 'belowLogo');
    const { bannerHeight } = useBannerContext();
    const backgroundMapPosition = useBackgroundMapPosition({
        isHeaderShown: enableHeaderModule,
        shouldShowPane,
        isUrlChecklistShown,
        canShowPaymentInfo: canShowPaymentInfo(tripInfo),
        isBannerShownOnTop,
        topBannerHeight: bannerHeight,
    });

    return (
        <Position
            position="fixed"
            width="100%"
            sx={{ ...backgroundMapPosition, ...tripMapStyle }}
            id={testIds.tripMapModule}
            data-test-id={testIds.tripMapModule}
            ref={ref}
        >
            {children({ dynamicMapRef })}

            {tripInfo.isStarted() ? (
                <TripDynamicMap ref={dynamicMapRef} />
            ) : (
                <TripStaticMap size={{ width, height }} />
            )}
        </Position>
    );
};

TripMap.defaultProps = {
    center: { lat: 12.933852, lng: 77.620284 },
};

export default withDirectionsProvider(TripMap);
