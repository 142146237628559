// @flow
import type { TripInfoProp } from 'components/status/TripInfoProp.type';
import { useTranslation } from 'react-i18next';
import { Text } from '@locus.sh/neo';
import React from 'react';
import testIds from 'tests/trip-pane/testIds.json';

function Pin({ tripInfo }: TripInfoProp): React$Element<any> | null {
    const {
        pin,
        settings: { showEta },
    } = tripInfo;
    const isETAValid = tripInfo.isETAValid();
    const shouldShowEta = isETAValid && showEta;
    const { t } = useTranslation();

    if (!tripInfo.isStarted() || !pin || !shouldShowEta) {
        return null;
    }

    return (
        <Text
            backgroundColor="grey.200"
            px={2}
            mr={2}
            py={1}
            fontSize={1}
            sx={{ borderRadius: '2px' }}
            data-test-id={testIds.pin}
        >
            {t('pin', { pin })}
        </Text>
    );
}

export default Pin;
