//@flow
import * as Immutable from 'immutable';

import { EnrouteOrder } from 'models/flow/EnrouteOrder.js';
import type { EnrouteOrderModelType } from 'models/flow/EnrouteOrder.js';

import { LatLng } from './LatLng';

class InternalEnrouteOrder extends EnrouteOrder {
    static fromJS(json: EnrouteOrderModelType): InternalEnrouteOrder {
        const state: Object = Object.assign({}, json);
        state.latLng = LatLng.fromJS(state.latLng);
        return new this(Immutable.Map(state));
    }

    get latLng(): LatLng {
        return this._state.get('latLng');
    }
}

export { InternalEnrouteOrder as EnrouteOrder };
