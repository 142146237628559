//@flow
import * as React from 'react';
import { useGoogleMap } from '@react-google-maps/api';
import { Box, mediaQueries } from '@locus.sh/neo';
import { useTranslation } from 'react-i18next';
const getZoomContainerCSS = (theme) => {
    return {
        display: 'none',
        width: '30px',
        backgroundColor: 'white',
        fontSize: '28px',
        borderRadius: 4,
        boxShadow: theme.shadows.normal,
        button: {
            '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.04)',
            },
            '&:focus': {
                backgroundColor: 'rgba(0,0,0,0.04)',
            },
        },
        bottom: '50px !important',
        [mediaQueries.md]: {
            display: 'flex',
            flexDirection: 'column',
        },
    };
};
const getDividerCSS = () => {
    return {
        width: '100%',
        height: '1px',
        backgroundColor: 'grey.200',
    };
};

const MapZoomControl = () => {
    const map = useGoogleMap();
    const { t } = useTranslation();
    const handleZoomIn = () => {
        if (!map) {
            return;
        }
        map.setZoom(map.getZoom() + 1);
    };
    const handleZoomOut = () => {
        if (!map) {
            return;
        }
        map.setZoom(map.getZoom() - 1);
    };
    const zoomContainer = React.useRef();
    React.useEffect(() => {
        map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(
            zoomContainer.current
        );
    }, [map]);
    return (
        <Box
            flexDirection="column"
            ml={2}
            mb={2}
            sx={getZoomContainerCSS}
            ref={zoomContainer}
        >
            <button title={t('mapZoomIn')} onClick={handleZoomIn}>
                +
            </button>
            <Box sx={getDividerCSS} />
            <button title={t('mapZoomOut')} onClick={handleZoomOut}>
                -
            </button>
        </Box>
    );
};
export { MapZoomControl };
export default MapZoomControl;
