/* TODO: If in future this function is getting used in many places,
   replace it with more reliable uuid
*/

/** returns a random unique string */
const uniqueId = () => {
    const dateString = Date.now().toString(36);
    const randomness = Math.random().toString(36).substr(2);
    return dateString + randomness;
};

export default uniqueId;
