// @flow
import * as React from 'react';
import type { TripInfo } from 'models/internal/TripInfo';
import type { TripStatus } from 'models/internal/TripStatus';
export const StateContext = React.createContext();

export const StateProvider = ({ reducer, initialState, children }) => (
    <StateContext.Provider value={React.useReducer(reducer, initialState)}>
        {children}
    </StateContext.Provider>
);

type State = {
    tripInfo: TripInfo,
    tripStatus: TripStatus,
};
export const useTripContext = (): [State] => React.useContext(StateContext);
