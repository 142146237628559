// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel'; // Make sure you copied this file into your repo

export type AmountModelType = {
    amount: number,
    /**
     * Currency for the amount.
     * Value should be one of the active codes of official ISO 4217 currency names.
     * Examples are INR, USD, AED, GBP etc ,
     */
    currency: string,
    /**
     * A symbol for the currency.
     * If missing, will default to currency string.
     */
    symbol: string,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class Amount extends ImmutableModel {
    static fromJS(json: AmountModelType): Amount {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }

    toJS(): AmountModelType {
        return {
            amount: this.amount,
            currency: this.currency,
            symbol: this.symbol,
        };
    }

    get amount(): number {
        return this._state.get('amount');
    }

    setAmount(amount: number): this {
        return this.clone(this._state.set('amount', amount));
    }

    get currency(): string {
        return this._state.get('currency');
    }

    setCurrency(currency: string): this {
        return this.clone(this._state.set('currency', currency));
    }

    get symbol(): string {
        return this._state.get('symbol');
    }

    setSymbol(symbol: string): this {
        return this.clone(this._state.set('symbol', symbol));
    }
}
