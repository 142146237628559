// @flow
import React, { createContext, useState, useContext, useCallback } from 'react';

type Props = {
    children: React$Element<any>,
};

const OrderNoteContext = createContext();

const OrderNoteProvider = ({ children }: Props): React$Element<any> => {
    const [isNoteEditing, setIsNoteEditing] = useState(false);
    const [canShowAddNoteButton, setCanShowAddNoteButton] = useState(false);

    const openNoteEditor = useCallback(() => setIsNoteEditing(true), []);
    const closeNoteEditor = useCallback(() => setIsNoteEditing(false), []);

    const showAddNoteButton = useCallback(
        () => setCanShowAddNoteButton(true),
        []
    );
    const hideAddNoteButton = useCallback(
        () => setCanShowAddNoteButton(false),
        []
    );

    return (
        <OrderNoteContext.Provider
            value={{
                isNoteEditing,
                openNoteEditor,
                closeNoteEditor,
                canShowAddNoteButton,
                hideAddNoteButton,
                showAddNoteButton,
            }}
        >
            {children}
        </OrderNoteContext.Provider>
    );
};

const useOrderNoteContext = (): any => {
    const context = useContext(OrderNoteContext);
    return context;
};

export { useOrderNoteContext, OrderNoteProvider };
