//@flow
import * as Immutable from 'immutable';

import { AddressLatLng } from 'models/flow/AddressLatLng.js';
import type { AddressLatLngModelType } from 'models/flow/AddressLatLng.js';

import { LatLng } from './LatLng';

class InternalAddressLatLng extends AddressLatLng {
    static fromJS(json: AddressLatLngModelType): InternalAddressLatLng {
        const state: Object = Object.assign({}, json);
        state.latLng = LatLng.fromJS(state.latLng);
        return new this(Immutable.Map(state));
    }

    get latLng(): LatLng {
        return this._state.get('latLng');
    }
}

export { InternalAddressLatLng as AddressLatLng };
