//@flow
import ExtendableError from 'es6-error';
import { TripInfo } from 'models/internal/TripInfo';
import { Direction } from 'models/internal/Direction';
import EnvConfig from 'config';
import I18nHelper from 'helpers/I18nHelper';
import NewRelic from 'helpers/NewRelic';

export const getAPIURL = (url: string) => {
    return `${EnvConfig.apiEndPoint}/${url}`;
};

const getTripURL = (tripId, firstPageLoad = false) => {
    const language = I18nHelper.getPreferredLanguage();
    let url = `${EnvConfig.apiEndPoint}/trip/${tripId}/info`;

    if (language) {
        url += `?language=${language}`;
    }
    if (firstPageLoad) {
        const str = 'firstPageLoad=true';
        url += language ? `&${str}` : `?${str}`;
    }
    return url;
};

const getPhoneCallURL = (tripId) =>
    `${EnvConfig.apiEndPoint}/trip/${tripId}/phonecall`;

const getAddNotesURL = (tripId) =>
    `${EnvConfig.apiEndPoint}/trip/${tripId}/note`;

const directionAPI = `${EnvConfig.apiEndPoint}/direction`;

export class ApiError extends ExtendableError {
    constructor(message, statusCode) {
        super(message);
        this.statusCode = statusCode;
    }
}
export class InternetNotAvailableError extends ExtendableError {}

const checkNetwork = function (response) {
    const error = new InternetNotAvailableError();
    error.response = response;
    throw error;
};
export const fetchClient = {
    fetch: ({ url, method = 'GET', headers = {}, body }) => {
        return fetch(url, {
            method,
            headers: new Headers({
                ...headers,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            }),
            body: JSON.stringify(body),
        }).then((res) => {
            if (res.ok) {
                return res.json();
            }
            return res.json().then(
                (json) => {
                    const apiError = new ApiError(json.message, res.status);
                    apiError.data = json;
                    throw apiError;
                },
                (error) => {
                    const apiError = new ApiError(res.statusText, res.status);
                    apiError.data = error;
                    throw apiError;
                }
            );
        }, checkNetwork);
    },
};
const tripClient = {
    fetch: (tripId, firstLoad) => {
        return fetchClient.fetch({ url: getTripURL(tripId, firstLoad) });
    },
};

const directionClient = {
    fetch: (body) =>
        fetchClient.fetch({
            url: directionAPI,
            method: 'POST',
            body,
        }),
};

export const phoneCallClient = {
    fetch: (tripId: string): Promise<any> => {
        return fetchClient.fetch({
            url: getPhoneCallURL(tripId),
            method: 'POST',
        });
    },
};

export const addNotesClient = {
    fetch: (tripId: string, payload: string): Promise<any> => {
        return fetchClient.fetch({
            url: getAddNotesURL(tripId),
            method: 'POST',
            body: {
                note: payload,
            },
        });
    },
};

export const pollFetchTrip = (tripId, { onSuccess, onFailure } = {}) => {
    return (dispatch) => {
        dispatch({ type: 'TRIP_POLL_START' });
        NewRelic.track('firstPageLoad', 'false');
        tripClient
            .fetch(tripId)
            .then((response) => {
                const tripInfo = TripInfo.fromJS(response);
                I18nHelper.changeLanguage(response.tripDisplayStrings);
                dispatch({ type: 'TRIP_POLL_SUCCESS', data: tripInfo });

                onSuccess?.(tripInfo);
            })
            .catch((error) => {
                dispatch({ type: 'TRIP_POLL_FAILURE', error });
                onFailure?.(error);
            });
    };
};
export const fetchFirstTrip = (
    tripId,
    firstLoad,
    { onSuccess, onFailure } = {}
) => {
    return (dispatch) => {
        dispatch({ type: 'TRIP_FETCH_START' });
        NewRelic.track('firstPageLoad', 'true');
        return tripClient
            .fetch(tripId, firstLoad)
            .then((response) => {
                const tripInfo = TripInfo.fromJS(response);
                I18nHelper.changeLanguage(response.tripDisplayStrings);
                dispatch({ type: 'TRIP_FETCH_SUCCESS', data: tripInfo });
                onSuccess?.(tripInfo);
            })
            .catch((error) => {
                dispatch({ type: 'TRIP_FETCH_FAILURE', error });
                onFailure?.(error);
            });
    };
};
export const refreshTrip = (tripId, { onSuccess, onFailure } = {}) => {
    return (dispatch) => {
        dispatch({ type: 'TRIP_REFRESH_START' });
        NewRelic.track('firstPageLoad', 'false');
        tripClient
            .fetch(tripId)
            .then((response) => {
                const tripInfo = TripInfo.fromJS(response);
                I18nHelper.changeLanguage(response.tripDisplayStrings);
                dispatch({ type: 'TRIP_REFRESH_SUCCESS', data: tripInfo });
                onSuccess?.(tripInfo);
            })
            .catch((error) => {
                dispatch({ type: 'TRIP_REFRESH_FAILURE', error });
                onFailure?.(error);
            });
    };
};

export const fetchDirection = ({ origin, destination }) => {
    return (dispatch) => {
        return directionClient
            .fetch({
                source: `${origin.lat},${origin.lng}`,
                destination: `${destination.lat},${destination.lng}`,
            })
            .then((response) => {
                const directions = Direction.fromJS(response);
                dispatch({
                    type: 'TRIP_DIRECTIONS_FETCH_SUCCESS',
                    data: directions,
                });
            })
            .catch((error) => {
                dispatch({ type: 'TRIP_DIRECTIONS_FETCH_FAILURE', error });
            });
    };
};
