import * as React from 'react';
import { Flex, Text, Image } from '@locus.sh/neo';
import testIds from 'tests/watermark/testIds.json';

const Watermark = (props) => {
    return (
        <Flex
            alignItems="center"
            justifyContent="center"
            mt="auto"
            px={3}
            py={3}
            flexShrink={0}
            {...props}
            data-test-id={testIds.poweredByModule}
        >
            <Text
                color="grey.800"
                textTransform="uppercase"
                fontSize={2}
                letterSpacing="1px"
                data-test-id={testIds.poweredByText}
            >
                Powered By
            </Text>
            <Image
                ml={2}
                maxHeight="16px"
                src="img/logo.png"
                data-test-id={testIds.locusLogo}
            />
        </Flex>
    );
};

export { Watermark };
export default Watermark;
