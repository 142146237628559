//@flow
import * as Immutable from 'immutable';

import { Banner, Position } from 'models/flow/Banner.js';
import type { BannerModelType } from 'models/flow/Banner.js';

class InternalBanner extends Banner {
    static fromJS(json: BannerModelType): InternalBanner {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }

    isTopPosition(): boolean {
        return this.position === Position.TOP;
    }
}

export { InternalBanner as Banner };
