//@flow
import * as React from 'react';
import { RemainingTime } from 'components';
import { Position, Text } from '@locus.sh/neo';
import { useTripContext } from 'trip/useTripContext';
import TimeUtil from 'helpers/TimeUtil';
import testIds from 'tests/trip-map/testIds.json';

const getCSS = (theme) => ({
    borderRadius: 4,
    boxShadow: theme.shadows.raised,
    userSelect: 'none',
    alignItems: 'center',
    gap: 1,
});
const ETAPopup: React.AbstractComponent<{}> = React.forwardRef((props, ref) => {
    const [{ tripInfo }] = useTripContext();

    const { eta, settings } = tripInfo;
    const isETAValid = tripInfo.isETAValid();
    const isInitial = tripInfo.isInitial();
    const isTerminal = tripInfo.isTerminal();
    const isAccepted = tripInfo.isAccepted();

    const {
        showEta,
        moduleSettings: { enableStatusModule },
    } = settings;
    const isEtaVisibleOnStatusCard = enableStatusModule && !isAccepted;

    if (
        !showEta ||
        !isETAValid ||
        isInitial ||
        isTerminal ||
        isEtaVisibleOnStatusCard
    ) {
        return null;
    }

    const absoluteTime = TimeUtil.format({
        date: tripInfo.eta,
        format: 'h:mm a',
    });
    return (
        <Position
            position="absolute"
            backgroundColor="blue.50"
            display="flex"
            ref={ref}
            sx={getCSS}
            px={1}
            left={2}
            bottom={3}
            zIndex={15}
            data-test-id={testIds.etaPopUp}
        >
            <RemainingTime time={eta} />
            <Text
                fontSize={2}
                color="blue.600"
                fontWeight={600}
            >{`(${absoluteTime})`}</Text>
        </Position>
    );
});
export { ETAPopup };
export default ETAPopup;
