//@flow
export const animateTo = function ({
    marker,
    currentPosition,
    newPosition,
    duration,
    completeCallback,
}: {
    marker?: any,
    currentPosition: { lat: number, lng: number },
    newPosition: { lat: number, lng: number },
    duration?: number,
    completeCallback?: Function,
}) {
    if (!marker) {
        return;
    }
    var startLat = currentPosition.lat;
    var startLng = currentPosition.lng;
    var endLat = newPosition.lat;
    var endLng = newPosition.lng;
    var animateStep = function (startDate) {
        var elapsedTime = new Date() - startDate;

        var durationRatio = elapsedTime / duration;
        var easingDurationRatio = 0.5 - Math.cos(durationRatio * Math.PI) / 2;

        if (durationRatio < 1) {
            var deltaLat = startLat + (endLat - startLat) * easingDurationRatio;
            var deltaLng = startLng + (endLng - startLng) * easingDurationRatio;
            var deltaPosition = new window.google.maps.LatLng(
                deltaLat,
                deltaLng
            );
            marker.setPosition(deltaPosition);
            marker.animateHandler = window.requestAnimationFrame(() => {
                animateStep(startDate);
            });
        } else {
            marker.setPosition(newPosition);

            if (typeof completeCallback === 'function') {
                completeCallback();
            }
        }
    };
    window.cancelAnimationFrame(marker.animateHandler);
    animateStep(new Date());
    return marker;
};
