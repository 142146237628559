//@flow
import * as React from 'react';
import { Box, Position, Text, Image } from '@locus.sh/neo';
import { useTripContext } from 'trip/useTripContext';
import testIds from 'tests/trip-map/testIds.json';

const CustomerSupport = function () {
    const [{ tripInfo }] = useTripContext();
    const { settings } = tripInfo;
    const { callSupportDisplayText, callSupportNumber } = settings;
    if (!callSupportNumber) {
        return null;
    }
    const backgroundColor =
        'linear-gradient(180deg, rgba(242,242,242,1) 0%, rgba(242,242,242,0.9) 80%, rgba(242,242,242,0) 100%)';
    return (
        <Position
            p={4}
            pl={3}
            pt={3}
            position="absolute"
            width={180}
            left={0}
            zIndex={11}
            top={0}
            sx={{
                background: backgroundColor,
            }}
            display="flex"
            alignItems="center"
            title={callSupportNumber}
            data-test-id={testIds.customerCare}
        >
            <Box
                width={40}
                height={40}
                as="a"
                flexShrink="0"
                href={`tel:${callSupportNumber}`}
            >
                <Image src={'/img/contact_support.png'} width="100%" />
            </Box>
            <Text
                as="a"
                href={`tel:${callSupportNumber}`}
                fontSize={1}
                ml={2}
                fontWeight="medium"
                textTransform="uppercase"
                maxWidth="120px"
            >
                {callSupportDisplayText}
            </Text>
        </Position>
    );
};
export { CustomerSupport };
export default CustomerSupport;
