//@flow
import React, { type Node } from 'react';
import { Box, Flex, Text } from '@locus.sh/neo';
import Slot from 'components/Slot';
import TimeUtil from 'helpers/TimeUtil';

import { useTranslation } from 'react-i18next';

export const formatSlot = (dateISO: string): string =>
    TimeUtil.format({ date: dateISO, format: 'hh:mm a' });

type SlotType = {
    start: string,
    end: string,
};

type Props = {
    slots: Array<SlotType>,
    onSlotSelect: (slot: SlotType) => void,
    selectedSlot: ?SlotType,
};

const SlotList = ({ slots, onSlotSelect, selectedSlot }: Props): Node => {
    const { t } = useTranslation();
    const handleSelectSlot = (start, end) => () => {
        const selectedSlot = slots.find(
            (slot) => slot.start === start && slot.end === end
        );
        if (selectedSlot) {
            onSlotSelect(selectedSlot);
        }
    };
    if (slots.length === 0) {
        return (
            <Text
                marginBottom={8}
                fontSize={1}
                fontWeight={400}
                color="grey.900"
            >
                {t('noSlotsAvailableMessage')}
            </Text>
        );
    }
    return (
        <Box>
            <Text color="grey.900" fontWeight={600} fontSize={1}>
                {t('selectTime')}
            </Text>
            <Flex
                flexWrap="wrap"
                marginTop={4}
                maxHeight="40vh"
                overflowY="auto"
            >
                {slots.map((slot, index) => (
                    <Slot
                        selected={
                            slot.start === selectedSlot?.start &&
                            slot.end === selectedSlot?.end
                        }
                        key={index}
                        onClick={handleSelectSlot(slot.start, slot.end)}
                    >
                        {formatSlot(slot.start)} - {formatSlot(slot.end)}
                    </Slot>
                ))}
            </Flex>
        </Box>
    );
};

export default SlotList;
