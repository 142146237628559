//@flow
import * as React from 'react';
import { Marker } from 'components';
import { useTripContext } from 'trip/useTripContext';

const EnrouteOrderMarker = () => {
    const [state] = useTripContext();
    const { tripInfo } = state;
    const { enrouteOrders, settings } = tripInfo;
    const { showEnrouteOrders } = settings;
    if (!showEnrouteOrders || !enrouteOrders.size) {
        return null;
    }
    return enrouteOrders.map((order, index) => {
        return (
            <Marker
                key={order.latLng.getKey(index)}
                position={order.latLng.toJS()}
                iconType={
                    enrouteOrders.size === 1 ? 'warning' : 'warningMultiple'
                }
            />
        );
    });
};
export { EnrouteOrderMarker };

export default EnrouteOrderMarker;
