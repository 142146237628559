//@flow
import * as React from 'react';
import CustomOverlayView from './CustomOverlayView';
import { OverlayView } from '@react-google-maps/api';
import { Image } from '@locus.sh/neo';
const ICON_TYPE_URL_MAP = {
    primary: '/img/markers/OrderDrop.svg',
    success: '/img/markers/OrderDrop.svg',
    danger: '/img/markers/danger.svg',
    secondary: '/img/markers/OrderPickup.svg',
    warning: '/img/markers/warning.svg',
    warningMultiple: '/img/markers/warningMultiple.svg',
};
type Props = {
    iconType: 'primary' | 'secondary',
    imagePositioning: 'CENTERED' | 'BOTTOM_CENTERED',
    position: { lat: string, lng: string },
    renderMarker: Function,
    onMarkerLoad: Function,
};
const renderMarker = function (props) {
    return (
        <>
            {props.children}
            <Image
                ref={(ref) =>
                    ref &&
                    google.maps.OverlayView.preventMapHitsAndGesturesFrom(ref)
                }
                onClick={props.onIconClick}
                style={props.rotationStyle}
                src={props.iconUrl || ICON_TYPE_URL_MAP[props.iconType]}
                width={props.iconSize}
                height={props.iconSize}
                sx={getStyle(props)}
                draggable={false}
            />
        </>
    );
};

const getStyle = (props) => () => {
    return {
        cursor: props.clickable && 'pointer',
        left: -props.iconSize / 2,
        top: props.imagePositioning.includes('CENTERED')
            ? -props.iconSize / 2
            : -props.iconSize,
        position: 'absolute',
    };
};

const Marker = (props: Props): React$Element<any> => {
    return (
        <CustomOverlayView
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            position={props.position}
            ref={props.onMarkerLoad}
        >
            {props.renderMarker({ ...props })}
        </CustomOverlayView>
    );
};

Marker.defaultProps = {
    renderMarker,
    imagePositioning: 'CENTERED',
    iconType: 'primary',
    iconSize: 40,
};
export { Marker };
export default Marker;
