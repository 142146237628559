//@flow
import * as React from 'react';
import { MapHelper } from 'helpers/MapHelper';

export const useHeading = (startLatLng, endLatLng) => {
    const [heading, setHeading] = React.useState();
    React.useEffect(() => {
        if (startLatLng || endLatLng) {
            const heading = MapHelper.computeHeading({
                startLatLng,
                endLatLng,
            });
            setHeading(heading);
        }
    }, [startLatLng, endLatLng]);
    return heading;
};
export const useTripBounds = ({
    destinationLatLng,
    sourceLatLng,
    userLatLng,
    directions,
    showLocationTrail,
    showEnrouteOrders,
    enrouteOrders,
}) => {
    const [mapBounds, setMapBounds] = React.useState();
    const [numberOfPoints, setNumberOfPoints] = React.useState(0);
    const isInitialMount = React.useRef(0);
    React.useEffect(() => {
        if (isInitialMount.current === 0) {
            const bounds = MapHelper.makeBounds(destinationLatLng);
            let pointsLen = destinationLatLng ? 1 : 0;
            if (bounds) {
                if (showEnrouteOrders && enrouteOrders?.size) {
                    enrouteOrders.forEach((order) =>
                        bounds.extend(order.latLng.toJS())
                    );
                    pointsLen++;
                }
                if (!showLocationTrail && sourceLatLng) {
                    bounds.extend(sourceLatLng);
                    pointsLen++;
                }
                if (userLatLng) {
                    bounds.extend(userLatLng);
                    pointsLen++;
                }
                if (directions && directions.polyline) {
                    const polylineLatLngs =
                        google.maps.geometry.encoding.decodePath(
                            directions.polyline
                        );
                    polylineLatLngs.forEach((latLng) => bounds.extend(latLng));
                    pointsLen += polylineLatLngs.length;
                }
            }

            setMapBounds(bounds);
            setNumberOfPoints(pointsLen);
        }
    }, [
        showLocationTrail,
        showEnrouteOrders,
        enrouteOrders,
        destinationLatLng,
        sourceLatLng,
        userLatLng,
        directions,
    ]);
    return { bounds: mapBounds, numberOfPoints };
};
