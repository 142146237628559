//@flow
import React from 'react';
import { Box, mediaQueries } from '@locus.sh/neo';

const ContainerStyle = {
    cursor: 'pointer',
    flexShrink: 0,
    [mediaQueries.md]: {
        display: 'none',
    },
};

export const ScrollIndicator = (): React$Element<any> => (
    <Box
        alignSelf="center"
        width="100%"
        p={2}
        backgroundColor="white"
        sx={ContainerStyle}
    >
        <Box
            backgroundColor="grey.300"
            width="64px"
            height="4px"
            m="0 auto"
            borderRadius="45px"
        />
    </Box>
);
