// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel'; // Make sure you copied this file into your repo

import { LineItem } from './LineItem';

import type { LineItemModelType } from './LineItem';

export type OrderDetailModelType = {
    lineItems: Array<LineItemModelType>,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class OrderDetail extends ImmutableModel {
    static fromJS(json: OrderDetailModelType): OrderDetail {
        const state: Object = Object.assign({}, json);
        state.lineItems = Immutable.List(state.lineItems).map((item) =>
            LineItem.fromJS(item)
        );
        return new this(Immutable.Map(state));
    }

    toJS(): OrderDetailModelType {
        return {
            lineItems: this.lineItems.toArray().map((item) => item.toJS()),
        };
    }

    get lineItems(): Immutable.List<LineItem> {
        return this._state.get('lineItems');
    }

    setLineItems(lineItems: Immutable.List<LineItem>): this {
        return this.clone(this._state.set('lineItems', lineItems));
    }
}
