// @flow
import React, { useEffect, useRef, useState } from 'react';
import TripHeader, { canShowTripHeader } from 'sections/header/TripHeader';
import TripAddressCard, {
    canShowTripAddressCard,
} from 'sections/tripPane/TripAddressCard';
import OrderDetails, {
    canShowOrderDetails,
} from 'sections/tripPane/OrderDetails';
import { Pane, Watermark } from 'components';
import { Flex, mediaQueries, Box, Position } from '@locus.sh/neo';
import { useTripContext } from 'trip/useTripContext';
import { Checklist } from './Checklist/loadable';
import { URLCard } from 'sections/header/URLCard';
import MapTestIds from 'tests/trip-map/testIds.json';
import { OrderNoteProvider } from 'sections/state/orderNote';
import OrderNote from 'sections/tripPane/OrderNote';
import PaymentInfo, { canShowPaymentInfo } from 'components/PaymentInfo';
import TripPaneTopSection from 'sections/tripPane/TripPaneTopSection';
import { useInView } from 'react-intersection-observer';
import { isMobile } from 'helpers/MediaHelper';
import Banner, { canShowBanner } from 'components/Banner';
import { useBannerContext } from 'sections/state/banner';

const getTripPaneStyle = function (theme) {
    return {
        overflow: 'overlay',
        [mediaQueries.md]: {
            overflow: 'visible',
            minWidth: theme.sizes[1],
            width: '40%',
        },
    };
};

const sectionStyles = {
    marginBottom: 2,
};

const useDocumentTitle = (displayName) => {
    useEffect(() => {
        window.document.title = displayName;
    }, [displayName]);
};
const useFavicon = (favicon) => {
    useEffect(() => {
        document
            .querySelector("link[rel='shortcut icon']")
            .setAttribute('href', favicon);
    }, [favicon]);
};

const scrollY = (domRef: HTMLElement, scrollValue: number) => {
    if (domRef) {
        const options = {
            left: 0,
            behavior: 'smooth',
            top: scrollValue,
        };
        domRef.scroll({ ...options });
    }
};

const resetScroll = (domRef) => {
    if (domRef) {
        const scrolledValue = domRef.scrollTop;
        scrollY(domRef, -scrolledValue);
    }
};

const scrollToTop = (domRef, { isPaymentInfoShown }) => {
    const mapElement = document.querySelector(`#${MapTestIds.tripMapModule}`);
    let pxToScroll = mapElement.clientHeight - 5;

    if (isPaymentInfoShown) {
        pxToScroll -= 40;
    }

    if (mapElement) {
        scrollY(domRef, pxToScroll);
    }
};

const TripPane = function () {
    const [state] = useTripContext();
    const scrollablePaneRef = useRef();
    const [isStickyStatus, setIsStickyStatus] = useState(false);

    const { tripInfo } = state;
    const { settings } = tripInfo;
    const { displayName, favicon, showPoweredBy, customerNotesSettings } =
        settings;

    const isInitial = tripInfo.isInitial();
    const isTerminal = tripInfo.isTerminal();

    const { customerNotes } = tripInfo;
    // hide order note module if order is cancelled or completed and notes is empty
    const shouldHideOrderNotesModule = isTerminal && !customerNotes.size;

    let { showCustomerNotes } = customerNotesSettings;
    showCustomerNotes = showCustomerNotes && !shouldHideOrderNotesModule;

    const isPaymentInfoShown = canShowPaymentInfo(tripInfo);
    const { bannerHeight } = useBannerContext();

    useEffect(() => {
        const domRef = scrollablePaneRef?.current;
        if (isInitial || isTerminal) {
            scrollToTop(domRef, {
                isPaymentInfoShown,
            });
        } else {
            resetScroll(domRef);
        }
    }, [
        isInitial,
        isTerminal,
        isPaymentInfoShown,
        bannerHeight, // REASON: loading banner should affect the pane scroll position
    ]);

    useDocumentTitle(displayName);
    useFavicon(favicon);

    /**
     * Following part is responsible for sticky status and action module
     */

    const [observerRef, inView] = useInView({
        threshold: 1, // inView will be false if any px is outside of viewport
        fallbackInView: true,
    });

    useEffect(() => {
        const isMobileScreen = isMobile();
        if (!inView && isMobileScreen) {
            setIsStickyStatus(true);
        } else {
            setIsStickyStatus(false);
        }
    }, [inView]);

    return (
        <OrderNoteProvider>
            <Flex
                flexDirection="column"
                flexShrink={0}
                flexGrow={0}
                width="100%"
                sx={getTripPaneStyle}
                id="scrollablePane"
                ref={scrollablePaneRef}
            >
                <Position position="sticky" top={0} zIndex={1}>
                    {canShowTripHeader(tripInfo) && (
                        <TripHeader tripInfo={tripInfo} />
                    )}
                    <URLCard tripInfo={tripInfo} />
                    {canShowBanner(tripInfo, 'belowLogo') && <Banner />}
                </Position>
                {isStickyStatus && (
                    <TripPaneTopSection isSticky styles={sectionStyles} />
                )}
                <Pane tripInfo={tripInfo}>
                    <Box ref={observerRef} />
                    <PaymentInfo tripInfo={tripInfo} />
                    <TripPaneTopSection
                        hidden={isStickyStatus}
                        styles={sectionStyles}
                    />
                    {showCustomerNotes && <OrderNote styles={sectionStyles} />}
                    {canShowBanner(tripInfo, 'belowStatusModule') && <Banner />}
                    <Checklist tripInfo={tripInfo} />
                    {(canShowOrderDetails(tripInfo) ||
                        canShowTripAddressCard(tripInfo)) && (
                        <Box sx={sectionStyles} backgroundColor="white">
                            {canShowTripAddressCard(tripInfo) && (
                                <TripAddressCard />
                            )}
                            {canShowOrderDetails(tripInfo) && <OrderDetails />}
                        </Box>
                    )}
                    {showPoweredBy ? <Watermark tripInfo={tripInfo} /> : null}
                </Pane>
            </Flex>
        </OrderNoteProvider>
    );
};

export default TripPane;
