import './polyfill';
import { ErrorBoundary } from 'components/error/ErrorBoundary';
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@locus.sh/neo';
import App from './pages/App';
import './i18n';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';

dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);

const title = 'via.sh';

ReactDOM.render(
    <ThemeProvider>
        <ErrorBoundary>
            <App title={title} />
        </ErrorBoundary>
    </ThemeProvider>,
    document.getElementById('app')
);

if (module.hot) {
    module.hot.accept();
}
