//@flow
import * as React from 'react';
import { Box, Button, Flex, Position, Text, defaultTheme } from '@locus.sh/neo';
import uniqueId from 'helpers/UniqueIdHelper';
import { useTranslation } from 'react-i18next';

type Props = {
    charLimit?: number,
    label?: string,
    value: string,
    rows?: number,
    onChange: (s: string) => void,
    onClear?: () => void,
    error?: string,
    backgroundColor: string,
    leftIcon?: React.Node,
    placeholder?: string,
    autoFocus?: boolean,
    autoGrow?: boolean,
    maxRows?: number,
    dataTestId?: string,
};

const INITIAL_HEIGHT = 50; // initial height with padding
const ROW_HEIGHT = 18;

const TextArea = ({
    charLimit,
    label,
    value,
    onChange,
    onClear,
    error,
    rows = 5,
    backgroundColor,
    leftIcon,
    placeholder,
    autoFocus,
    autoGrow,
    maxRows,
    dataTestId,
}: Props): React.Node => {
    const { t } = useTranslation();
    const [id, setId] = React.useState();
    const textAreaRef = React.useRef();

    const handleChange = (e: Event) => {
        const { value } = e.target;
        onChange && onChange(value);
    };

    const handleClear = () => {
        if (textAreaRef?.current) {
            textAreaRef.current.focus();
        }
        onClear?.();
    };

    // Generate id only once
    React.useEffect(() => {
        setId(uniqueId());
    }, []);

    // For auto grow logic
    React.useEffect(() => {
        const node = textAreaRef?.current;
        if (node && autoGrow) {
            node.style.height = `${INITIAL_HEIGHT}px`;
            const { scrollHeight } = node;
            const maxNumberOfRows = Number(maxRows);

            const newHeight =
                maxNumberOfRows > 0
                    ? Math.min(
                          scrollHeight,
                          (maxNumberOfRows - 1) * ROW_HEIGHT + INITIAL_HEIGHT
                      )
                    : scrollHeight;
            node.style.height = `${newHeight}px`;
        }
    }, [value, autoGrow, maxRows]);

    const boxShadowColor = error
        ? defaultTheme.colors.red['400']
        : defaultTheme.colors.blue['400'];

    return (
        <Position position="relative">
            {leftIcon && (
                <Position position="absolute" zIndex={1} top={3} left={2}>
                    {leftIcon}
                </Position>
            )}
            <Box
                ref={textAreaRef}
                as="textarea"
                value={value}
                id={id}
                onChange={handleChange}
                required
                rows={rows}
                maxLength={charLimit}
                autoFocus={autoFocus}
                placeholder={placeholder}
                width="100%"
                pt={label ? 7 : 2}
                pr={8}
                pb={2}
                pl={leftIcon ? 8 : 2}
                color="grey.900"
                backgroundColor={backgroundColor}
                border="none"
                sx={{
                    resize: 'none',
                    outline: 'none',
                    fontSize: 2,
                    boxShadow: `0px 1px 0px ${defaultTheme.colors.grey['200']}`,
                    '&:focus': {
                        boxShadow: `0px 1px 0px ${boxShadowColor}`,
                    },
                    '&:focus + label, :valid + label': {
                        fontSize: 0,
                        top: 1,
                    },
                    '&:focus + label': {
                        color: 'blue.500',
                    },
                    '&::placeholder': {
                        color: 'grey.400',
                    },
                }}
                data-test-id={dataTestId}
            />
            {label && (
                <Box
                    as="label"
                    htmlFor={id}
                    color="grey.600"
                    backgroundColor={backgroundColor}
                    pl={2}
                    width={leftIcon ? 'calc(100% - 24px)' : '100%'}
                    sx={{
                        position: 'absolute',
                        left: leftIcon ? 6 : 0,
                        top: 3,
                        fontSize: 2,
                        transition: '0.3s ease all',
                        lineHeight: 1,
                    }}
                >
                    {label}
                </Box>
            )}
            {onClear && value && (
                <Button
                    appearance="icon"
                    type="button"
                    onClick={handleClear}
                    icon="cross"
                    size="small"
                    color="grey.600"
                    sx={{
                        position: 'absolute',
                        right: 2,
                        top: '45%',
                        transform: 'translate3d(0, -50%, 0)',
                    }}
                    data-test-id={
                        dataTestId ? `${dataTestId}-clearButton` : undefined
                    }
                />
            )}
            <Flex>
                {error && (
                    <Text fontSize={1} color="red.400" width="80%">
                        {error}
                    </Text>
                )}
                {charLimit && (
                    <Text
                        ml="auto"
                        width="max-content"
                        fontSize={1}
                        color="grey.500"
                    >
                        {value.length}/{charLimit} {t('characters')}
                    </Text>
                )}
            </Flex>
        </Position>
    );
};

TextArea.defaultProps = {
    backgroundColor: 'grey.100',
};

export { TextArea };
export default TextArea;
