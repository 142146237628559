// @flow
import * as React from 'react';
import { Flex, Text, Subheading, Box, Divider } from '@locus.sh/neo';
import { useTranslation } from 'react-i18next';
import { useTripContext } from 'trip/useTripContext';
import testIds from 'tests/trip-pane/testIds.json';
import type { TripInfo } from 'models/internal/TripInfo';

const Address = (props) => {
    const { address, title } = props;

    return (
        <Flex flexDirection="column">
            {title && (
                <Text
                    fontSize={2}
                    data-test-id={testIds.addressTitle}
                    lineHeight={2}
                    color="grey.900"
                    marginBottom={1}
                >
                    {title}
                </Text>
            )}
            {address.address && (
                <Subheading
                    data-test-id={testIds.address}
                    fontSize={2}
                    color="grey.600"
                    fontWeight={400}
                >
                    {address.address}
                </Subheading>
            )}
        </Flex>
    );
};
const AddressCard = (props) => {
    const { addressLabel, addressTitle, address, ...rest } = props;
    if (!address) {
        return null;
    }
    return (
        <Flex alignItems="baseline" {...rest}>
            <Text
                fontSize={2}
                width="60px"
                sx={{
                    flexShrink: 0,
                }}
                data-test-id={testIds.addressLabel}
                color="grey.800"
            >
                {addressLabel}
            </Text>
            <Address title={addressTitle} address={address} />
        </Flex>
    );
};

const canShowTripAddressCard = (tripInfo: TripInfo) => {
    const {
        settings: {
            moduleSettings: { enableCustomerModule },
        },
    } = tripInfo;

    return enableCustomerModule;
};

const TripAddressCard = () => {
    const { t } = useTranslation();
    const [{ tripInfo }] = useTripContext();
    const { source, destination, settings, customerName } = tripInfo;
    const { displayName } = settings;
    return (
        <Flex flexDirection="column" data-test-id={testIds.tripAddressModule}>
            <Flex alignItems="center" p={4}>
                <Text fontSize={2} fontWeight={600} color="grey.900">
                    {t('orderDetails')}
                </Text>
            </Flex>
            <Box mb={4}>
                <Divider color="grey.300" />
            </Box>
            <AddressCard
                mb={3}
                px={4}
                address={source}
                addressTitle={displayName}
                addressLabel={t('from')}
            />
            <AddressCard
                mb={3}
                px={4}
                address={destination}
                addressTitle={customerName}
                addressLabel={t('to')}
            />
            <Box>
                <Divider color="grey.300" />
            </Box>
        </Flex>
    );
};

export { canShowTripAddressCard };
export default TripAddressCard;
