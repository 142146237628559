// https://github.com/zloirock/core-js/blob/master/README.md#babelpolyfill
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// https://github.com/zloirock/core-js/blob/master/README.md#missing-polyfills
import 'isomorphic-fetch';

// https://github.com/wessberg/scroll-behavior-polyfill#applying-the-polyfill
if (!('scrollBehavior' in document.documentElement.style)) {
    import('scroll-behavior-polyfill').then(() =>
        console.log('scroll polyfill loaded')
    );
}

if (!('ResizeObserver' in window)) {
    import('resize-observer-polyfill').then((module) => {
        window.ResizeObserver = module.default;
        console.log('ResizeObserver polyfill loaded');
    });
}
