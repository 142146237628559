//@flow
import * as Immutable from 'immutable';

import { TripReducerState } from 'models/flow/TripReducerState.js';
import type { TripReducerStateModelType } from 'models/flow/TripReducerState.js';

import { TripInfo } from './TripInfo';
import { Direction } from './Direction';

export const UIStatusEnum = {
    Loading: 'LOADING',
    Polling: 'POLLING',
    Error: 'ERROR',
    Idle: 'IDLE',
    InitialSuccess: 'INITIAL_SUCCESS',
    PollError: 'POLL_ERROR',
};

class InternalTripReducerState extends TripReducerState {
    static fromJS(json: TripReducerStateModelType): InternalTripReducerState {
        const state: Object = Object.assign({}, json);
        state.tripInfo = state.tripInfo
            ? TripInfo.fromJS(state.tripInfo)
            : state.tripInfo;
        state.directions = Direction.fromJS(state.directions);
        return new this(Immutable.Map(state));
    }

    isRefreshInProgress() {
        return (
            this.tripStatus === UIStatusEnum.Polling ||
            this.tripStatus === UIStatusEnum.Loading
        );
    }

    get tripInfo(): ?TripInfo {
        return this._state.get('tripInfo');
    }

    get direction(): Direction {
        return this._state.get('direction');
    }

    get directions(): Direction {
        return this._state.get('directions');
    }
}

export { InternalTripReducerState as TripReducerState };
