//@flow
import type { ExpiryInfoStatus } from 'components/expiredPage/index.type';
import { ExpiryInfoStatusEnum } from 'components/expiredPage/enum';

const getStatusText = (
    status: ExpiryInfoStatus,
    translator: Function
): string => {
    switch (status) {
        case ExpiryInfoStatusEnum.COMPLETED:
            return translator('expiredCompletedMessage');
        case ExpiryInfoStatusEnum.CANCELLED:
            return translator('expiredCancelledMessage');
        default:
            return translator('expiredRescheduledMessage');
    }
};

const getBackgroundColor = (status: ExpiryInfoStatus): string => {
    switch (status) {
        case ExpiryInfoStatusEnum.COMPLETED:
            return 'blue.50';
        case ExpiryInfoStatusEnum.CANCELLED:
            return 'yellow.50';
        default:
            return 'grey.50';
    }
};

export { getBackgroundColor, getStatusText };
