// @flow
import * as React from 'react';
import { Image, Flex } from '@locus.sh/neo';
import type { TripInfo } from 'models/internal/TripInfo';
import { Position as BannerPosition } from 'models/flow/Banner';
import { canShowOrderDetails } from 'sections/tripPane/OrderDetails';
import { canShowTripAddressCard } from 'sections/tripPane/TripAddressCard';
import { useTripContext } from 'trip/useTripContext';
import { useBannerContext } from 'sections/state/banner';
import { useResizeDetector } from 'react-resize-detector';
import Tracker from 'helpers/Tracker';

type Placement = 'belowStatusModule' | 'belowLogo';
const TOP_BANNER_HEIGHT = 50;

const canShowBanner = (tripInfo: TripInfo, placement: Placement): boolean => {
    if (!tripInfo.banner) {
        return null;
    }

    if (tripInfo.banner.position === BannerPosition.TOP) {
        // Applying logic for small banner
        if (tripInfo.isInitial()) {
            if (
                (canShowOrderDetails(tripInfo) ||
                    canShowTripAddressCard(tripInfo)) &&
                placement === 'belowStatusModule'
            ) {
                return true;
            }
        } else if (placement === 'belowLogo') {
            return true;
        }
    }

    if (tripInfo.banner.position === BannerPosition.MIDDLE) {
        // Applying logic for large banner
        if (placement === 'belowStatusModule') {
            return true;
        }
    }

    return false;
};

const Banner = (): React$Element<any> => {
    const [{ tripInfo }] = useTripContext();
    const { banner } = tripInfo;
    const { updateBannerHeight } = useBannerContext();

    const onClick = () => {
        if (banner?.redirectUrl) {
            window.open(banner.redirectUrl, '_blank');
            Tracker.track('bannerUrlClick', {
                url: banner.redirectUrl,
            });
        }
    };

    const { height, ref } = useResizeDetector({
        refreshMode: 'debounce',
        refreshRate: 250,
    });

    React.useEffect(() => {
        updateBannerHeight(height);
    }, [updateBannerHeight, height]);

    return (
        <Flex
            maxHeight={
                banner?.isTopPosition() ? `${TOP_BANNER_HEIGHT}px` : '150px'
            }
        >
            <Image
                src={banner?.bannerUrl}
                ref={ref}
                width="100%"
                maxHeight="100%"
                sx={{
                    zIndex: 1,
                    cursor: banner?.redirectUrl ? 'pointer' : 'initial',
                }}
                onClick={onClick}
            />
        </Flex>
    );
};

export { canShowBanner, TOP_BANNER_HEIGHT };
export default Banner;
