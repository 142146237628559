//@flow
import * as React from 'react';
import { Position, Portal } from '@locus.sh/neo';
import { DialogContext } from '@locus.sh/neo/dist/es/core/dialog/DialogContext.js';

const getContentStyle = (isOpen) => ({
    bottom: 0,
    left: 0,
    width: '100%',
    backgroundColor: 'white',
    sx: {
        borderRadius: '10px 10px 0 0',
        transform: isOpen ? 'translate3d(0, 0, 0)' : 'translate3d(0, 100%, 0)',
        transition: '0.5s ease transform',
    },
    maxHeight: '80vh',
});

type Props = {
    /**
     * for children use `<DialogHeader>`,
     * `<DialogContent>`, & `<DialogFooter>` as needed.
     */
    children: React.Node,
    /**
     * This prop moves the `DialogHeader` & `DialogFooter` out of
     * the scroll content area (making them 'sticky');
     * also applies appropriate shadows to header/footer
     */
    sticky?: boolean,
    /**
     * Controls the visibility of the BottomSheet
     */
    isOpen?: boolean,
    invertBackdrop?: boolean,
    /**
     * function called on backdrop click
     */
    onDismiss?: (?Event) => void,
};

export const BottomSheet = ({
    children,
    sticky,
    onDismiss,
    invertBackdrop,
    isOpen,
}: Props): React.Node => {
    const context = { sticky, onDismiss };
    return (
        <DialogContext.Provider value={context}>
            <Portal>
                {isOpen && (
                    <Position
                        position="fixed"
                        top="0"
                        right="0"
                        left="0"
                        bottom="0"
                        sx={{
                            backgroundColor: invertBackdrop
                                ? 'rgba(255,255,255, 0.4)'
                                : 'rgba(0,0,0,0.2)',
                        }}
                        onClick={onDismiss}
                        zIndex="overlay"
                    />
                )}
                <Position
                    position="fixed"
                    zIndex="overlay"
                    {...getContentStyle(isOpen)}
                >
                    {children}
                </Position>
            </Portal>
        </DialogContext.Provider>
    );
};
