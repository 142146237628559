// @flow
import React from 'react';
import { Flex, Text, Box, defaultTheme } from '@locus.sh/neo';
import { formats } from 'models/flow/CustomerChecklist.js';
import { TripInfo } from 'models/flow/TripInfo.js';
type Props = {
    tripInfo: TripInfo,
};

export const URL_CARD_HEIGHT = 40;

export const canShowUrlCard = (tripInfo: TripInfo): boolean => {
    const {
        status: { status },
        settings,
    } = tripInfo;
    const items =
        settings.customerChecklists.find(
            (settings) => settings.status === status
        )?.items || [];

    const urlItem = items.find((item) => item.format === formats.URL);
    if (!urlItem) {
        return false;
    }
    return true;
};

export const URLCard = (props: Props): null | React$Element<any> => {
    const getURLCardStyle = () => ({
        height: `${URL_CARD_HEIGHT}px`,
        alignItems: 'center',
        backgroundColor: defaultTheme.colors.grey['600'],
    });

    const { tripInfo } = props;

    const {
        status: { status },
        settings,
    } = tripInfo;
    const items =
        settings.customerChecklists.find(
            (settings) => settings.status === status
        )?.items || [];

    const urlItem = items.find((item) => item.format === formats.URL);

    if (!urlItem) {
        return null;
    }

    const url = urlItem.possibleValues.first();

    return (
        <Flex
            boxShadow="raised"
            paddingX="1rem"
            appearance="primary"
            sx={getURLCardStyle}
        >
            <Box width="100%" display="flex" color="grey.100">
                <Flex flexGrow={1}>
                    <Text fontSize={2}>{urlItem.item}</Text>
                </Flex>
                <Text fontSize={2}>
                    <a
                        style={{ textDecoration: 'underline' }}
                        href={url}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {url}
                    </a>
                </Text>
            </Box>
        </Flex>
    );
};
