//@flow
import * as React from 'react';
import type { ExpiryInfoStatus } from 'components/expiredPage/index.type';
import { ExpiryInfoStatusEnum } from 'components/expiredPage/enum';
import { Flex, Box, Image, Text } from '@locus.sh/neo';
import TimeUtil, { DateTimeFormat } from 'helpers/TimeUtil';
import { getBackgroundColor, getStatusText } from 'components/expiredPage/util';
import { useTranslation } from 'react-i18next';

type Props = {
    status: ExpiryInfoStatus,
    time: string,
};

const ExpiredPage = ({ status, time }: Props): React$Element<any> => {
    const format =
        status === ExpiryInfoStatusEnum.RESCHEDULED
            ? DateTimeFormat.HUMAN_DATE_FORMAT_WITH_ORDINAL
            : DateTimeFormat.HUMAN_DATE_TIME_FORMAT_WITH_ORDINAL;
    const { t } = useTranslation();
    return (
        <Flex
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="100%"
            width="100%"
            px={10}
            backgroundColor={getBackgroundColor(status)}
        >
            <Box mb={5}>
                <Image src="/img/expiry.svg" />
            </Box>
            <Text fontSize={1} mb={1} textAlign="center">
                {t('expiredLinkMessage')}.
            </Text>
            <Text fontSize={1} mb={1} textAlign="center">
                {getStatusText(status, t)}
                <Text as="span" fontWeight={600} ml={1}>
                    {TimeUtil.format({
                        date: time,
                        format,
                    })}
                </Text>
            </Text>
        </Flex>
    );
};

export default ExpiredPage;
