// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel'; // Make sure you copied this file into your repo

export const ExpiryInfoStatusEnum = {
    COMPLETED: 'Completed',
    CANCELLED: 'Cancelled',
    RESCHEDULED: 'Rescheduled',
};

export type ExpiryInfoStatus = $Values<typeof ExpiryInfoStatusEnum>;

export type ExpiryInfoModelType = {
    status: ExpiryInfoStatus,
    timeStamp: string,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class ExpiryInfo extends ImmutableModel {
    static fromJS(json: ExpiryInfoModelType): ExpiryInfo {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }

    toJS(): ExpiryInfoModelType {
        return {
            status: this.status,
            timeStamp: this.timeStamp,
        };
    }

    get status(): ExpiryInfoStatus {
        return this._state.get('status');
    }

    setStatus(status: ExpiryInfoStatus): this {
        return this.clone(this._state.set('status', status));
    }

    get timeStamp(): string {
        return this._state.get('timeStamp');
    }

    setTimeStamp(timeStamp: string): this {
        return this.clone(this._state.set('timeStamp', timeStamp));
    }
}
