//@flow
import * as React from 'react';
import { Flex } from '@locus.sh/neo';
import { ImageWithFallback } from 'components';
import testIds from 'tests/trip-header/testIds.json';

const ClientLogo = function (props) {
    return (
        <Flex
            alignItems="center"
            flexGrow={0}
            flexShrink={1}
            flexBasis="auto"
            mx={4}
        >
            <a href={props.websiteUrl} data-test-id={testIds.clientLogo}>
                <ImageWithFallback
                    alt={props.alt}
                    minWidth="40px"
                    maxHeight="40px"
                    src={props.logo}
                    sx={{ objectFit: 'contain' }}
                />
            </a>
        </Flex>
    );
};
ClientLogo.defaultProps = {
    logo:
        'https://placeholder.com/wp-content/uploads/2018/10/placeholder.com-logo4.png',
};
export { ClientLogo };
export default ClientLogo;
