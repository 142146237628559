// @flow
import * as Immutable from 'immutable'; // This is required
import ImmutableModel from './ImmutableModel'; // Make sure you copied this file into your repo

export type RescheduleInfoModelType = {
    allowCustomerReschedule: boolean,
    reschedulesExhausted: boolean,
    allowFutureDatesReschedule: boolean,
    rescheduleNumberOfDays: number,
};

// /////////////////////////////////////////////////////////////////////////////
//
// NOTE: THIS CLASS IS GENERATED. DO NOT MAKE CHANGES HERE.
//
// If you need to update this class, update the corresponding flow type above
// and re-run the flow-immutable-models codemod
//
// /////////////////////////////////////////////////////////////////////////////
export class RescheduleInfo extends ImmutableModel {
    static fromJS(json: RescheduleInfoModelType): RescheduleInfo {
        const state: Object = Object.assign({}, json);
        return new this(Immutable.Map(state));
    }

    toJS(): RescheduleInfoModelType {
        return {
            allowCustomerReschedule: this.allowCustomerReschedule,
            reschedulesExhausted: this.reschedulesExhausted,
            allowFutureDatesReschedule: this.allowFutureDatesReschedule,
            rescheduleNumberOfDays: this.rescheduleNumberOfDays,
        };
    }

    get allowCustomerReschedule(): boolean {
        return this._state.get('allowCustomerReschedule');
    }

    setAllowCustomerReschedule(allowCustomerReschedule: boolean): this {
        return this.clone(
            this._state.set('allowCustomerReschedule', allowCustomerReschedule)
        );
    }

    get reschedulesExhausted(): boolean {
        return this._state.get('reschedulesExhausted');
    }

    setReschedulesExhausted(reschedulesExhausted: boolean): this {
        return this.clone(
            this._state.set('reschedulesExhausted', reschedulesExhausted)
        );
    }

    get allowFutureDatesReschedule(): boolean {
        return this._state.get('allowFutureDatesReschedule');
    }

    setAllowFutureDatesReschedule(allowFutureDatesReschedule: boolean): this {
        return this.clone(
            this._state.set(
                'allowFutureDatesReschedule',
                allowFutureDatesReschedule
            )
        );
    }

    get rescheduleNumberOfDays(): number {
        return this._state.get('rescheduleNumberOfDays');
    }

    setRescheduleNumberOfDays(rescheduleNumberOfDays: number): this {
        return this.clone(
            this._state.set('rescheduleNumberOfDays', rescheduleNumberOfDays)
        );
    }
}
