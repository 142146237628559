//@flow
import * as Immutable from 'immutable';

import { LineItem } from 'models/flow/LineItem.js';
import type { LineItemModelType } from 'models/flow/LineItem.js';

import { Amount } from './Amount';

class InternalLineItem extends LineItem {
    static fromJS(json: LineItemModelType): InternalLineItem {
        const state: Object = Object.assign({}, json);
        state.price = Amount.fromJS(state.price);
        return new this(Immutable.Map(state));
    }

    get price(): Amount {
        return this._state.get('price');
    }
}

export { InternalLineItem as LineItem };
